import moment, { Moment } from 'moment';
import { IFillCalendar } from '../../structures/interfaces/ScheduleRules';
import { ICalendarCadences } from './../../structures/interfaces/Rules';

export const fieldsSize = { sm: 24, md: 12, lg: 8, xl: 7, xxl: 6 };

export const handleCalendarScroll = (): void => {
  const calendarElement = document.getElementById('calendar');
  if (calendarElement) {
    calendarElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

export const BuildFillCalendar = (
  capacity: number,
  hours: [Moment, Moment]
): IFillCalendar => {
  const [startTime, endTime] = hours;
  return {
    availableUnits: Number(capacity),
    endTime,
    startTime,
    capacityPerDay: Number(capacity),
  };
};
export const RulePeriodDates = (
  dateRange: [Moment, Moment],
  includes: [boolean, boolean] = [true, true]
): Moment[] => {
  const startDate = moment(dateRange[0]).clone();
  const endDate = moment(dateRange[1]).clone();

  const dateDiff = endDate.diff(startDate, 'day');

  if (dateDiff > 0) {
    const datesInRange = new Array(dateDiff)
      .fill('')
      .map((_, index) => startDate.clone().add(index + 1, 'day'));

    if (includes[0]) {
      datesInRange.unshift(startDate.clone());
    }

    if (!includes[1]) {
      datesInRange.pop();
    }

    return datesInRange;
  }
  return [];
};

interface ICadencesFormat {
  id?: string;
  ruleId?: string;
  capacityPerDay?: number;
  availableUnits?: number;
  startTime?: Moment;
  endTime?: Moment;
}
interface GetListDataProps {
  value: Moment;
  specialHours: boolean;
  sunday?: {
    cif: ICadencesFormat;
    fob: ICadencesFormat;
  };
  saturday?: {
    cif: ICadencesFormat;
    fob: ICadencesFormat;
  };
  generalWeek?: {
    cif: ICadencesFormat;
    fob: ICadencesFormat;
    startTime?: Moment;
    endTime?: Moment;
  };
}
interface GetListDataReturn {
  listData: ICalendarCadences['calendarCell'];
  fillCalendar: {
    id?: string;
    ruleId?: string;
    availableUnits?: number;
    capacityPerDay?: number;
    endTime?: moment.Moment;
    startTime?: moment.Moment;
    cif?: {
      availableUnits?: number;
      capacityPerDay?: number;
      endTime?: moment.Moment;
      startTime?: moment.Moment;
    };
    fob?: {
      availableUnits?: number;
      capacityPerDay?: number;
      endTime?: moment.Moment;
      startTime?: moment.Moment;
    };
  };
}
interface IDayReturn {
  cif: ICadencesFormat;
  fob: ICadencesFormat;
  startTime?: Moment;
  endTime?: Moment;
}

export const GetListData = ({
  value,
  generalWeek,
  saturday,
  sunday,
  specialHours,
}: GetListDataProps): GetListDataReturn | undefined => {
  const days = (value: Moment): IDayReturn | undefined => {
    switch (value.isoWeekday()) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        if (generalWeek?.cif.capacityPerDay || generalWeek?.fob.capacityPerDay)
          return generalWeek;

        return;
      case 6:
        if (saturday?.cif.capacityPerDay || saturday?.fob.capacityPerDay)
          return saturday;

        return;
      case 7:
        if (sunday?.cif.capacityPerDay || sunday?.fob.capacityPerDay)
          return sunday;

        return;
    }
  };
  const fillCalendar = days(value);

  if (fillCalendar) {
    const weekStartTime = fillCalendar.startTime?.clone();
    const weekEndTime = fillCalendar?.endTime?.clone();

    const cifStartTime = fillCalendar.cif.startTime?.clone();
    const cifEndTime = fillCalendar?.cif.endTime?.clone();

    const fobStartTime = fillCalendar.fob.startTime?.clone();
    const fobEndTime = fillCalendar?.fob.endTime?.clone();

    const configureTime = {
      date: value.date(),
      month: value.month(),
      year: value.year(),
    };

    weekStartTime?.set(configureTime);
    weekEndTime?.set(configureTime);
    cifStartTime?.set(configureTime);
    cifEndTime?.set(configureTime);
    fobStartTime?.set(configureTime);
    fobEndTime?.set(configureTime);

    const newFillCalendar = {
      ...fillCalendar,
      cif: {
        ...fillCalendar.cif,
        startTime: cifStartTime,
        endTime: cifEndTime,
      },
      fob: {
        ...fillCalendar.fob,
        startTime: fobStartTime,
        endTime: fobEndTime,
      },
      startTime: weekStartTime,
      endTime: weekEndTime,
    } as GetListDataReturn['fillCalendar'];

    const timeFormat = (time: Moment | undefined) => time?.format('HH:mm');
    const renderHours = (
      start: Moment | undefined,
      end: Moment | undefined
    ) => {
      return `${timeFormat(start)} - ${timeFormat(end)}`;
    };
    const cifHours = `${renderHours(cifStartTime, cifEndTime)} \u00A0\u00A0`;
    const fobHours = `\u00A0\u00A0 ${renderHours(fobStartTime, fobEndTime)}`;

    const weekHours = `${renderHours(
      cifStartTime || fobStartTime || weekStartTime,
      cifEndTime || fobEndTime || weekEndTime
    )}`;

    const specialTimes = `${cifHours} | ${fobHours}`;

    const result = specialHours ? specialTimes : weekHours;

    const listData = [
      { cif: fillCalendar.cif.capacityPerDay },
      { fob: fillCalendar.fob.capacityPerDay },
      { hours: result },
    ] as ICalendarCadences['calendarCell'];

    return { listData, fillCalendar: newFillCalendar };
  }
};
