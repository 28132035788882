import { Checkbox, Layout } from 'antd';
import { ReactElement } from 'react';
import { useRuleContext } from '../../../contexts/rule/RuleContext.hooks';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Moment } from 'moment';
import { useRequest } from '../../../hooks/useRequest';
import RulesController from '../../../structures/controllers/Rules';

const CalendarPopoverContent = ({
  currDate,
}: {
  currDate: Moment;
}): ReactElement => {
  const {
    disableCadence,
    handleSetDisableCadence,
    calendarDates,
    handleSetCalendarDates,
  } = useRuleContext();

  const [deleteCifCadence] = useRequest(RulesController.deleteCifCadence);
  const [deleteFobCadence] = useRequest(RulesController.deleteFobCadence);

  const handleOnChange = ({ target: { checked } }: CheckboxChangeEvent) => {
    handleSetDisableCadence(checked);

    if (checked) {
      const aux = calendarDates.filter(calendarDate => {
        if (calendarDate && calendarDate.date) {
          return !calendarDate.date.isSame(currDate, 'day');
        }
      });
      const selected = calendarDates.find(calendarDate => {
        return calendarDate.date?.isSame(currDate, 'day');
      });

      deleteCifCadence({ id: selected?.fillCalendar.cif?.id || '' })
        .then()
        .catch();

      deleteFobCadence({ id: selected?.fillCalendar.fob?.id || '' })
        .then()
        .catch();

      handleSetCalendarDates(aux);
    }

    handleSetDisableCadence(false);
  };

  return (
    <Layout>
      <Layout.Content>
        <Checkbox checked={disableCadence} onChange={handleOnChange}>
          Desabilitar cadência
        </Checkbox>
      </Layout.Content>
    </Layout>
  );
};

export default CalendarPopoverContent;
