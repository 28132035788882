import { gql, TypedDocumentNode } from 'urql';
import {
  ICreateCustomerRuleInput,
  IFetchRuleToUpdate,
  INewRuleInput,
  IRulesRequirements,
  RuleUpdateInput,
} from '../interfaces/Rules';

const RulesQueries = {
  fetchRuleRequirements: (): TypedDocumentNode<
    IRulesRequirements,
    {
      where: {
        organization_id: { equals: string };
        pick_up_location: { equals: true };
      };
    }
  > => gql`
    query RulesRequirements($where: AddressWhereInputDto!) {
      seedTypes: ListSeedTypes {
        name
        nameWithoutAccent: name_without_accent
      }
      locations: FindAddresses(where: $where) {
        id
        title
      }
    }
  `,

  createRule: (): TypedDocumentNode<
    { createRule: [{ id: string }] },
    { data: INewRuleInput }
  > => gql`
    mutation createRule($data: RuleCreateInput!) {
      createRule: CreateRule(data: $data) {
        id
      }
    }
  `,

  updateRule: (): TypedDocumentNode<
    { updateRule: [{ id: string }] },
    { data: RuleUpdateInput }
  > => gql`
    mutation updateRule(
      $where: RuleWhereUniqueInput!
      $data: RuleUpdateInput!
    ) {
      updateRule: UpdateRule(where: $where, data: $data) {
        id
      }
    }
  `,

  createCustomerRules: (): TypedDocumentNode<
    { customerRule: { id: string } },
    { input: ICreateCustomerRuleInput }
  > => gql`
    mutation createCustomerRule($input: CustomerRuleCreateInput!) {
      customerRule: CreateCustomerRule(input: $input) {
        id
      }
    }
  `,

  fetchRule: (): TypedDocumentNode<{
    rule: IFetchRuleToUpdate;
    where: { id: string };
  }> => gql`
    query findRule($where: RuleWhereUniqueInput!) {
      rule: FindRule(where: $where) {
        id
        seedType: seed_type {
          name
          nameWithoutAccent: name_without_accent
        }
        pickUpLocation: pick_up_location {
          id
          title
        }
        whiteSeedSchedulingTime: white_seed_scheduling_time
        tsiSeedSchedulingTime: tsi_seed_scheduling_time
        releaseSubmissionDate: release_submission_date
        schedulingStart: scheduling_start
        startDate: start_date
        endDate: end_date
        loadReportTimeout: load_report_timeout
        driverReportTimeout: driver_report_timeout
        unity
        generalEndTime: general_end_time
        generalStartTime: general_start_time
        capacityPerCompany: capacity_per_company
        # cif fields
        cifGeneralCapacityPerDay: cif_general_capacity_per_day
        cifGeneralSundayCapacity: cif_general_sunday_capacity
        cifGeneralSaturdayCapacity: cif_general_saturday_capacity
        generalCifSaturdayStartTime: general_cif_saturday_start_time
        generalCifSaturdayEndTime: general_cif_saturday_end_time
        generalCifSundayStartTime: general_cif_sunday_start_time
        generalCifSundayEndTime: general_cif_sunday_end_time
        # fob fields
        fobGeneralCapacityPerDay: fob_general_capacity_per_day
        fobGeneralSundayCapacity: fob_general_sunday_capacity
        fobGeneralSaturdayCapacity: fob_general_saturday_capacity
        generalFobSaturdayStartTime: general_fob_saturday_start_time
        generalFobSaturdayEndTime: general_fob_saturday_end_time
        generalFobSundayStartTime: general_fob_sunday_start_time
        generalFobSundayEndTime: general_fob_sunday_end_time
        # cadences
        cifCadences: cif_cadences {
          id
          startTime: start_time
          endTime: end_time
          availableUnits: available_units
        }
        fobCadences: fob_cadences {
          id
          startTime: start_time
          endTime: end_time
          availableUnits: available_units
        }
      }
    }
  `,

  deleteCifCadence: (): TypedDocumentNode<
    { deleteCifCadence: { success: boolean } },
    { where: { id: string } }
  > => gql`
    mutation deleteCifCadence($where: CIFCadenceWhereUniqueInput!) {
      deleteCifCadence: DeleteCIFCadence(where: $where) {
        success
      }
    }
  `,

  deleteFobCadence: (): TypedDocumentNode<
    { deleteFobCadence: { success: boolean } },
    { where: { id: string } }
  > => gql`
    mutation deleteFobCadence($where: FOBCadenceWhereUniqueInput!) {
      deleteFobCadence: DeleteFOBCadence(where: $where) {
        success
      }
    }
  `,

  fetchFarms: (): TypedDocumentNode<
    { farms: Array<{ id: string; name: string }> },
    { where: { organization_id: string } }
  > => gql`
    query GetOrganizationFarms($where: OrganizationWhere!) {
      farms: GetOrganizationFarms(where: $where) {
        id
        name
      }
    }
  `,
};

export default RulesQueries;
