import moment from 'moment';
import client from '../../api/urql/customClient';
import Formatter from '../../classes/Formatter';
import {
  IAddTruckLoadItemsInput,
  ICreateTruckLoadInputByGraphQL,
  IContItemsRequest,
  IContItemsResponse,
  ICreatePreScheduleResponse,
  ICreateTruckLoadInputRequest,
  IGetTruckLoad,
  IGetTruckLoadsResponse,
  IGetVehicles,
  ILoadTruckLoadItemsInput,
  IPreScheduleInput,
  ITruckLoadRules,
  IUpdateTruckLoadsInput,
  IUpdateTruckLoadInputByGraphQL,
  IShippingCompanyByGraphQL,
} from '../interfaces/TruckLoad';
import TruckLoadsQueries from '../queries/TruckLoad';
import Normalizer from '../../classes/Normalizer';
import { v4 as uuidv4 } from 'uuid';

const buildSlug = (truckLoads: IGetTruckLoadsResponse[]) => {
  return truckLoads?.map(truckLoad => ({
    ...truckLoad,
    slug: Formatter.removeAccents(
      ` ${truckLoad.loadingOrder?.loadingOrder ?? ''}
        ${truckLoad.name ?? ''}
        ${
          truckLoad.cif_cadence.startTime ??
          truckLoad.fob_cadence.startTime ??
          truckLoad.farm_cadence.startTime ??
          ''
        }
        ${truckLoad.vehicle.vehicleModel ?? ''}
        ${truckLoad.loadedAmount ?? ''}
        ${truckLoad?.driver?.name ?? ''}
`.toLowerCase()
    ),
  }));
};

const filterTruckLoadByStatus = (
  truckLoads: IGetTruckLoadsResponse[],
  truckLoadStatus?: string
) => {
  return truckLoadStatus != undefined
    ? truckLoads.filter(truckLoad => {
        return truckLoad.status === truckLoadStatus;
      })
    : truckLoads;
};

const filterTruckLoadByPickUpLocation = (
  truckLoad: IGetTruckLoadsResponse[],
  truckLoadPickUpLocation?: string
) => {
  if (truckLoadPickUpLocation != undefined) {
    return truckLoad.filter(truckLoad => {
      return truckLoad.pickUpLocation.title === truckLoadPickUpLocation;
    });
  } else {
    return truckLoad;
  }
};
const filterTruckLoadByDate = (
  truckLoads: IGetTruckLoadsResponse[],
  date?: [moment.Moment, moment.Moment]
) =>
  date != undefined
    ? truckLoads.filter(truckLoad => {
        return moment(
          truckLoad.cif_cadence.startTime ??
            truckLoad.fob_cadence.startTime ??
            truckLoad.farm_cadence.startTime
        ).isBetween(date[0], date[1], 'day', '[]');
      })
    : truckLoads;

class TruckLoadsController {
  static filterTruckLoad = (
    getTruckLoads: IGetTruckLoadsResponse[],
    {
      search = '',
      truckLoadStatus,
      truckLoadDate,
      truckLoadPickUpLocation,
    }: {
      search?: string;
      truckLoadStatus?: string;
      truckLoadDate?: [moment.Moment, moment.Moment];
      truckLoadPickUpLocation?: string;
    }
  ): IGetTruckLoadsResponse[] => {
    return filterTruckLoadByDate(
      filterTruckLoadByPickUpLocation(
        filterTruckLoadByStatus(
          buildSlug(getTruckLoads).filter(truckLoad => {
            return truckLoad.slug.includes(
              Formatter.removeAccents(search.toLowerCase().trim())
            );
          }),
          truckLoadStatus
        ),
        truckLoadPickUpLocation
      ),
      truckLoadDate
    );
  };
  static getTruckLoads = ({
    orgId,
  }: {
    orgId?: string;
  }): Promise<IGetTruckLoadsResponse[]> =>
    new Promise((resolve, reject) =>
      client
        .query(TruckLoadsQueries.getTruckLoads(), {
          where: orgId ? { organization_id: orgId } : {},
        })
        .then(({ truckLoads }) => resolve(truckLoads))
        .catch(err => reject(err))
    );

  static fetchTruckLoad = ({
    truckLoadId,
  }: {
    truckLoadId: string;
  }): Promise<IGetTruckLoad> =>
    new Promise((resolve, reject) =>
      client
        .query(TruckLoadsQueries.fetchTruckLoad(), {
          where: { id: truckLoadId },
        })
        .then(({ getTruckLoad }) => resolve(getTruckLoad))
        .catch(err => reject(err))
    );

  static downloadLoadingOrder = ({
    loadId,
  }: {
    loadId: string;
  }): Promise<{ url: string }> =>
    new Promise((resolve, reject) => {
      client
        .query(TruckLoadsQueries.downloadLoadingOrder(), {
          where: { truck_load_id: loadId },
        })
        .then(({ pdf }) => {
          resolve(pdf);
        })
        .catch(err => {
          reject(err);
        });
    });

  static cancelTruckLoad = ({
    loadId,
  }: {
    loadId: string;
  }): Promise<{ success: boolean }> =>
    new Promise((resolve, reject) => {
      client
        .mutation(TruckLoadsQueries.cancelTruckLoad(), {
          where: { truck_load_id: loadId },
        })
        .then(({ cancel }) => {
          resolve(cancel);
        })
        .catch(err => {
          reject(err);
        });
    });

  static createPreSchedule = ({
    data,
  }: {
    data: IPreScheduleInput;
  }): Promise<ICreatePreScheduleResponse> =>
    new Promise((resolve, reject) => {
      client
        .mutation(TruckLoadsQueries.createPreSchedule(), { data })
        .then(({ preSchedule }) => {
          resolve(preSchedule);
        })
        .catch(err => {
          reject(err);
        });
    });

  static listTruckLoadsRules = ({
    organizationId,
    seedTypeId,
    pickUpLocation,
    unity,
  }: {
    organizationId: string;
    seedTypeId?: string;
    pickUpLocation?: string;
    unity?: string;
  }): Promise<ITruckLoadRules[]> =>
    new Promise((resolve, reject) => {
      client
        .query(TruckLoadsQueries.listTruckLoadRules(), {
          where: {
            organization_id: { equals: organizationId },
            seed_type_id: seedTypeId ? { equals: seedTypeId } : undefined,
            pick_up_location_id: pickUpLocation
              ? { equals: pickUpLocation }
              : undefined,
            unity: unity ? { equals: unity } : undefined,
          },
        })
        .then(({ rule }) => {
          resolve(rule);
        })
        .catch(err => {
          reject(err);
        });
    });

  static getVehicles = ({
    organizationId,
  }: {
    organizationId: string;
    takeCommonVehicles: boolean;
  }): Promise<IGetVehicles[]> =>
    new Promise((resolve, reject) =>
      client
        .query(TruckLoadsQueries.getVehicles(), {
          where: {
            organization_id: { equals: organizationId },
          },
        })
        .then(({ vehicles }) => {
          resolve(vehicles);
        })
        .catch(err => reject(err))
    );

  static listContractItems = ({
    excludeTsi,
    farmId,
    organizationId,
    preScheduleId,
    seedTypeId,
    unity,
  }: IContItemsRequest): Promise<IContItemsResponse[]> =>
    new Promise((resolve, reject) => {
      client
        .query(TruckLoadsQueries.listContractItems(), {
          where: {
            exclude_tsi: excludeTsi,
            farm_id: farmId,
            organization_id: organizationId,
            pre_schedule_id: preScheduleId,
            seed_type_id: seedTypeId,
            unity,
          },
        })
        .then(({ list }) => {
          resolve(list.contractItems);
        })
        .catch(err => {
          reject(err);
        });
    });

  static cancelPreSchedule = ({
    id,
  }: {
    id: string;
  }): Promise<{ success: boolean }> => {
    return new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadsQueries.cancelPreSchedule(), { where: { id } })
        .then(({ cancel }) => resolve(cancel))
        .catch(reject)
    );
  };

  static createTruckLoad = (
    values: ICreateTruckLoadInputRequest
  ): Promise<{
    id: string;
  }> => {
    let shippingCompanyObj;
    let driverObj;

    if (!values.noShippingCompany && values.shippingCompanyData) {
      const phone = values.shippingCompanyData.phoneNumber;
      const {
        email,
        tradeName,
        companyName,
        shippingCompanyId,
        documentNumberCnpj,
        phoneNumber = Normalizer.onlyNumbers(phone),
      } = values.shippingCompanyData;

      shippingCompanyObj = {
        connectOrCreate: {
          where: { id: shippingCompanyId || uuidv4() },
          create: {
            email,
            trade_name: tradeName,
            company_name: companyName,
            phone_number: phoneNumber,
            document_number_cnpj: documentNumberCnpj,
            organization: { connect: { id: values.organizationId } },
          },
        },
      };
    } else {
      shippingCompanyObj = undefined;
    }

    if (!values.informDriverAfter) {
      const {
        documentNumberCpf,
        driverId,
        email,
        name,
        notes,
        phone,
        vehicleLicensePlate,
        vehicleModel,
      } = values.driver;

      driverObj = {
        connectOrCreate: {
          where: { id: driverId || uuidv4() },
          create: {
            created_at: moment().toISOString(),
            document_number_cpf: documentNumberCpf,
            email,
            name,
            note: notes,
            organization_id: values.organizationId,
            phone_number: phone,
            vehicle_license_plate: vehicleLicensePlate,
            vehicle_model: vehicleModel,
            vehicle: { connect: { id: vehicleModel } },
            shipping_company: undefined,
          },
        },
      };
    } else {
      driverObj = undefined;
    }

    const input: ICreateTruckLoadInputByGraphQL = {
      can_sort_truck_load_item: values.canSortTruckLoadItem,
      driver: driverObj,
      load_note: values.loadNote,
      name: values.name,
      no_shipping_company: values.noShippingCompany,
      organization: { connect: { id: values.organizationId } },
      pre_schedule: { connect: { id: values.preScheduleId } },
      reference_vehicle: values.vehicleId,
      seed_type: { connect: { name_without_accent: values.seedTypeId } },
      shipping_company: shippingCompanyObj,
      truck_load_items: {
        createMany: {
          data: values.truckLoadItems.map(item => ({
            allocated_amount: item.allocatedAmount,
            contract_item_id: item.contractItemId,
            load_sort: item.loadSort,
          })),
        },
      },
      vehicle: { connect: { id: values.vehicleId } },
    };
    return new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadsQueries.createTruckLoad(), { input })
        .then(({ truckLoad }) => resolve(truckLoad))
        .catch(reject)
    );
  };

  static getPreScheduleByCreator = ({
    creatorId,
  }: {
    creatorId: string;
  }): Promise<Array<{ id: string; created_by: string }>> => {
    return new Promise((resolve, reject) => {
      client
        .query(TruckLoadsQueries.getPreScheduleByCreator(), {
          where: {
            created_by: {
              equals: creatorId,
            },
            expired: {
              equals: false,
            },
          },
        })
        .then(({ getPreScheduleByCreator }) => {
          resolve(getPreScheduleByCreator);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  static authorizeTruckLoad = ({
    truckLoadId,
  }: {
    truckLoadId: string;
  }): Promise<{ id: string; authorizedAt: string }> => {
    return new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadsQueries.authorizeTruckLoad(), {
          where: { id: truckLoadId },
        })
        .then(({ authorizeTruckLoad }) => {
          resolve(authorizeTruckLoad);
        })
        .catch(err => {
          reject(err);
        })
    );
  };

  static updateTruckLoad = ({
    truckLoadId,
    input,
  }: {
    truckLoadId: string;
    input: IUpdateTruckLoadsInput;
  }): Promise<{ id: string }> => {
    const carrierPhone = Normalizer.onlyNumbers(
      input.shippingCompany.phoneNumber
    );
    const carrierCnpj = Normalizer.onlyNumbers(
      input.shippingCompany.documentNumberCnpj
    );
    const driverPhone = Normalizer.onlyNumbers(input.driver.phone);
    const driverCpf = Normalizer.onlyNumbers(input.driver.documentNumberCpf);

    const carrierObj = {
      connectOrCreate: {
        where: {
          id: input.shippingCompany.shippingCompanyId || uuidv4(),
        },
        create: {
          company_name: input.shippingCompany.companyName,
          document_number_cnpj: carrierCnpj,
          email: input.shippingCompany.email,
          organization: { connect: { id: input.organizationId } },
          phone_number: carrierPhone,
          trade_name: input.shippingCompany.tradeName,
        },
      },
    } as IShippingCompanyByGraphQL;

    const data = {
      driver: {
        connectOrCreate: {
          where: {
            id: input.driver.driverId || uuidv4(),
          },
          create: {
            created_at: moment().toISOString(),
            document_number_cpf: driverCpf,
            email: input.driver.email,
            name: input.driver.name,
            note: input.driver.notes,
            organization_id: input.organizationId,
            phone_number: driverPhone,
            shipping_company: carrierObj,
            vehicle: { connect: { id: input.driver.vehicleModel } },
            vehicle_license_plate: input.driver.vehicleLicensePlate,
            vehicle_model: input.driver.vehicleModel,
          },
        },
      },
      shipping_company: carrierObj,
    } as IUpdateTruckLoadInputByGraphQL;

    return new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadsQueries.updateTruckLoad(), {
          data,
          where: { id: truckLoadId },
        })
        .then(({ updateTruckLoad }) => {
          resolve(updateTruckLoad);
        })
        .catch(err => {
          reject(err);
        })
    );
  };

  static addTruckLoadItems = ({
    input,
    where,
  }: {
    input: IAddTruckLoadItemsInput;
    where: {
      id: string;
    };
  }): Promise<{ id: string }> => {
    return new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadsQueries.addTruckLoadItems(), { input, where })
        .then(({ addTruckLoadItems }) => resolve(addTruckLoadItems))
        .catch(err => reject(err))
    );
  };

  static deleteTruckLoadItems = ({
    input,
  }: {
    input: { data: { id: string } };
  }): Promise<{ success: boolean }> => {
    return new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadsQueries.deleteTruckLoadItems(), { input })
        .then(({ deleteItems }) => resolve(deleteItems))
        .catch(err => reject(err))
    );
  };

  static loadTruckLoadItems = ({
    input,
  }: {
    input: ILoadTruckLoadItemsInput;
  }): Promise<{ id: string }> => {
    return new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadsQueries.loadTruckLoadItems(), {
          input: { data: input.data },
        })
        .then(({ updateTruckLoad }) => {
          resolve(updateTruckLoad);
        })
        .catch(err => {
          reject(err);
        })
    );
  };
}

export default TruckLoadsController;
