import { Card, Col, Form, Row, Select } from 'antd';
import { fieldsSize } from '../RulesForm.utils';
import { ReactElement } from 'react';
import { useRuleContext } from '../../../contexts/rule/RuleContext.hooks';

const UnityForm = ({ isLoading }: { isLoading: boolean }): ReactElement => {
  const { operation } = useRuleContext();

  const unities = [
    { value: 'BAG', label: 'BAGS' },
    { value: 'SMALL_BAG', label: 'SACAS' },
  ];

  return (
    <Card title={'Informações de unidade'} loading={isLoading}>
      <Row gutter={24}>
        <Col {...fieldsSize}>
          <Form.Item
            initialValue={'BAG'}
            label="Unidade de medida"
            name={'unity'}
            required
          >
            <Select options={unities} disabled={operation === 'update'} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default UnityForm;
