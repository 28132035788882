import { CopyOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  Layout,
  message,
  Row,
  Typography,
} from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useRequest } from '../../../hooks/useRequest';
import ClientsController from '../../../structures/controllers/Clients';
import { IGetClient } from '../../../structures/interfaces/Clients';

const copyToClipboard = (text: string) => {
  try {
    navigator.clipboard.writeText(text);
    message.success('Copiado para área de transferência!');
  } catch (err) {
    message.error('Falha ao copiar a senha!');
  }
};

interface IResetPassProps {
  form: FormInstance<{ password: string }>;
  data?: IGetClient;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const ResetContactPassword = ({
  form,
  data,
  setIsLoading,
}: IResetPassProps): ReactElement => {
  const [password, setPassword] = useState('');
  const [resetPass, isLoad] = useRequest(ClientsController.resetCustomerPass);

  useEffect(() => {
    setIsLoading(isLoad);
    form.resetFields();
    setPassword('');
  }, [open]);

  const handleOnOk = () => {
    setIsLoading(isLoad);
    if (!data) return null;
    resetPass({ ids: [data.id] })
      .then(res => {
        if (res.length === 0) {
          const text =
            'Este usuário não tem acesso ao sistema! por isso não pode ter sua senha resetada!';
          return message.error({ content: text, style: { marginTop: 60 } });
        }
        form.setFieldsValue({ password: res[0].password });
        setPassword(res[0].password);
        setIsLoading(isLoad);
      })
      .catch(() => {
        message.error('Não foi possível resetar a senha deste usuário!');
      });
  };

  return (
    <Layout>
      <Descriptions column={1} style={{ marginBottom: 24 }}>
        <Descriptions.Item label="Nome">
          {data?.tradeName || data?.name}
        </Descriptions.Item>
      </Descriptions>

      <Typography.Text strong style={{ marginBottom: 24 }}>
        Clique para resetar a senha de seu cliente!
      </Typography.Text>

      <Form form={form} onFinish={handleOnOk} disabled={!password}>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name="password">
              <Input.Password placeholder="Sua senha aparecerá aqui!" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Button
              disabled={!password}
              type="primary"
              icon={<CopyOutlined />}
              onClick={() => copyToClipboard(form.getFieldValue('password'))}
            />
          </Col>
        </Row>
      </Form>
    </Layout>
  );
};

export default ResetContactPassword;
