import { IntegrationMod } from '../structures/interfaces/Settings';

interface IMod {
  intMod?: IntegrationMod;
  mod?: string;
}

interface IFetchSync {
  url: string;
  mod: string;
  syncSuccess: () => void;
  syncError: () => void;
}

export const screenModule = ({ intMod, mod }: IMod): string | undefined => {
  if (intMod?.modulesWithSync) {
    const moduleOfScreen = intMod?.modulesWithSync?.find(aux => {
      return aux === mod;
    });
    return moduleOfScreen;
  }
};

export const fetchSyncUrl = async ({
  url,
  mod,
  syncSuccess,
  syncError,
}: IFetchSync): Promise<void> => {
  const syncUrl = `/sync/${mod.toLowerCase()}`;
  if (url) {
    const concatUrl = url.concat(syncUrl);
    await fetch(concatUrl, { mode: 'no-cors' })
      .then(() => syncSuccess())
      .catch(() => syncError());
  }
};
