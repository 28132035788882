import { Form, Row, Col, Input, message, FormInstance, Select } from 'antd';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useRequest } from '../../../../../hooks/useRequest';
import ShippingCompanyController from '../../../../../structures/controllers/ShippingCompany';
import Normalizer from '../../../../../classes/Normalizer';
import Formatter from '../../../../../classes/Formatter';
import TruckLoadsController from '../../../../../structures/controllers/TruckLoad';
import { DefaultOptionType } from 'antd/lib/select';
import UserContext from '../../../../../contexts/user';

export interface IDriverFormValues {
  driverId: string;
  vehicleId: string;
  documentNumberCpf: string;
  name: string;
  vehicleModel: string;
  vehicleLicensePlate: string;
  phone: string;
  email: string;
  notes: string;
}

interface IDriverFormProps {
  form: FormInstance<IDriverFormValues>;
  updateData?: IDriverFormValues;
}
const DriverForm = ({ form, updateData }: IDriverFormProps): ReactElement => {
  const { userData, customerData } = useContext(UserContext);
  const orgId = userData?.organization.id || customerData?.organizationId;

  const [findDriver] = useRequest(ShippingCompanyController.findDriver);
  const [fetchVehicles] = useRequest(TruckLoadsController.getVehicles);
  const [vehicles, setVehicles] = useState<DefaultOptionType[]>([]);

  const getVehicles = (orgId: string) => {
    fetchVehicles({ organizationId: orgId, takeCommonVehicles: true })
      .then(res => {
        const options = res.map(vehicle => ({
          value: vehicle.id,
          label: vehicle.vehicleModel,
        }));
        setVehicles(options);
      })
      .catch();
  };

  const handleSearch = (cpf: string) => {
    if (cpf) {
      const searchValue = Normalizer.onlyNumbers(cpf);
      findDriver({ cpf: searchValue })
        .then(res => {
          form.setFieldsValue({
            driverId: res?.id,
            vehicleId: res?.vehicleId,
            name: res?.driver,
            vehicleModel: res?.vehicle,
            vehicleLicensePlate: res?.licensePlate,
            phone: Formatter.formatPhoneWithoutDDI(res?.phoneNumber),
            email: res?.email,
            notes: res?.driverObservation,
          });
        })
        .catch(() => {
          form.resetFields([
            'driverId',
            'vehicleId',
            'name',
            'vehicleModel',
            'vehicleLicensePlate',
            'phone',
            'email',
            'notes',
          ]);
          message.error('Erro ao buscar motorista');
        });
    }
  };

  useEffect(() => {
    if (orgId) getVehicles(orgId);
    if (updateData) {
      form.setFieldsValue({
        driverId: updateData?.driverId,
        vehicleId: updateData?.vehicleId,
        name: updateData?.name,
        vehicleModel: updateData?.vehicleModel,
        vehicleLicensePlate: updateData?.vehicleLicensePlate,
        phone: Formatter.formatPhoneWithoutDDI(updateData?.phone),
        email: updateData?.email,
        notes: updateData?.notes,
        documentNumberCpf: Formatter.formatCPF(updateData?.documentNumberCpf),
      });
    }
  }, [updateData]);

  return (
    <Form layout="vertical" form={form}>
      <Form.Item name={'driverId'} hidden />
      <Form.Item name={'vehicleId'} hidden />

      <Row gutter={[24, 0]}>
        <Col span={6}>
          <Form.Item
            label="CPF do motorista"
            name={'documentNumberCpf'}
            normalize={Formatter.formatCPF}
            rules={[{ required: true }]}
          >
            <Input.Search onSearch={handleSearch} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Nome do motorista"
            name={'name'}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Modelo do veículo"
            name={'vehicleModel'}
            rules={[{ required: true }]}
          >
            <Select options={vehicles} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Telefone"
            name={'phone'}
            normalize={Formatter.formatPhoneWithoutDDI}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Placa do veículo"
            name={'vehicleLicensePlate'}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Observações sobre o motorista" name={'notes'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default DriverForm;
