import { Col, Row } from 'antd';
import { ReactElement } from 'react';
import Caminhao from '../../../assets/caminhao.svg';
import {
  boxContent,
  boxesContainer,
  boxesPosition,
  hideTruck,
} from './TruckImage.styles';
import { GetCultivationColor } from '../CreateLoad/createLoadSteps/LoadSteps.utils';

export interface ITruckImageItems {
  id: string;
  contractItemId?: string;
  allocatedAmount: number;
  cultivation: string;
  color: string;
}
interface ITruckImageProps {
  colorsAssigned?: Record<string, string>;
  truckLoadItems: ITruckImageItems[];
  onBoxHover: (id: string | undefined) => void;
  hoveredRow?: string;
}
export default function TruckImage({
  truckLoadItems,
  onBoxHover,
  hoveredRow,
}: ITruckImageProps): ReactElement {
  const renderBox = (background: string, id: string, index: number) => {
    return (
      <div
        key={id}
        style={boxesContainer(background, id === hoveredRow)}
        onMouseOver={() => onBoxHover(id)}
      >
        <div style={boxContent(background)}>
          {truckLoadItems.length - index}
        </div>
      </div>
    );
  };

  return (
    <div style={hideTruck}>
      <img src={Caminhao} />
      <Row style={boxesPosition} onMouseLeave={() => onBoxHover(undefined)}>
        <Col span={24}>
          {truckLoadItems.map((item, index) => {
            const color = GetCultivationColor(item.cultivation);
            return renderBox(color, item.id, index);
          })}
        </Col>
      </Row>
    </div>
  );
}
