import { DownOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  FormInstance,
  InputNumber,
  MenuProps,
  Row,
  Typography,
} from 'antd';
import { ReactElement, useEffect, useState } from 'react';
import { ISchedulingConfig } from '../../../../structures/interfaces/Rules';
import WeekendForm from './WeekendForm';

interface ICifFormProps {
  cifOpen: boolean;
  setCifOpen: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<ISchedulingConfig>;
}
const CifForm = ({
  cifOpen,
  form,
  setCifOpen,
}: ICifFormProps): ReactElement => {
  const [replyInSaturday, setReplyInSaturday] = useState(false);
  const [replyInSunday, setReplyInSunday] = useState(false);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Checkbox
          checked={replyInSaturday}
          onChange={v => setReplyInSaturday(v.target.checked)}
        >
          Sábado
        </Checkbox>
      ),
    },
    {
      key: '2',
      label: (
        <Checkbox
          checked={replyInSunday}
          onChange={({ target: { checked } }) => setReplyInSunday(checked)}
        >
          Domingo
        </Checkbox>
      ),
    },
  ];

  useEffect(() => {
    const { cif, scheduleHours } = form.getFieldsValue();
    const { generalCapacity } = cif;

    if (replyInSaturday) {
      form.setFieldsValue({
        cif: {
          saturday: {
            capacity: generalCapacity,
            hours: scheduleHours,
          },
        },
      });
    }

    if (replyInSunday) {
      form.setFieldsValue({
        cif: {
          sunday: {
            capacity: generalCapacity,
            hours: scheduleHours,
          },
        },
      });
    }
  }, [replyInSaturday, replyInSunday]);

  return (
    <Col
      span={12}
      style={{ borderRight: '1px solid #d9d9d9', paddingRight: 24 }}
    >
      <Typography.Title
        level={2}
        style={{ textAlign: 'center', color: '#52c41a' }}
      >
        CIF
      </Typography.Title>

      <Row gutter={[12, 0]}>
        <Col span={12}>
          <Form.Item
            label={'Capacidade MAX por dia para embarque'}
            name={['cif', 'generalCapacity']}
            required
          >
            <InputNumber
              addonAfter={'Bags'}
              style={{ width: '100%', textAlign: 'left' }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label=" ">
            <Checkbox
              checked={cifOpen}
              onChange={v => {
                setCifOpen(v.target.checked);
                if (v.target.checked === false) {
                  form.resetFields([
                    ['cif', 'saturday', 'capacity'],
                    ['cif', 'sunday', 'capacity'],
                    ['cif', 'saturday', 'hours'],
                    ['cif', 'sunday', 'hours'],
                  ]);
                }
              }}
              children={'Finais de semana'}
              style={{ marginRight: 24 }}
            />

            <Dropdown menu={{ items }} trigger={['click']} disabled={!cifOpen}>
              <Button disabled={!cifOpen} icon={<DownOutlined />}>
                Replicar dados da semana
              </Button>
            </Dropdown>
          </Form.Item>
        </Col>
        {cifOpen && <WeekendForm ruleType="cif" form={form} />}
      </Row>
    </Col>
  );
};

export default CifForm;
