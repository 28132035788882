import { Col, Form, FormInstance, Input, message, Row } from 'antd';
import { Dispatch, ReactElement, SetStateAction, useEffect } from 'react';
import Formatter from '../../../classes/Formatter';
import Normalizer from '../../../classes/Normalizer';
import { useRequest } from '../../../hooks/useRequest';
import ClientsController from '../../../structures/controllers/Clients';
import { IUpdateCustomerFields } from '../../../structures/interfaces/Clients';

export interface IUpdateCustomersProps {
  id: string;
  name: string;
  companyName: string;
  documentCpf: string;
  documentCnpj: string;
  phoneNumber: string;
  email: string;
}

interface IUpdateClientProps {
  data?: IUpdateCustomerFields;
  setData: Dispatch<SetStateAction<boolean>>;
  form: FormInstance<IUpdateCustomerFields>;
}

const UpdateContact = ({
  data,
  setData,
  form,
}: IUpdateClientProps): ReactElement => {
  const [updateCustomer] = useRequest(ClientsController.updateCustomer);

  const handleSubmit = (formValues: IUpdateCustomerFields) => {
    if (!data?.id) return null;

    const treatedValues = {
      ...formValues,
      documentNumberCpf:
        formValues.documentNumberCpf && formValues.documentNumberCpf !== ''
          ? Normalizer.onlyNumbers(formValues.documentNumberCpf)
          : null,
      phoneNumber:
        formValues.phoneNumber && formValues.phoneNumber !== ''
          ? Normalizer.onlyNumbers(formValues.phoneNumber)
          : null,
    } as IUpdateCustomerFields;

    updateCustomer({ customerData: treatedValues, id: data.id })
      .then(() => {
        message.success({
          style: { marginTop: 60 },
          content: 'Cliente atualizado com sucesso!',
        });
        setData(v => !v);
      })
      .catch(() => message.error('Não foi possível atualizar o cliente'));
  };

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      documentNumberCpf: Formatter.formatCPF(data?.documentNumberCpf),
      phoneNumber: Formatter.formatCellphone(data?.phoneNumber),
    });
  }, [data]);

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Form.Item hidden={true} name={'id'}>
        <Input />
      </Form.Item>

      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Form.Item label="Nome" name={'name'}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            normalize={Formatter.formatCPF}
            label="CPF"
            name={'documentNumberCpf'}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            normalize={Formatter.formatCellphone}
            label="Telefone"
            name={'phoneNumber'}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="E-mail" name={'email'}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default UpdateContact;
