import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  FormInstance,
  Layout,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Space,
} from 'antd';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import UserContext from '../../../../../contexts/user';
import { useRequest } from '../../../../../hooks/useRequest';
import BreadCrumb from '../../../../../structures/controllers/Breadcrumb';
import TruckLoadsController from '../../../../../structures/controllers/TruckLoad';
import {
  IContItemsResponse,
  IGetTruckLoad,
  ILoadTruckLoadItemsInput,
  IUpdateTruckLoadsInput,
} from '../../../../../structures/interfaces/TruckLoad';
import { handleLoadAddition, removeDuplicates } from '../LoadSteps.utils';
import TruckLoadAuthorization from '../stepThree/StepThree.authorization';
import DriverForm, {
  IDriverFormValues,
} from '../stepThree/StepThree.driverForm';
import ShippingCompanyForm from '../stepThree/StepThree.shippingCompanyForm';
import ContractItemsTable, {
  ItemsToAddType,
} from '../stepTwo/StepTwo.contractItemsTable';
import LoadData from '../stepTwo/StepTwo.loadData';
import OrderingTable from '../stepTwo/StepTwo.orderTable';
import DriverDescriptions from './ViewLoad.DriverDescriptions';
import ShippingCompanyDescriptions from './ViewLoad.ShippingCompanyDescriptions';
import { IShippingCompanyFormValues } from '../../../../settings/shippingCompany/ShippingCompanyPage.new';

interface IViewLoadProps {
  match: { params: { id: string } };
}

const handleUpdateDriverAndCarrier = ({
  driverForm,
  carrierForm,
  doUpdateTruckLoadsRequest,
  organizationId,
}: {
  driverForm: FormInstance<IDriverFormValues>;
  carrierForm: FormInstance<IShippingCompanyFormValues>;
  noShippingCompany: boolean;
  doUpdateTruckLoadsRequest: (input: IUpdateTruckLoadsInput) => void;
  truckLoadData: IGetTruckLoad | undefined;
  organizationId: string;
}) => {
  const updateData: IUpdateTruckLoadsInput = {
    organizationId,
    driver: { ...driverForm.getFieldsValue() },
    shippingCompany: { ...carrierForm.getFieldsValue() },
  };

  doUpdateTruckLoadsRequest(updateData);
};

const ViewLoad = ({ match }: IViewLoadProps): ReactElement => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'pages.truckLoads',
  });

  type IStateType = { state: { truckLoadId?: string; status?: string } };
  const { state = {} }: IStateType = useLocation();
  const truckLoadId = state.truckLoadId || match.params.id;

  const { userData, customerData } = useContext(UserContext);

  const [carrierForm] = Form.useForm<IShippingCompanyFormValues>();
  const [driverForm] = Form.useForm<IDriverFormValues>();
  const [contractItemsForm] = Form.useForm();
  const [loadItemsForm] = Form.useForm();

  const [truckLoadData, setTruckLoadData] = useState<IGetTruckLoad>();

  const [loadItems, setLoadItems] = useState<IContItemsResponse[]>([]);
  const [itemsToAdd, setItemsToAdd] = useState<ItemsToAddType>({});
  const [addedItems, setAddedItems] = useState<IContItemsResponse[]>(loadItems);
  const [contractItems, setContractItems] = useState<IContItemsResponse[]>([]);
  const [filterItems, setFilterItems] = useState<IContItemsResponse[]>([]);
  const [noShippingCompany, setNoShippingCompany] = useState(false);
  const [openUpdateDriver, setOpenUpdateDriver] = useState(false);
  const [openUpdateLoad, setOpenUpdateLoad] = useState(false);
  const [loadAll, setLoadAll] = useState(false);
  const [hasLoadAll, setHasLoadAll] = useState(false);

  const [updateTruckLoad, isUpdating] = useRequest(
    TruckLoadsController.updateTruckLoad
  );
  const [fetchTruckLoad, isFetchingTruckLoad] = useRequest(
    TruckLoadsController.fetchTruckLoad
  );
  const [addTruckLoadItems, isAdding] = useRequest(
    TruckLoadsController.addTruckLoadItems
  );
  const [authorizeTruckLoad, isAuth] = useRequest(
    TruckLoadsController.authorizeTruckLoad
  );
  const [fetchContractItems, isFetchingCI] = useRequest(
    TruckLoadsController.listContractItems
  );
  const [loadTruckLoadItems, isLoading] = useRequest(
    TruckLoadsController.loadTruckLoadItems
  );

  let enableEdition;

  if (customerData) {
    if (truckLoadData?.status === 'WAITING_DRIVER') {
      enableEdition = true;
    } else {
      enableEdition = false;
    }
  } else if (userData) {
    const userEditCondition = !(
      truckLoadData?.status === 'CANCELED' ||
      truckLoadData?.status === 'LOADED' ||
      truckLoadData?.status === 'COMPLETED'
    );

    if (userEditCondition) {
      enableEdition = true;
    } else {
      enableEdition = false;
    }
  }

  const doTruckLoadRequest = () => {
    if (truckLoadId) {
      fetchTruckLoad({ truckLoadId })
        .then(res => {
          setTruckLoadData(res);
          const aux = res.truckLoadItems.flatMap(item => {
            return {
              ...item.contractItem,
              allocatedAmount: item.allocatedAmount,
              truckLoadItemId: item.id,
            };
          });
          setLoadItems(aux);
          setAddedItems(aux);
          setNoShippingCompany(res.noShippingCompany);
        })
        .catch(() => message.error('Erro ao buscar dados da carga'));
    }
  };
  const doTruckLoadItemsRequest = () => {
    const organizationId =
      userData?.organization.id || customerData?.organizationId || '';

    fetchContractItems({
      unity: 'BAG',
      organizationId,
      seedTypeId: truckLoadData?.seedType.nameWithoutAccent,
      excludeTsi: false,
      preScheduleId: truckLoadData?.preScheduleId,
    })
      .then(res => {
        const aux = res.filter(item => item.remainingAmount > 0);
        setFilterItems(aux);
        setContractItems(aux);
      })
      .catch(() => message.error('Erro ao buscar itens da carga'));
  };
  const doUpdateTruckLoadsRequest = (input: IUpdateTruckLoadsInput) => {
    updateTruckLoad({ input, truckLoadId })
      .then(() => {
        message.success('Dados atualizados com sucesso!');
        doTruckLoadRequest();
      })
      .catch(() => message.error('Erro ao atualizar dados da carga'));
  };

  const loadTruckLoadItemsRequest = (input: ILoadTruckLoadItemsInput) => {
    loadTruckLoadItems({ input })
      .then(() => {
        message.success('Dados atualizados com sucesso!');
        doTruckLoadRequest();
      })
      .catch(() => message.error('Erro ao carregar carga'));
  };

  const handleAuthorization = (truckLoadId?: string) => {
    if (truckLoadId) {
      authorizeTruckLoad({ truckLoadId })
        .then(() => doTruckLoadRequest())
        .catch(() => message.error('Erro ao autorizar o agendamento'));
    }
  };

  const loadData = {
    name: truckLoadData?.name || '---',
    notes: truckLoadData?.loadNote || '---',
    seedType: truckLoadData?.seedType?.name || '---',
    pickupLocation: truckLoadData?.pickUpLocation?.title || '---',
    selectedDate:
      truckLoadData?.cif_cadence?.startTime ||
      truckLoadData?.fob_cadence?.startTime ||
      truckLoadData?.farm_cadence?.startTime ||
      '---',
    selectedVehicle: truckLoadData?.vehicle?.vehicleModel || '---',
    status: truckLoadData?.status || '---',
    vehicleMaxCapacity: truckLoadData?.vehicle?.bagsCapacity || 0,
    availabilityBags: truckLoadData?.allocatedAmount || 0,
  };

  const customFooter = (
    <Space>
      <Popconfirm
        zIndex={1041}
        title="Tem certeza que deseja cancelar?"
        onConfirm={() => {
          setOpenUpdateDriver(false);
          carrierForm.resetFields();
          driverForm.resetFields();
        }}
      >
        <Button icon={<CloseOutlined />} danger>
          Cancelar
        </Button>
      </Popconfirm>

      <Popconfirm
        zIndex={1041}
        title="Finalizar operação?"
        okText="Sim"
        cancelText="Não"
        onConfirm={() => {
          handleUpdateDriverAndCarrier({
            carrierForm,
            doUpdateTruckLoadsRequest,
            driverForm,
            noShippingCompany,
            truckLoadData,
            organizationId:
              userData?.organization.id || customerData?.organizationId || '',
          });
          setOpenUpdateDriver(false);
        }}
      >
        <Button icon={<CheckOutlined />} type="primary">
          Finalizar
        </Button>
      </Popconfirm>
    </Space>
  );
  const updateDriverAndCarrierModal = (
    <Modal
      zIndex={1040}
      width={1700}
      title="Atualizar dados de transportadora/motorista"
      open={openUpdateDriver}
      footer={customFooter}
    >
      <Checkbox
        checked={noShippingCompany}
        onChange={value => {
          if (value.target.checked) carrierForm.resetFields();
          setNoShippingCompany(value.target.checked);
        }}
      >
        Não possuo transportadora
      </Checkbox>

      {!noShippingCompany && <Divider />}
      {!noShippingCompany && (
        <ShippingCompanyForm
          updateData={
            truckLoadData?.shippingCompany
              ? {
                  companyName: truckLoadData?.shippingCompany?.companyName,
                  documentNumberCnpj:
                    truckLoadData?.shippingCompany?.documentNumberCnpj,
                  email: truckLoadData?.shippingCompany?.email,
                  phoneNumber: truckLoadData?.shippingCompany?.phoneNumber,
                  shippingCompanyId: truckLoadData.shippingCompany.id || '',
                  tradeName: truckLoadData?.shippingCompany?.tradeName,
                }
              : undefined
          }
          form={carrierForm}
        />
      )}
      <Divider />
      <DriverForm
        updateData={
          truckLoadData?.driver
            ? {
                documentNumberCpf: truckLoadData?.driver?.documentNumberCpf,
                email: truckLoadData?.driver.email,
                name: truckLoadData?.driver?.name,
                notes: truckLoadData?.driver?.note,
                vehicleLicensePlate: truckLoadData?.driver?.vehicleLicensePlate,
                vehicleModel: truckLoadData?.driver?.vehicleModel,
                driverId: truckLoadData?.driver?.driverId,
                phone: truckLoadData?.driver?.phoneNumber,
                vehicleId: truckLoadData?.driver?.vehicleId,
              }
            : undefined
        }
        form={driverForm}
      />
    </Modal>
  );
  const addItemsButton = (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      onClick={() => {
        handleLoadAddition({
          addedItems,
          contractItems,
          contractItemsForm,
          itemsToAdd,
          setAddedItems,
          setContractItems,
          setItemsToAdd,
          unity: 'BAG',
          setFilterContItems: setFilterItems,
        });
      }}
    >
      Adicionar itens a carga
    </Button>
  );
  const updateItemsFooter = (
    <Space>
      <Popconfirm
        zIndex={1041}
        title="Tem certeza que deseja cancelar?"
        okText="Sim"
        cancelText="Não"
        onConfirm={() => setOpenUpdateLoad(false)}
      >
        <Button icon={<CloseOutlined />} danger>
          Cancelar
        </Button>
      </Popconfirm>

      <Popconfirm
        zIndex={1041}
        title="Finalizar operação?"
        okText="Sim"
        cancelText="Não"
        onConfirm={() => {
          const newItems = addedItems.filter(item => !loadItems.includes(item));
          const newItems2 = removeDuplicates(newItems) || [];
          addTruckLoadItems({
            where: { id: truckLoadId },
            input: {
              data: newItems2.map((item, index) => {
                return {
                  allocated_amount: item.allocatedAmount || 0,
                  contract_item_id: item.id,
                  extra_item: true,
                  item_level: 0,
                  load_sort: index,
                  item_position: item.index || 0,
                };
              }),
            },
          })
            .then(() => message.success('Itens adicionados com sucesso!'))
            .catch(() => message.error('Erro ao atualizar itens da carga'));
          const aux = removeDuplicates(addedItems) || [];
          setLoadItems(aux);
          setOpenUpdateLoad(false);
        }}
      >
        <Button icon={<CheckOutlined />} type="primary">
          Finalizar
        </Button>
      </Popconfirm>
    </Space>
  );
  const updateTruckLoadItemsModal = (
    <Modal
      zIndex={1040}
      width={1700}
      title="Atualizar itens da carga"
      open={openUpdateLoad}
      footer={updateItemsFooter}
      maskClosable={false}
      closable={false}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <ContractItemsTable
            data={filterItems}
            form={contractItemsForm}
            itemsToAdd={itemsToAdd}
            setItemsToAdd={setItemsToAdd}
            isLoading={isFetchingCI}
          />
        </Col>
        <Col span={24} style={{ textAlign: 'right' }}>
          {addItemsButton}
        </Col>
        <Col span={24}>
          <OrderingTable
            isLoading={isFetchingCI}
            isEdition={true}
            filterItems={filterItems}
            itemsToAdd={itemsToAdd}
            contractItemsForm={contractItemsForm}
            setFilterItems={setFilterItems}
            data={addedItems}
            setData={setAddedItems}
          />
        </Col>
      </Row>
    </Modal>
  );

  // extra update buttons
  const updateDriver = enableEdition && (
    <Button
      type="primary"
      icon={<EditOutlined />}
      onClick={() => setOpenUpdateDriver(true)}
    >
      Editar
    </Button>
  );
  const updateLoad = truckLoadData?.status === 'AUTHORIZED_LOAD' &&
    truckLoadData?.driver && (
      <Space>
        <Button
          type="primary"
          icon={<EditOutlined />}
          onClick={() => {
            doTruckLoadItemsRequest();
            setOpenUpdateLoad(true);
          }}
        >
          Editar
        </Button>
        <Popconfirm
          title="Tem certeza que deseja carregar tudo?"
          okText="Sim"
          cancelText="Não"
          onConfirm={() => {
            setLoadAll(true);
            setHasLoadAll(true);
          }}
        >
          <Button disabled={isAdding} icon={<DownloadOutlined />}>
            Carregar tudo
          </Button>
        </Popconfirm>
      </Space>
    );

  useEffect(() => {
    doTruckLoadRequest();
  }, []);

  return (
    <Layout>
      <PageHeader
        ghost={false}
        title={t(`viewLoad.title`)}
        subTitle={t(`viewLoad.subtitle`)}
        breadcrumb={<BreadCrumb lastName={'Detalhes'} />}
      />
      <Layout.Content style={{ margin: 24 }}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Card title="Dados básicos da carga" loading={isFetchingTruckLoad}>
              <LoadData data={loadData} />
            </Card>
          </Col>

          <Col span={24}>
            <Card
              title="Carga formada"
              loading={isFetchingTruckLoad}
              extra={updateLoad}
            >
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <OrderingTable
                    isLoading={isUpdating}
                    isViewLoad={true}
                    data={loadItems}
                    loadedAmountForm={loadItemsForm}
                    loadAll={loadAll}
                    setLoadAll={setLoadAll}
                  />
                </Col>
                {truckLoadData?.status === 'AUTHORIZED_LOAD' &&
                  truckLoadData?.driver && (
                    <Col span={24} style={{ textAlign: 'right' }}>
                      <Popconfirm
                        title="Finalizar operação?"
                        onConfirm={() => {
                          loadTruckLoadItemsRequest({
                            data: loadItems.map(
                              ({ allocatedAmount, truckLoadItemId }) => {
                                return {
                                  where: {
                                    id: truckLoadItemId || '',
                                  },
                                  amount: allocatedAmount || 0,
                                };
                              }
                            ),
                          });
                        }}
                      >
                        <Button
                          loading={isLoading}
                          disabled={!hasLoadAll || isAdding}
                          icon={<CheckOutlined />}
                          type="primary"
                        >
                          Concluir carregamento
                        </Button>
                      </Popconfirm>
                    </Col>
                  )}
              </Row>
            </Card>
          </Col>

          <Col span={24}>
            <Card
              loading={isFetchingTruckLoad || isUpdating}
              title="Dados da transportadora/motorista"
              extra={updateDriver}
            >
              <ShippingCompanyDescriptions
                data={truckLoadData?.shippingCompany}
              />
              <Divider />
              <DriverDescriptions data={truckLoadData?.driver} />
            </Card>
          </Col>

          {userData && truckLoadData?.driver && (
            <Col span={24}>
              <Card
                title={
                  truckLoadData?.status === 'AUTHORIZED_LOAD' ||
                  truckLoadData?.status === 'LOADED'
                    ? 'Carga autorizada'
                    : 'Autorizar carga'
                }
                loading={isAuth}
              >
                <TruckLoadAuthorization
                  handleAuthorization={() => handleAuthorization(truckLoadId)}
                  loadAuthorized={
                    truckLoadData?.status === 'AUTHORIZED_LOAD' ||
                    truckLoadData?.status === 'LOADED'
                  }
                />
              </Card>
            </Col>
          )}
        </Row>
      </Layout.Content>
      {updateDriverAndCarrierModal}
      {updateTruckLoadItemsModal}
    </Layout>
  );
};

export default ViewLoad;
