import { Descriptions } from 'antd';
import { ReactElement, useContext, useEffect, useState } from 'react';
import Formatter from '../../../../../classes/Formatter';
import UserContext from '../../../../../contexts/user';
import { useRequest } from '../../../../../hooks/useRequest';
import TruckLoadsController from '../../../../../structures/controllers/TruckLoad';

const { Item } = Descriptions;

export interface IDriverData {
  name: string;
  documentNumberCpf: string;
  phoneNumber: string;
  vehicleModel: string;
  vehicleLicensePlate: string;
  note: string;
  driverId: string;
  vehicleId: string;
  phone: string;
  email: string;
  notes: string;
}
interface IDriverDescriptionProps {
  data: IDriverData | undefined;
}

const DriverDescriptions = ({
  data,
}: IDriverDescriptionProps): ReactElement => {
  const { userData, customerData } = useContext(UserContext);
  const [fetchVehicles] = useRequest(TruckLoadsController.getVehicles);
  const [vehicle, setVehicle] = useState<string>();

  if (!data)
    data = {
      documentNumberCpf: '---',
      phoneNumber: '---',
      name: '---',
      driverId: '---',
      vehicleId: '---',
      phone: '---',
      email: '---',
      notes: '---',
      vehicleModel: '---',
      vehicleLicensePlate: '---',
      note: '---',
    };

  const {
    vehicleModel,
    vehicleLicensePlate,
    name,
    note,
    documentNumberCpf,
    phoneNumber,
  } = data;

  useEffect(() => {
    const orgId = customerData?.id || userData?.organization.id;
    if (!orgId) return;
    fetchVehicles({ organizationId: orgId, takeCommonVehicles: true })
      .then(res => {
        const response = res.find(item => item.id === vehicleModel);
        setVehicle(response?.vehicleModel);
      })
      .catch();
  }, [customerData?.id, userData?.organization.id, vehicleModel]);

  return (
    <Descriptions labelStyle={{ fontWeight: 'bold' }}>
      <Item label="Motorista">{name}</Item>

      <Item label="CPF do motorista">
        {Formatter.formatCPF(documentNumberCpf)}
      </Item>

      <Item label="Telefone">
        {Formatter.formatPhoneWithoutDDI(phoneNumber)}
      </Item>

      <Item label="Modelo do veículo">{vehicle}</Item>

      <Item label="Placa do veículo">{vehicleLicensePlate}</Item>

      <Item label="Observações">{note}</Item>
    </Descriptions>
  );
};

export default DriverDescriptions;
