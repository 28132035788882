import { Calendar } from 'antd';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import {
  ITruckLoadCadences,
  ITruckLoadRules,
} from '../../../../../structures/interfaces/TruckLoad';

import moment from 'moment';
import DefaultAntdCell from './DefaultAntdCell';
import DefaultDisableAntdCell from './DefaultDisableCalendarCell';

interface ITruckLoadsCalendarProps {
  cadences: ITruckLoadRules['cif_cadences'] | ITruckLoadRules['cif_cadences'];
  setSelectedCadence: Dispatch<SetStateAction<ITruckLoadCadences | undefined>>;
}

const renderDate = (
  date: moment.Moment,
  cadences: ITruckLoadRules['cif_cadences'] | ITruckLoadRules['fob_cadences']
) => {
  const isCadenceDay = cadences.find(cadence =>
    moment(cadence.startTime).isSame(date, 'day')
  );

  const disableDaysBeforeToday = !moment(date).isAfter(
    moment().subtract(1, 'days'),
    'day'
  );

  if (!cadences.length) {
    return <DefaultAntdCell date={date} cadence={isCadenceDay} />;
  } else if (!isCadenceDay) {
    return <DefaultDisableAntdCell date={date} />;
  } else if (isCadenceDay && disableDaysBeforeToday) {
    return <DefaultDisableAntdCell date={date} />;
  } else {
    return <DefaultAntdCell date={date} cadence={isCadenceDay} />;
  }
};

const TruckLoadsCalendar = ({
  cadences,
  setSelectedCadence,
}: ITruckLoadsCalendarProps): ReactElement => {
  return (
    <Calendar
      dateFullCellRender={date => renderDate(date, cadences)}
      onSelect={date => {
        const selected = cadences.find(cadence =>
          moment(cadence.startTime).isSame(date, 'day')
        );
        setSelectedCadence(selected);
      }}
    />
  );
};

export default TruckLoadsCalendar;
