import { Col, Form, FormInstance, Input, message, Row } from 'antd';
import { ReactElement, useEffect } from 'react';
import Formatter from '../../../../../classes/Formatter';
import Normalizer from '../../../../../classes/Normalizer';
import { useRequest } from '../../../../../hooks/useRequest';
import ShippingCompanyController from '../../../../../structures/controllers/ShippingCompany';
import { IShippingCompanyFormValues } from '../../../../settings/shippingCompany/ShippingCompanyPage.new';

interface IShippingCompanyFormProps {
  form: FormInstance<IShippingCompanyFormValues>;
  updateData?: IShippingCompanyFormValues;
}

const ShippingCompanyForm = ({
  form,
  updateData,
}: IShippingCompanyFormProps): ReactElement => {
  const [listShippingCompanies] = useRequest(
    ShippingCompanyController.listShippingCompanies
  );

  const handleSearch = (cnpj: string) => {
    if (cnpj) {
      const searchValue = Normalizer.onlyNumbers(cnpj);
      listShippingCompanies({ cnpj: searchValue })
        .then(res => {
          if (res.length === 0) {
            form.resetFields([
              'shippingCompanyId',
              'companyName',
              'tradeName',
              'email',
              'phoneNumber',
            ]);
            return message.error({
              style: { marginTop: 60 },
              content: 'Nenhuma transportadora encontrada com este CNPJ!',
              duration: 3,
            });
          }
          const shippingCompany = res[0];
          form.setFieldsValue({
            shippingCompanyId: shippingCompany?.shippingCompanyId,
            companyName: shippingCompany?.companyName,
            tradeName: shippingCompany?.tradeName,
            email: shippingCompany?.email,
            phoneNumber: Formatter.formatPhoneWithoutDDI(
              shippingCompany?.phoneNumber
            ),
          });
        })
        .catch(() => {
          form.resetFields();
          message.error('Erro ao buscar transportadora');
        });
    }
  };

  useEffect(() => {
    if (updateData) {
      form.setFieldsValue({
        documentNumberCnpj: Formatter.formatCNPJ(
          updateData?.documentNumberCnpj
        ),
        shippingCompanyId: updateData?.shippingCompanyId,
        companyName: updateData?.companyName,
        tradeName: updateData?.tradeName,
        email: updateData?.email,
        phoneNumber: Formatter.formatPhoneWithoutDDI(updateData?.phoneNumber),
      });
    }
  }, [updateData]);

  return (
    <Form layout="vertical" form={form}>
      <Form.Item name={'shippingCompanyId'} hidden />
      <Row gutter={[24, 0]}>
        <Col span={6}>
          <Form.Item
            label="CNPJ"
            name={'documentNumberCnpj'}
            normalize={Formatter.formatCNPJ}
            rules={[{ required: true }]}
          >
            <Input.Search onSearch={cnpj => handleSearch(cnpj)} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Nome da transportadora"
            name={'tradeName'}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Nome fantasia"
            name={'companyName'}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="E-mail" name={'email'}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Telefone"
            name={'phoneNumber'}
            normalize={Formatter.formatPhoneWithoutDDI}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ShippingCompanyForm;
