import client from '../../api/urql/customClient';
import {
  IGetOrganizationPendencies,
  pendenciesInput,
} from '../interfaces/Home';
import homeQueries, { IHomeTiles } from '../queries/Home';

class HomeController {
  static fetchHomeTiles = ({ orgId }: { orgId: string }): Promise<IHomeTiles> =>
    new Promise((resolve, reject) =>
      client
        .query(homeQueries.fetchHomeTiles(), {
          contractWhere: { organization_id: { equals: orgId } },
          truckLoadWhere: { organization_id: { equals: orgId } },
        })
        .then(data => resolve(data))
        .catch(err => reject(err))
    );

  static getOrganizationPendencies = (
    id: string
  ): Promise<IGetOrganizationPendencies> =>
    new Promise((resolve, reject) =>
      client
        .query(homeQueries.getOrganizationPendencies(), {
          where: { id: id },
        })
        .then(({ pendencies }) => {
          resolve(pendencies);
        })
        .catch(err => reject(err))
    );

  static updateOrganizationPendencies = ({
    id,
    pendenciesInput,
  }: {
    id: string;
    pendenciesInput: pendenciesInput;
  }): Promise<IGetOrganizationPendencies> =>
    new Promise((resolve, reject) =>
      client
        .mutation(homeQueries.updateOrganizationPendencies(), {
          where: { id: id },
          input: {
            my_company: pendenciesInput.myCompany,
            rules: pendenciesInput.rules,
          },
        })
        .then(({ updatePendencies }) => {
          resolve(updatePendencies);
        })
        .catch(err => reject(err))
    );
}

export default HomeController;
