import { Card, Col, Layout, message, Row } from 'antd';
import { ReactElement, useContext, useEffect, useState } from 'react';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import ContractController from '../../structures/controllers/Contract';
import { IFetchContracts } from '../../structures/interfaces/Contract';
import { BuildSlug } from '../contracts/Contracts.utils';
import ContractsFilter from '../contracts/ContractsPage.filter';
import ViewContracts from '../contracts/ViewContract';
import HomeTable from './HomePage.table';
import HomeTiles from './HomePage.tiles';

const HomePage = (): ReactElement => {
  const { userData, customerData } = useContext(UserContext);
  const orgId = userData?.organization.id || customerData?.organizationId;

  const [contracts, setContracts] = useState<IFetchContracts[]>([]);
  const [filterContracts, setFilterContracts] = useState<IFetchContracts[]>([]);
  const [selectedContract, setSelectedContract] = useState<IFetchContracts>();
  const [open, setOpen] = useState(false);

  const [fetchContracts, isFetching] = useRequest(
    ContractController.fetchContracts
  );

  const handleFetchContracts = () => {
    if (userData) {
      fetchContracts({ orgId })
        .then(res => {
          const data = BuildSlug(res);
          setContracts(data);
          setFilterContracts(data);
        })
        .catch(() => message.error('Não foi possível buscar seus contratos'));
    } else {
      const farmIds = customerData?.farms.map(farm => farm.id);
      fetchContracts({ farmIds })
        .then(res => {
          const data = BuildSlug(res);
          setContracts(data);
          setFilterContracts(data);
        })
        .catch(() => message.error('Não foi possível buscar seus contratos'));
    }
  };

  useEffect(() => {
    handleFetchContracts();
  }, []);

  return (
    <Layout style={{ margin: 24 }}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <HomeTiles />
        </Col>

        <Col span={24}>
          <ContractsFilter
            contracts={contracts}
            setFilteredContracts={setFilterContracts}
          />
        </Col>

        <Col span={24}>
          <Card title="Pedidos">
            <HomeTable
              data={filterContracts}
              isLoading={isFetching}
              setSelectedContract={setSelectedContract}
              setOpen={setOpen}
            />
          </Card>
        </Col>
      </Row>

      <ViewContracts
        open={open}
        onClose={() => setOpen(false)}
        contractData={selectedContract}
      />
    </Layout>
  );
};

export default HomePage;
