import { ArrowDownOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  FormInstance,
  Popconfirm,
  Row,
  TimePicker,
} from 'antd';
import moment from 'moment';
import { ReactElement, useState } from 'react';
import { useRuleContext } from '../../../../contexts/rule/RuleContext.hooks';
import {
  ICalendarCadences,
  ISchedulingConfig,
} from '../../../../structures/interfaces/Rules';
import {
  GetListData,
  handleCalendarScroll,
  RulePeriodDates,
} from '../../RulesForm.utils';
import CifForm from './CifForm';
import FobForm from './FobForm';

interface ICapacitiesFormProps {
  form: FormInstance<ISchedulingConfig>;
  isLoading: boolean;
}

const CapacitiesForm = ({
  form,
  isLoading,
}: ICapacitiesFormProps): ReactElement => {
  const { operation, handleSetCalendarDates } = useRuleContext();

  const [cifOpen, setCifOpen] = useState(false);
  const [fobOpen, setFobOpen] = useState(false);

  const handleCreateCadences = () => {
    const { startDate, endDate, fob, scheduleHours, cif } =
      form.getFieldsValue();

    const {
      generalCapacity: fobCapacity,
      saturday: fobSaturday,
      sunday: fobSunday,
    } = fob;
    const {
      generalCapacity: cifCapacity,
      saturday: cifSaturday,
      sunday: cifSunday,
    } = cif;

    const period = RulePeriodDates([startDate, endDate]);

    if (scheduleHours) {
      const [startTime, endTime] = scheduleHours;
      const [fobSatStartTime, fobSatEndTime] = fobSaturday?.hours || [];
      const [fobSunStartTime, fobSunEndTime] = fobSunday?.hours || [];
      const [cifSatStartTime, cifSatEndTime] = cifSaturday?.hours || [];
      const [cifSunStartTime, cifSunEndTime] = cifSunday?.hours || [];

      const fobWeek = {
        capacityPerDay: fobCapacity,
        availableUnits: fobCapacity,
        startTime: moment(startTime),
        endTime: moment(endTime),
      };
      const fobSat = {
        capacityPerDay: fobSaturday?.capacity,
        availableUnits: fobSaturday?.capacity,
        startTime: fobSatStartTime ? moment(fobSatStartTime) : undefined,
        endTime: fobSatEndTime ? moment(fobSatEndTime) : undefined,
      };
      const fobSun = {
        capacityPerDay: fobSunday?.capacity,
        availableUnits: fobSunday?.capacity,
        startTime: fobSunStartTime ? moment(fobSunStartTime) : undefined,
        endTime: fobSunEndTime ? moment(fobSunEndTime) : undefined,
      };
      const cifWeek = {
        capacityPerDay: cifCapacity,
        availableUnits: cifCapacity,
        startTime: moment(startTime),
        endTime: moment(endTime),
      };
      const cifSat = {
        capacityPerDay: cifSaturday?.capacity,
        availableUnits: cifSaturday?.capacity,
        startTime: cifSatStartTime ? moment(cifSatStartTime) : undefined,
        endTime: cifSatEndTime ? moment(cifSatEndTime) : undefined,
      };
      const cifSun = {
        capacityPerDay: cifSunday?.capacity,
        availableUnits: cifSunday?.capacity,
        startTime: cifSunStartTime ? moment(cifSunStartTime) : undefined,
        endTime: cifSunEndTime ? moment(cifSunEndTime) : undefined,
      };

      const weekCadences = {
        cif: cifWeek,
        fob: fobWeek,
        endTime: moment(endTime),
        startTime: moment(startTime),
      };
      const satCadences = {
        cif: cifSat,
        fob: fobSat,
      };
      const sunCadences = {
        cif: cifSun,
        fob: fobSun,
      };

      const data = period.map(date => {
        const haveWeekendTime =
          !!(cifSatStartTime && fobSatEndTime) ||
          !!(cifSunStartTime && fobSunEndTime);

        const specialHours =
          haveWeekendTime &&
          (date.isoWeekday() === 6 || date.isoWeekday() === 7);

        const listData = GetListData({
          value: date,
          generalWeek: weekCadences,
          saturday: satCadences,
          sunday: sunCadences,
          specialHours,
        });

        if (listData) {
          const { listData: calendarCell, fillCalendar } = listData;
          return {
            date,
            calendarCell,
            fillCalendar,
          };
        }
      }) as ICalendarCadences[];

      handleSetCalendarDates(data);
    }
  };

  const scheduleField = (
    <Row>
      <Col span={6}>
        <Form.Item label="Horários" name={'scheduleHours'}>
          <TimePicker.RangePicker style={{ width: '100%' }} format={'HH:mm'} />
        </Form.Item>
      </Col>
    </Row>
  );
  const createBtn = (
    <Button
      icon={<ArrowDownOutlined />}
      type="primary"
      onClick={() => {
        handleCalendarScroll();
        handleCreateCadences();
      }}
    >
      Criar cadências
    </Button>
  );
  const updateBtn = (
    <Popconfirm
      placement="topLeft"
      title="Ao confirmar, todas as cadências e suas quantidades serão atualizadas, deseja continuar?"
      onConfirm={handleCalendarScroll}
    >
      <Button icon={<ArrowDownOutlined />} type="primary">
        Atualizar cadências
      </Button>
    </Popconfirm>
  );
  const confirmCadences = operation === 'create' ? createBtn : updateBtn;

  return (
    <Card title="Capacidades" loading={isLoading}>
      {scheduleField}
      <Row>
        <CifForm cifOpen={cifOpen} setCifOpen={setCifOpen} form={form} />
        <FobForm fobOpen={fobOpen} setFobOpen={setFobOpen} form={form} />
      </Row>
      <Divider style={{ background: '#d9d9d9' }} />
      {operation === 'update' && (
        <Alert
          description="ATENÇÃO! As cadências desta regra podem estar sendo utilizadas em algum agendamendo, caso decida criar novamente, os dados anteriores serão todos perdidos!"
          type="warning"
          style={{ marginBottom: 24 }}
        />
      )}
      {confirmCadences}
    </Card>
  );
};

export default CapacitiesForm;
