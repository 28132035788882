import { gql, TypedDocumentNode } from 'urql';

import { ILogin, IUpdateUserDataInput } from '../../utils/interfaces';
import {
  ICreateUser,
  ICreateUserResponse,
  ICrop,
  ICustomerOrganization,
  IGetLoginDataUser,
  IGetPermission,
  IGetUser,
  IGetUserResponse,
  IRegisterUserResponse,
  IResetPasswordResponse,
  ISendRecoveryCode,
  IUpdateUser,
  IUpdateUserAccount,
  IUserLogin,
  IUserLoginResponse,
  IUserOrganizationsResponse,
  IUserPermissionsResponse,
  IUserResponse,
  IValidateEmailResponse,
  IValidateRecoveryCode,
} from '../interfaces/User';

const userFragment = `
  id
  firstName: first_name
  lastName: last_name
  documentNumber: document_number
  phoneNumber: phone_number
  email
`;

const queries = {
  login: (): TypedDocumentNode<IUserLoginResponse, IUserLogin> => gql`
    mutation ($email: String!, $password: String!) {
      Login(input: { email: $email, password: $password }) {
        token
      }
    }
  `,

  user: (): TypedDocumentNode<IUserResponse, { userId: string }> =>
    gql`
      query ($userId: String!) {
        User(input: { id: $userId }) {
          ${userFragment}
        }
      }
    `,

  getUserOrganizations: (): TypedDocumentNode<
    IUserOrganizationsResponse,
    { userId: string }
  > =>
    gql`
      query {
        OrganizationManagers(
          where: { reference_user_id: { equals: $userId } }
        ) {
          organization {
            id
            name
          }
        }
      }
    `,

  createUser: (): TypedDocumentNode<
    { createdUser: ICreateUserResponse },
    { createUserInput: ICreateUser }
  > => gql`
    mutation CreateUser($createUserInput: UserCreateInput!) {
      createdUser: CreateUser(input: $createUserInput) {
        token
      }
    }
  `,

  validateEmail: (): TypedDocumentNode<
    IValidateEmailResponse,
    { token: string }
  > => gql`
    mutation validateEmail($token: String!) {
      validateEmail: ValidateEmail(input: { token: $token }) {
        token
      }
    }
  `,

  getFirstUserData: (): TypedDocumentNode<
    { firstUserData: IGetUserResponse },
    { where: { id: string } }
  > => gql`
    query getFirstUserData($where: GetUserInput!) {
      firstUserData: GetUserFirstLogin(where: $where) {
        id
        name
        email
        documentNumberCpf: document_number_cpf
        phoneNumber: phone_number
        userHasPermissions: user_has_permissions {
          userPermissions: user_permissions {
            id
            module
            operation
          }
        }
        userType: user_type {
          id
          typeDescription: type_description
        }
        organization {
          id
          documentNumberCnpj: document_number_cnpj
          tradeName: trade_name
        }
      }
    }
  `,

  getUser: (): TypedDocumentNode<
    { getUserData: IGetUserResponse },
    { where: { id: string } }
  > => gql`
    query getUser($where: GetUserInput!) {
      getUserData: GetUser(where: $where) {
        id
        name
        email
        receiveNotifications: receive_notifications
        ignoreDriverExpiration: ignore_driver_expiration
        documentNumberCpf: document_number_cpf
        phoneNumber: phone_number
        userHasPermissions: user_has_permissions {
          userPermissions: user_permissions {
            id
            module
            operation
          }
        }
        userType: user_type {
          id
          typeDescription: type_description
        }
        organization {
          id
          documentNumberCnpj: document_number_cnpj
          tradeName: trade_name
        }
      }
    }
  `,

  sendRecoveryCode: (): TypedDocumentNode<
    ISendRecoveryCode,
    { input: { email: string } }
  > => gql`
    mutation ($input: RecoverPasswordInput!) {
      sendCode: SendRecoveryCode(input: $input) {
        success
      }
    }
  `,

  getUserDataLogin: (): TypedDocumentNode<
    { getUserData: IGetLoginDataUser },
    { where: { id: string } }
  > => gql`
    query getUser($where: GetUserInput!) {
      getUserData: GetUser(where: $where) {
        id
        organization {
          id
        }
        userType: user_type {
          id
        }
        userHasPermissions: user_has_permissions {
          userPermissions: user_permissions {
            id
            module
            operation
          }
        }
      }
    }
  `,
  validadeRecoveryCode: (): TypedDocumentNode<
    IValidateRecoveryCode,
    { input: { email: string; recovery_code: string } }
  > => gql`
    mutation ($input: ValidateRecoveryCodeInput!) {
      validateToken: ValidateRecoveryCode(input: $input) {
        token
      }
    }
  `,

  updatePassword: (): TypedDocumentNode<
    { status: boolean },
    { input: { password: string }; where: { id: string } }
  > => gql`
    mutation ($input: UpdatePasswordInput!, $where: UserWhereUniqueInput!) {
      status: UpdatePassword(input: $input, where: $where) {
        success
      }
    }
  `,

  resetPassword: (): TypedDocumentNode<
    IResetPasswordResponse,
    { input: { password: string }; where: { id: string } }
  > => gql`
    mutation ($input: UpdatePasswordInput!, $where: UserWhereUniqueInput!) {
      resetpassword: ResetPassword(input: $input, where: $where) {
        success
      }
    }
  `,

  userPermissionsTable: (): TypedDocumentNode<{
    getPermissions: IUserPermissionsResponse[];
  }> => gql`
    query getPermissions {
      getPermissions: GetTypesPermissions {
        userType: user_type {
          id
        }
        userPermissions: user_permission {
          id
          module
          operation
        }
      }
    }
  `,

  getUserByOrganizationId: (): TypedDocumentNode<
    { getUser: IGetUser[] },
    { where: { organization_id: string } }
  > => gql`
    query getUsersByOrganizationId($where: FindUsersByOrganizationInput!) {
      getUser: FindUsersByOrganization(where: $where) {
        id
        name
        email
        receiveNotifications: receive_notifications
        ignoreDriverExpiration: ignore_driver_expiration
        documentNumberCpf: document_number_cpf
        phoneNumber: phone_number
        createdAt: created_at
        updatedAt: updated_at
        status
        organization {
          tradeName: trade_name
          id
        }
        userType: user_type {
          id
          typeDescription: type_description
        }
      }
    }
  `,

  getUserPermissions: (): TypedDocumentNode<{
    getPermissions: IGetPermission[];
  }> => gql`
    query getPermissions {
      getPermissions: GetTypesPermissions {
        userType: user_type {
          id
          typeDescription: type_description
        }
      }
    }
  `,

  updateUserAccount: (): TypedDocumentNode<
    {
      user: IUpdateUserAccount;
    },
    {
      where: { id: string };
    }
  > => gql`
    mutation updateUser(
      $where: UserWhereUniqueInput!
      $input: UserUpdateInput!
    ) {
      user: UpdateUser(where: $where, input: $input) {
        name
        phoneNumber: phone_number
        email
      }
    }
  `,

  updateUser: (): TypedDocumentNode<
    { user: IUpdateUser },
    { where: { id: string } }
  > => gql`
    mutation updateUser(
      $where: UserWhereUniqueInput!
      $input: UserUpdateInput!
    ) {
      user: UpdateUser(where: $where, input: $input) {
        name
        email
        receiveNotifications: receive_notifications
        ignoreDriverExpiration: ignore_driver_expiration
        documentNumberCpf: document_number_cpf
        phoneNumber: phone_number
        organizationId: organization_id
        userHasPermissions: user_has_permissions {
          userPermissions: user_permissions {
            id
            module
            operation
          }
        }
      }
    }
  `,

  deleteUser: (): TypedDocumentNode<{
    status: boolean;
    where: { id: string };
  }> => gql`
    mutation DeleteUser($where: DeleteUserInput!) {
      status: DeleteUser(where: $where) {
        success
      }
    }
  `,
  registerUser: (): TypedDocumentNode<{
    data: IRegisterUserResponse;
  }> => gql`
    mutation registerUser($input: UserRegistrationInput!) {
      data: RegisterUser(input: $input) {
        password
      }
    }
  `,

  firstLogin: (): TypedDocumentNode<
    { FirstLogin: { token: string } },
    { input: ILogin }
  > => gql`
    mutation firstLogin($input: LoginInput!) {
      FirstLogin(input: $input) {
        token
      }
    }
  `,

  allowUserAccess: (): TypedDocumentNode<{
    allowUserAccess: { success: boolean };
  }> => gql`
    mutation AllowUserAccess($input: AllowUserAccess!) {
      allowUserAccess: AllowUserAccess(input: $input) {
        success
      }
    }
  `,

  updateUserFirstLogin: (): TypedDocumentNode<
    { updateUserData: { success: boolean } },
    { input: IUpdateUserDataInput }
  > => gql`
    mutation updateUserFirstData(
      $where: GetUserInput!
      $input: UserFirstLoginUpdateInput!
    ) {
      updateUserData: UpdateUserFirstLoginData(where: $where, input: $input) {
        success
      }
    }
  `,

  getCrops: (): TypedDocumentNode<
    { getCropsData: ICrop[] },
    { where: { organization_id: string } }
  > => gql`
    query getCrops($where: OrganizationWhere!) {
      getCropsData: GetCrops(where: $where) {
        id
        year
        seedTypeId: seed_type_id
        referenceCode: reference_code
        startDate: start_date
        endDate: end_date
        current
      }
    }
  `,

  getCustomerOrganization: (): TypedDocumentNode<
    { customerOrganization: ICustomerOrganization[] },
    { where: { id: string } }
  > => gql`
    query GetCustomerOrganizations($where: CustomerWhereInput!) {
      customerOrganization: GetCustomerOrganizations(where: $where) {
        id
        logoUrl: logo_url
        documentNumberCnpj: document_number_cnpj
        tradeName: trade_name
        referenceName: reference_name
        companyName: company_name
      }
    }
  `,
};

export default queries;
