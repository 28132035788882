import { DownOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  InputNumber,
  MenuProps,
  Row,
  Typography,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { ReactElement, useEffect, useState } from 'react';
import { ISchedulingConfig } from '../../../../structures/interfaces/Rules';
import WeekendForm from './WeekendForm';

const { Title } = Typography;
interface IFobFormProps {
  fobOpen: boolean;
  setFobOpen: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<ISchedulingConfig>;
}
const FobForm = ({
  fobOpen,
  form,
  setFobOpen,
}: IFobFormProps): ReactElement => {
  const [replyInSaturday, setReplyInSaturday] = useState(false);
  const [replyInSunday, setReplyInSunday] = useState(false);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Checkbox
          checked={replyInSaturday}
          onChange={v => setReplyInSaturday(v.target.checked)}
        >
          Sábado
        </Checkbox>
      ),
    },
    {
      key: '2',
      label: (
        <Checkbox
          checked={replyInSunday}
          onChange={({ target: { checked } }) => setReplyInSunday(checked)}
        >
          Domingo
        </Checkbox>
      ),
    },
  ];

  useEffect(() => {
    const { fob, scheduleHours } = form.getFieldsValue();
    const { generalCapacity } = fob;

    if (replyInSaturday) {
      form.setFieldsValue({
        fob: {
          saturday: {
            capacity: generalCapacity,
            hours: scheduleHours,
          },
        },
      });
    }

    if (replyInSunday) {
      form.setFieldsValue({
        fob: {
          sunday: {
            capacity: generalCapacity,
            hours: scheduleHours,
          },
        },
      });
    }
  }, [replyInSaturday, replyInSunday]);

  return (
    <Col span={12} style={{ paddingLeft: 24 }}>
      <Title level={2} style={{ textAlign: 'center', color: 'purple' }}>
        FOB
      </Title>

      <Row gutter={[12, 0]}>
        <Col span={12}>
          <Form.Item
            label={'Capacidade MAX por dia para embarque'}
            name={['fob', 'generalCapacity']}
            required
          >
            <InputNumber
              addonAfter={'Bags'}
              style={{ width: '100%', textAlign: 'left' }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={'Capacidade MAX dia cliente'}
            name={['fob', 'capacityPerDay']}
            required
          >
            <InputNumber
              addonAfter={'Bags'}
              style={{ width: '100%', textAlign: 'left' }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label=" ">
            <Checkbox
              checked={fobOpen}
              onChange={v => {
                setFobOpen(v.target.checked);
                if (v.target.checked === false) {
                  form.resetFields([
                    ['fob', 'saturday', 'capacity'],
                    ['fob', 'sunday', 'capacity'],
                    ['fob', 'saturday', 'hours'],
                    ['fob', 'sunday', 'hours'],
                  ]);
                }
              }}
              children={'Finais de semana'}
              style={{ marginRight: 24 }}
            />

            <Dropdown menu={{ items }} trigger={['click']} disabled={!fobOpen}>
              <Button disabled={!fobOpen} icon={<DownOutlined />}>
                Replicar dados da semana
              </Button>
            </Dropdown>
          </Form.Item>
        </Col>
        {fobOpen && <WeekendForm ruleType="fob" form={form} />}
      </Row>
    </Col>
  );
};

export default FobForm;
