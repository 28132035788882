import { Col, Form, FormInstance, Input, message, Row } from 'antd';
import { ReactElement, useContext, useEffect } from 'react';
import Formatter from '../../../classes/Formatter';
import { IUseRequestAction, useRequest } from '../../../hooks/useRequest';
import ShippingCompanyController from '../../../structures/controllers/ShippingCompany';
import Normalizer from '../../../classes/Normalizer';
import {
  IAddShippingCompany,
  INewShippingCompany,
  IUpdateShippingCompany,
} from '../../../structures/interfaces/ShippingCompany';
import UserContext from '../../../contexts/user';

export interface IShippingCompanyFormValues {
  shippingCompanyId: string;
  companyName: string;
  tradeName: string;
  email: string;
  documentNumberCnpj: string;
  phoneNumber: string;
}
interface INewShippingCompanyFormProps {
  updateData?: IShippingCompanyFormValues;
  form: FormInstance<IShippingCompanyFormValues>;
  doListShippingCompanies?: () => void;
  doCloseModal?: () => void;
  addNewShippingCompany?: IUseRequestAction<
    IAddShippingCompany,
    INewShippingCompany
  >;
  updateShippingCompany?: IUseRequestAction<
    { where: { id: string }; input: IUpdateShippingCompany },
    { id: string }
  >;
}
const NewShippingCompanyForm = ({
  updateData,
  form,
  doListShippingCompanies,
  doCloseModal,
}: INewShippingCompanyFormProps): ReactElement => {
  const { userData, customerData } = useContext(UserContext);
  const orgId = userData?.organization.id || customerData?.organizationId;

  const [addNewShippingCompany] = useRequest(
    ShippingCompanyController.addNewShippingCompany
  );
  const [updateShippingCompany] = useRequest(
    ShippingCompanyController.updateShippingCompany
  );

  const onFinish = (value: IShippingCompanyFormValues) => {
    if (!updateData) {
      if (orgId) {
        const data = {
          organization_id: orgId,
          company_name: value.companyName,
          document_number_cnpj: value.documentNumberCnpj,
          email: value.email,
          phone_number: value.phoneNumber,
          trade_name: value.tradeName,
        } as IAddShippingCompany;

        addNewShippingCompany(data)
          .then(() => {
            message.success({
              content: 'Transportadora criada com sucesso!',
              style: { marginTop: 60 },
            });
            doListShippingCompanies && doListShippingCompanies();
            doCloseModal && doCloseModal();
          })
          .catch(() => message.error('Erro ao criar transportadora'));
      }
    } else {
      updateShippingCompany({
        where: { id: updateData.id },
        input: {
          email: value.email,
          trade_name: value.tradeName,
          company_name: value.companyName,
          phone_number: Normalizer.onlyNumbers(value.phoneNumber),
        },
      })
        .then(() => {
          message.success('Transportadora atualizada com sucesso!');
          doListShippingCompanies && doListShippingCompanies();
          form.resetFields();
        })
        .catch(() => message.error('Erro ao atualizar transportadora'));
    }
  };

  useEffect(() => {
    if (updateData) {
      form.setFieldsValue({
        ...updateData,
        documentNumberCnpj: Formatter.formatCNPJ(updateData.documentNumberCnpj),
        phoneNumber: Formatter.formatPhoneWithoutDDI(updateData.phoneNumber),
      });
    }
  }, [updateData]);

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Form.Item
            name="companyName"
            label="Nome da transportadora"
            rules={[{ required: true }]}
          >
            <Input placeholder="Insira o nome da transportadora" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="tradeName"
            label="Nome Fantasia"
            rules={[{ required: true }]}
          >
            <Input placeholder="Insira o nome fantasia" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="email" label="E-mail">
            <Input placeholder="Insira o e-mail da transportadora" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="documentNumberCnpj"
            label="CNPJ"
            normalize={Formatter.formatCNPJ}
            rules={[{ required: true }]}
          >
            <Input placeholder="Insira o CNPJ da transportadora" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="phoneNumber"
            label="Telefone"
            normalize={Formatter.formatPhoneWithoutDDI}
          >
            <Input placeholder="Insira o telefone da transportadora" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default NewShippingCompanyForm;
