import { gql, TypedDocumentNode } from 'urql';
import { IGetOrganizationPendencies } from '../interfaces/Home';

export interface IHomeTiles {
  totalLoads: number;
  authorizedLoads: number;
  waitingDriverLoads: number;
  loadedLoads: number;
  canceledLoads: number;
  finalizedContracts: number;
  pendingContracts: number;
}

const homeQueries = {
  fetchHomeTiles: (): TypedDocumentNode<
    IHomeTiles,
    {
      truckLoadWhere: {
        organization_id: {
          equals: string;
        };
      };
      contractWhere: {
        organization_id: {
          equals: string;
        };
      };
    }
  > => gql`
    query homeTiles(
      $truckLoadWhere: TruckLoadWhereInput!
      $contractWhere: ContractWhereInput!
    ) {
      totalLoads: CountTotalTruckLoads(where: $truckLoadWhere)
      authorizedLoads: CountAuthorizedTruckLoads(where: $truckLoadWhere)
      waitingDriverLoads: CountTruckLoadsWaitingForDriver(
        where: $truckLoadWhere
      )
      loadedLoads: CountLoadedTruckLoads(where: $truckLoadWhere)
      canceledLoads: CountCanceledTruckLoads(where: $truckLoadWhere)
      finalizedContracts: CountFinalizedContracts(where: $contractWhere)
      pendingContracts: CountPendingContracts(where: $contractWhere)
    }
  `,

  getOrganizationPendencies: (): TypedDocumentNode<
    { pendencies: IGetOrganizationPendencies },
    {
      where: { id: string };
    }
  > => gql`
    query getOrganizationPendencies($where: OrganizationWhereUniqueInput!) {
      pendencies: GetOrganizationPendencies(where: $where) {
        id
        organizationId: organization_id
        myCompany: my_company
        rules
        organization {
          tradeName: trade_name
        }
      }
    }
  `,

  updateOrganizationPendencies: (): TypedDocumentNode<
    {
      updatePendencies: IGetOrganizationPendencies;
    },
    {
      where: { id: string };
    }
  > => gql`
    mutation updateOrganizationPendencies(
      $where: OrganizationWhereUniqueInput!
      $input: PendenciesUncheckedUpdateInput!
    ) {
      updatePendencies: UpdateOrganizationPendencies(
        where: $where
        input: $input
      ) {
        id
        organizationId: organization_id
        myCompany: my_company
        rules
        organization {
          tradeName: trade_name
        }
      }
    }
  `,
};

export default homeQueries;
