import {
  ArrowLeftOutlined,
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Statistic,
} from 'antd';
import { ReactElement, useContext, useState } from 'react';
import TruckLoadFooter from '../../../components/TruckLoadFooter';
import OrderingTable from '../stepTwo/StepTwo.orderTable';
import DriverForm, { IDriverFormValues } from './StepThree.driverForm';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Normalizer from '../../../../../classes/Normalizer';
import UserContext from '../../../../../contexts/user';
import { useRequest } from '../../../../../hooks/useRequest';
import TruckLoadsController from '../../../../../structures/controllers/TruckLoad';
import {
  ICreateTruckLoadInputRequest,
  IStepOneData,
  IStepTwoData,
} from '../../../../../structures/interfaces/TruckLoad';
import NewShippingCompanyForm, {
  IShippingCompanyFormValues,
} from '../../../../settings/shippingCompany/ShippingCompanyPage.new';
import { removeDuplicates } from '../LoadSteps.utils';
import LoadData from '../stepTwo/StepTwo.loadData';
import TruckLoadAuthorization from './StepThree.authorization';
import ShippingCompanyForm from './StepThree.shippingCompanyForm';

interface IStepThreeProps {
  nextStep: () => void;
  previousStep: () => void;
  stepOneData: IStepOneData;
  stepTwoData: IStepTwoData;
  customOrder: boolean;
  setCreatedTruckLoadId: (value: string) => void;
  cancelPreSchedule: (value: string, timeout?: boolean) => void;
}
const StepThree = ({
  stepOneData,
  stepTwoData,
  previousStep,
  customOrder,
  setCreatedTruckLoadId,
  cancelPreSchedule,
}: IStepThreeProps): ReactElement => {
  const { t } = useTranslation();
  const [carrierForm] = Form.useForm<IShippingCompanyFormValues>();
  const [driverForm] = Form.useForm<IDriverFormValues>();
  const [loadNotesForm] = Form.useForm<{ notes: string }>();
  const { userData } = useContext(UserContext);

  const history = useHistory();

  const { cancel } = stepOneData;

  const [noShippingCompany, setNoShippingCompany] = useState(false);
  const [informDriverAfterLoad, setInformDriverAfterLoad] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [openCarrierModal, setOpenCarrierModal] = useState(false);
  const [receivedTruckLoadId, setReceivedTruckLoadId] = useState('');

  const [createTruckLoad, isCreating] = useRequest(
    TruckLoadsController.createTruckLoad
  );
  const [authorizeTruckLoad] = useRequest(
    TruckLoadsController.authorizeTruckLoad
  );

  const loadData = {
    availabilityBags: stepTwoData.addedItems?.length || 0,
    name: stepOneData.loadName || '---',
    pickupLocation: '---',
    seedType: stepOneData.seedType || '---',
    selectedDate: stepOneData.selectedDate || '---',
    selectedVehicle: stepOneData.vehicle?.vehicleModel || '---',
    vehicleMaxCapacity: stepOneData.vehicle?.bagsCapacity || 0,
  };
  const addNewShippingCompany = (
    <Button
      icon={<PlusOutlined />}
      type="primary"
      onClick={() => {
        carrierForm.resetFields();
        setOpenCarrierModal(true);
      }}
    >
      Cadastrar nova transportadora
    </Button>
  );

  const handleCreateTruckLoad = () => {
    let driverData: ICreateTruckLoadInputRequest['driver'] | null;
    let shippingCompanyData: IShippingCompanyFormValues | null;

    if (!noShippingCompany) {
      const { documentNumberCnpj, phoneNumber: phone } =
        carrierForm.getFieldsValue();
      const data = {
        ...carrierForm.getFieldsValue(),
        documentNumberCnpj: Normalizer.onlyNumbers(documentNumberCnpj),
        phone: Normalizer.onlyNumbers(phone),
      };
      shippingCompanyData = data;
    } else shippingCompanyData = null;
    if (!informDriverAfterLoad) {
      const { phone, documentNumberCpf } = driverForm.getFieldsValue();
      const data = {
        ...driverForm.getFieldsValue(),
        phone: Normalizer.onlyNumbers(phone),
        documentNumberCpf: Normalizer.onlyNumbers(documentNumberCpf),
      };
      driverData = data;
    } else driverData = null;

    const aux = removeDuplicates(stepTwoData.addedItems);
    const tlItems =
      aux?.map((item, index) => {
        return {
          contractItemId: item.id,
          allocatedAmount: item.allocatedAmount || 0,
          loadSort: index,
        };
      }) || [];

    const loadData: ICreateTruckLoadInputRequest = {
      referenceVehicleId: stepOneData.vehicle?.id || '',
      seedTypeId: stepOneData.seedType || '',

      loadNote: loadNotesForm.getFieldValue('notes'),
      driverId: informDriverAfterLoad
        ? null
        : driverForm.getFieldValue('driverId'),
      shippingCompanyId: noShippingCompany
        ? null
        : carrierForm.getFieldValue('shippingCompanyId'),

      canSortTruckLoadItem: customOrder,
      noShippingCompany: informDriverAfterLoad
        ? informDriverAfterLoad
        : noShippingCompany,

      driver: driverData,
      shippingCompanyData,
      preScheduleId: stepOneData.preScheduleId || '',
      organizationId: stepOneData.organizationId || '',
      vehicleId: stepOneData.vehicle?.id || '',
      name: stepOneData.loadName,
      informDriverAfter: informDriverAfterLoad,
      truckLoadItems: tlItems,
    } as ICreateTruckLoadInputRequest;

    createTruckLoad(loadData)
      .then(res => {
        setReceivedTruckLoadId(res.id);
        if (userData && loadData.driverId && loadData.shippingCompanyId) {
          setOpenAuthModal(true);
        } else {
          message.success({
            content: 'Agendamento criado com sucesso!',
            style: { marginTop: 60 },
          });
          history.push('/cargas');
        }
      })
      .catch((err: { message: string }) => {
        message.error({
          content: t(err.message),
          style: { marginTop: 60 },
        });
      });
  };
  const handleAuthorization = () => {
    authorizeTruckLoad({
      truckLoadId: receivedTruckLoadId,
    })
      .then(() => setCreatedTruckLoadId(receivedTruckLoadId))
      .catch(() => message.error('Falha ao autorizar o agendamento!'));
  };

  const handleAuthorizeLater = () => {
    setCreatedTruckLoadId(receivedTruckLoadId);
  };

  return (
    <>
      <Row gutter={[24, 24]} style={{ marginBottom: 94 }}>
        <Col span={24}>
          <Card title="Dados básicos da carga">
            <LoadData data={loadData} />
          </Card>
        </Col>

        <Col span={24}>
          <Card title="Cargas formadas">
            <OrderingTable data={stepTwoData.addedItems} isStepThree={true} />
          </Card>
        </Col>

        <Col span={24}>
          <Card title="Observações sobre a carga">
            <Form layout="vertical" form={loadNotesForm}>
              <Form.Item
                name="notes"
                label="Se necessário faça observações sobre a carga"
              >
                <Input.TextArea
                  style={{ width: '40%' }}
                  autoSize={{ minRows: 4, maxRows: 4 }}
                  placeholder="Observação sobre a carga"
                />
              </Form.Item>
            </Form>
          </Card>
        </Col>

        <Col span={24}>
          <Card
            title="Dados da transportadora/motorista"
            extra={addNewShippingCompany}
          >
            <Checkbox
              checked={noShippingCompany}
              onChange={value => {
                if (value.target.checked) carrierForm.resetFields();
                setNoShippingCompany(value.target.checked);
              }}
            >
              Não possuo transportadora
            </Checkbox>

            <Checkbox
              checked={informDriverAfterLoad}
              onChange={value => {
                if (value.target.checked) driverForm.resetFields();
                setInformDriverAfterLoad(value.target.checked);
              }}
            >
              Informar transportadora/motorista depois
            </Checkbox>

            {!(noShippingCompany || informDriverAfterLoad) && <Divider />}
            {!(noShippingCompany || informDriverAfterLoad) && (
              <ShippingCompanyForm form={carrierForm} />
            )}
            {!informDriverAfterLoad && <Divider />}
            {!informDriverAfterLoad && <DriverForm form={driverForm} />}
          </Card>
        </Col>
      </Row>

      <TruckLoadFooter>
        <Space size={32} style={{ margin: '24px 0' }}>
          <Statistic.Countdown
            title={'Tempo restante'}
            valueStyle={{ lineHeight: '20px' }}
            format="HH:mm:ss"
            value={cancel?.expiresIn}
            style={{ marginTop: '12px' }}
            onFinish={() => {
              if (cancel?.id) cancelPreSchedule(cancel.id, true);
            }}
          />

          <Space>
            <Popconfirm
              title={'Tem certeza que deseja cancelar?'}
              onConfirm={() => history.push('/cargas')}
            >
              <Button danger icon={<CloseOutlined />}>
                Cancelar
              </Button>
            </Popconfirm>

            <Button icon={<ArrowLeftOutlined />} onClick={previousStep}>
              Voltar
            </Button>

            <Button
              type="primary"
              icon={<CheckOutlined />}
              onClick={handleCreateTruckLoad}
              loading={isCreating}
            >
              Confirmar
            </Button>
          </Space>
        </Space>
      </TruckLoadFooter>

      <Modal title={'Autorizar'} open={openAuthModal} footer={null}>
        <TruckLoadAuthorization
          isModal={true}
          handleAuthorization={handleAuthorization}
          handleAuthorizeLater={handleAuthorizeLater}
          loadAuthorized={false}
        />
      </Modal>

      <Modal
        title="Adicionar transportadora"
        width={1400}
        open={openCarrierModal}
        onOk={() => carrierForm.submit()}
        onCancel={() => setOpenCarrierModal(false)}
      >
        <NewShippingCompanyForm
          form={carrierForm}
          doCloseModal={() => setOpenCarrierModal(false)}
        />
      </Modal>
    </>
  );
};

export default StepThree;
