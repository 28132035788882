import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm, Space, Table, Typography } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Formatter from '../../classes/Formatter';
import { IGetRules } from '../../structures/interfaces/ScheduleRules';
import { useRequest } from '../../hooks/useRequest';
import ScheduleRulesController from '../../structures/controllers/ScheduleRules';
import UserContext from '../../contexts/user';
import { useRuleContext } from '../../contexts/rule/RuleContext.hooks';

interface IRulesTableProps extends TableProps<IGetRules> {
  data: IGetRules[];
  doFetchRequest: (organizationId: string) => void;
}

const renderRulePeriod = (record: IGetRules) => {
  return (
    <Space>
      {Formatter.formatDate(record.startDate)}
      {'-'}
      {Formatter.formatDate(record.endDate)}
    </Space>
  );
};

const RulesTable = ({
  data,
  loading,
  doFetchRequest,
}: IRulesTableProps): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const { userData } = useContext(UserContext);
  const organizationId = userData?.organization.id;
  const { handleSetOperation } = useRuleContext();

  const [deleteRule, isLoading] = useRequest(
    ScheduleRulesController.deleteScheduleRule
  );

  const doDeleteRequest = (id: string) => {
    deleteRule({ id })
      .then(() => {
        if (organizationId) {
          message.success('Regra excluída com sucesso');
          doFetchRequest(organizationId);
        }
      })
      .catch(() =>
        message.error({
          content: 'Erro ao excluir regra',
          style: { marginTop: 60 },
        })
      );
  };

  const rulesColumns: ColumnsType<IGetRules> = [
    {
      title: t('pages.scheduleRules.seedCulture'),
      dataIndex: ['seedType', 'name'],
      key: 'seedTypeId',
    },
    {
      title: t('pages.scheduleRules.pickUpLocation'),
      dataIndex: ['pickUpLocation', 'title'],
      key: 'title',
    },
    {
      title: t('pages.scheduleRules.rulePeriodDate'),
      key: 'rulePeriod',
      render: (_, record) => renderRulePeriod(record),
    },
    {
      title: t('pages.scheduleRules.releaseSubmissionDate'),
      dataIndex: 'releaseSubmissionDate',
      key: 'releaseSubmissionDate',
      render: date => Formatter.formatDate(date),
    },
    {
      title: t('pages.scheduleRules.schedulingStart'),
      dataIndex: 'schedulingStart',
      key: 'schedulingStart',
      render: date => Formatter.formatDate(date),
    },
    {
      title: t('pages.scheduleRules.maximumPerCompany'),
      dataIndex: 'capacityPerCompany',
      key: 'capacityPerCompany',
      render: date => <Typography.Text>{date} bags</Typography.Text>,
    },
    {
      title: t('pages.scheduleRules.whiteSeeds'),
      dataIndex: 'whiteSeedSchedulingTime',
      key: 'whiteSeedSchedulingTime',
      render: date => <Typography.Text>{date} dias</Typography.Text>,
    },
    {
      title: t('pages.scheduleRules.tsiSeeds'),
      dataIndex: 'tsiSeedSchedulingTime',
      key: 'tsiSeedSchedulingTime',
      render: date => <Typography.Text>{date} dias</Typography.Text>,
    },
    {
      title: t('pages.scheduleRules.actions'),
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            type="link"
            onClick={() => {
              handleSetOperation('update');
              history.push(`regras/editar/${record.id}`);
            }}
          >
            Editar
          </Button>

          <Popconfirm
            title="Tem certeza que deseja excluir?"
            onConfirm={() => doDeleteRequest(record.id)}
          >
            <Button type="text" danger icon={<DeleteOutlined />}>
              Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      size="small"
      columns={rulesColumns}
      dataSource={data}
      loading={loading || isLoading}
    />
  );
};

export default RulesTable;
