import { gql, TypedDocumentNode } from 'urql';

import addressFragment from './Address';

import { ICreateCompanyDto } from '../interfaces/Company/CompanyDto';

import {
  IBranchCompanyUpdate,
  ICompanyAPI,
  IDefaultBranchCompanies,
  IDeleteCarrierPolicy,
  IDeleteCarrierPolicyResponse,
  IDeleteCompanyLogo,
  IDeleteCompanyLogoResponse,
  IFindOrganization,
  IGetOrganizationApiKey,
  INewBranchCompany,
  INewBranchCompanyInput,
  IUpdateBranchCompanyInput,
  IUpdateOrganizationInput,
  IUploadCarrierPolicy,
  IUploadCarrierPolicyResponse,
  IUploadCompanyLogo,
  IUploadCompanyLogoResponse,
} from '../interfaces/Company/Company';

const contactFragment = `
  id
  name
  email
  phone
`;

const companyFragment = `
  id
  documentNumber: document_number
  documentType: document_type
  tradeName: trade_name
  companyName: company_name
  urlLogo: url_logo
  companyFunction: company_function
  companyType: company_type
  companyStatus {
    id
    status
  }
`;

const companyDataFragment = `
  contacts {
    ${contactFragment}
  }
  address {
    ${addressFragment}
  }
`;

const queries = {
  registerCompany: (): TypedDocumentNode<
    { createdCompany: ICompanyAPI },
    { createCompanyDto: ICreateCompanyDto }
  > => gql`
    mutation RegisterCompany($createCompanyDto: CreateCompanyDto!) {
      createdCompany: CreateCompany(input: $createCompanyDto) {
        ${companyFragment}
        ${companyDataFragment}
        motherCompany: mother_company {
          ${companyFragment}
          ${companyDataFragment}
        }
        referenceCompany: reference_company {
          id
          documentNumber: document_number
          documentType: document_type
        }
      }
    }
  `,

  findCompanyByDocument: (): TypedDocumentNode<
    { foundCompanies: ICompanyAPI[] },
    { documentNumber: string }
  > => gql`
    query findCompany($documentNumber: String!) {
      foundCompanies: FindCompanies(input: { document_number: $documentNumber }) {
        ${companyFragment}
        motherCompany: mother_company {
          ${companyFragment}
        }
      }
    }
  `,

  uploadCompanyLogo: (): TypedDocumentNode<
    { uploadedFile: IUploadCompanyLogoResponse },
    IUploadCompanyLogo
  > => gql`
    mutation updateOrganizationLogo($where: UploadLogo!, $file: Upload!) {
      uploadedFile: UpdateOrganizationLogo(where: $where, file: $file) {
        logo_url
      }
    }
  `,

  deleteCompanyLogo: (): TypedDocumentNode<
    { deletedLogo: IDeleteCompanyLogoResponse },
    IDeleteCompanyLogo
  > => gql`
    mutation DeleteLogo($input: DeleteLogoDto!) {
      deletedLogo: DeleteLogo(input: $input) {
        deleted
      }
    }
  `,
  uploadCarrierPolicy: (): TypedDocumentNode<
    { uploadedPolicy: IUploadCarrierPolicyResponse },
    IUploadCarrierPolicy
  > => gql`
    mutation UploadPolicy($file: Upload!, $input: PolicyInput!) {
      uploadedPolicy: UploadPolicy(file: $file, input: $input) {
        id
        name
        companyId: company_id
        url: bucket_url
        expiresIn: expires_in
      }
    }
  `,
  deleteCarrierPolicy: (): TypedDocumentNode<
    { deletedPolicy: IDeleteCarrierPolicyResponse },
    IDeleteCarrierPolicy
  > => gql`
    mutation DeletePolicy($input: DeletePolicyDto!) {
      deletedPolicy: DeletePolicy(input: $input) {
        deleted
      }
    }
  `,

  findCompanyByOrganizationId: (): TypedDocumentNode<
    { getCompany: IFindOrganization },
    {
      where: {
        id: string;
      };
    }
  > => gql`
    query findCompany($where: OrganizationWhereUniqueInput!) {
      getCompany: FindOrganization(where: $where) {
        documentNumberCnpj: document_number_cnpj
        tradeName: trade_name
        companyName: company_name
        logoUrl: logo_url
        contacts {
          id
          email
          phoneNumber: phone_number
        }
        addresses {
          id
          zipCode: zip_code
          stateShortName: state_short_name
          cityName: city_name
          district
          street
          streetNumber: street_number
          complement
          pickUpLocation: pick_up_location
          defaultAddress: default_address
        }
      }
    }
  `,

  updateOrganization: (): TypedDocumentNode<
    { updateOrganization: IFindOrganization },
    { where: { id: string }; input: IUpdateOrganizationInput }
  > => gql`
    mutation UpdateOrganization(
      $where: OrganizationWhereUniqueInput!
      $input: OrganizationUncheckedUpdateInput!
    ) {
      updateOrganization: UpdateOrganization(where: $where, input: $input) {
        organizationId: id
        documentNumberCnpj: document_number_cnpj
        tradeName: trade_name
        companyName: company_name
        contacts {
          id
          email
          phoneNumber: phone_number
        }
        addresses {
          id
          zipCode: zip_code
          stateShortName: state_short_name
          cityName: city_name
          district
          street
          streetNumber: street_number
          complement
          pickUpLocation: pick_up_location
          defaultAddress: default_address
        }
      }
    }
  `,

  updateBranchOrganization: (): TypedDocumentNode<
    { updateBranchOrganization: IBranchCompanyUpdate },
    { where: { id: string }; input: IUpdateBranchCompanyInput }
  > => gql`
    mutation UpdateOrganization(
      $where: OrganizationWhereUniqueInput!
      $input: OrganizationUncheckedUpdateInput!
    ) {
      updateBranchOrganization: UpdateOrganization(
        where: $where
        input: $input
      ) {
        organizationId: id
        documentNumberCnpj: document_number_cnpj
        tradeName: trade_name
        companyName: company_name
        addresses {
          id
          zipCode: zip_code
          stateShortName: state_short_name
          cityName: city_name
          district
          street
          streetNumber: street_number
        }
      }
    }
  `,

  myBranches: (): TypedDocumentNode<
    { myBranches: { branchCompanies: IDefaultBranchCompanies[] } },
    { where: { id: string } }
  > => gql`
    query findBranches($where: OrganizationWhereUniqueInput!) {
      myBranches: FindOrganization(where: $where) {
        branchCompanies: branch_companies {
          id
          documentNumberCnpj: document_number_cnpj
          tradeName: trade_name
          companyName: company_name
          deletedAt: deleted_at
          addresses {
            id
            zipCode: zip_code
            stateShortName: state_short_name
            cityName: city_name
            district
            street
            streetNumber: street_number
            defaultAddress: default_address
          }
        }
      }
    }
  `,

  deleteBranchCompany: (): TypedDocumentNode<{
    status: boolean;
    where: { id: string };
  }> => gql`
    mutation DeleteBranchOrganization($where: DeleteOrganizationWhereInput!) {
      status: DeleteBranchOrganization(where: $where) {
        success
      }
    }
  `,

  newBranchCompany: (): TypedDocumentNode<
    { registerBranchOrganization: INewBranchCompany },
    { input: INewBranchCompanyInput }
  > => gql`
    mutation RegisterBranchOrganization(
      $input: BranchOrganizationRegistrationInput!
    ) {
      registerBranchOrganization: RegisterBranchOrganization(input: $input) {
        parentId: parent_id
        documentNumberCnpj: document_number_cnpj
        tradeName: trade_name
        companyName: company_name

        addresses {
          title
          defaultAddress: default_address
          countryName: country_name
          stateName: state_name
          stateShortName: state_short_name
          cityName: city_name
          district
          street
          streetNumber: street_number
          zipCode: zip_code
        }
      }
    }
  `,

  getOrganizationApiKey: (): TypedDocumentNode<{
    where: { id: string };
    getOrganizationApiKey: IGetOrganizationApiKey;
  }> => gql`
    query getOrganizationApiKey($where: OrganizationWhereUniqueInput!) {
      getOrganizationApiKey: GetOrganizationApiKey(where: $where) {
        apiKey: api_key
      }
    }
  `,
};

export default queries;
