import { CheckCircleTwoTone, ClockCircleTwoTone } from '@ant-design/icons';
import { Col, Row } from 'antd';
import moment, { Moment } from 'moment';
import { ReactElement } from 'react';
import { ITruckLoadCadences } from '../../../../../structures/interfaces/TruckLoad';

const iconsLayout = {
  md: 8,
  lg: 6,
  xl: 3,
  xxl: 3,
};
const labelsLayout = {
  span: 0,
  xl: 13,
  xxl: 10,
  style: { fontSize: 13 },
};
const valuesLayout = {
  span: 8,
  xxl: 11,
};
const DefaultAntdCell = ({
  date,
  cadence,
}: {
  date: Moment;
  cadence?: ITruckLoadCadences;
}): ReactElement => {
  const classNames = ['ant-picker-cell-inner', 'ant-picker-calendar-date'];
  if (date.isSame(moment(), 'day')) {
    classNames.push('ant-picker-calendar-date-today');
  }

  const scheduleContent = cadence ? (
    <div>
      <Row>
        <Col {...iconsLayout}>
          <CheckCircleTwoTone twoToneColor="#006AEC" />
        </Col>
        <Col {...labelsLayout}>Disponível</Col>
        <Col {...valuesLayout}>
          <strong>{`${cadence.availableUnits} bags`}</strong>
        </Col>
      </Row>

      <Row>
        <Col {...iconsLayout}>
          <ClockCircleTwoTone twoToneColor="#52c41a" />
        </Col>
        <Col {...labelsLayout}>Início</Col>
        <Col {...valuesLayout} style={{ color: '#999' }}>
          {moment(cadence.startTime).format('HH:mm')}
        </Col>
      </Row>

      <Row>
        <Col {...iconsLayout}>
          <ClockCircleTwoTone twoToneColor="#eb2f41" />
        </Col>
        <Col {...labelsLayout}>Fim</Col>
        <Col {...valuesLayout} style={{ color: '#999' }}>
          {moment(cadence.endTime).format('HH:mm')}
        </Col>
      </Row>
    </div>
  ) : (
    <></>
  );

  return (
    <div className={classNames.join(' ')} style={{ marginBottom: 8 }}>
      <div className={`ant-picker-calendar-date-value`}>
        {date.format('DD')}
      </div>
      <div className={`ant-picker-calendar-date-content`}>
        {scheduleContent}
      </div>
    </div>
  );
};

export default DefaultAntdCell;
