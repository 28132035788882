import { rejects } from 'assert';
import client from '../../api/urql/customClient';
import Formatter from '../../classes/Formatter';
import {
  CustomerRuleInput,
  ICreateCadencesInput,
  ICreateCustomerRule,
  ICreateCustomerRuleResponse,
  ICreateRule,
  IDeleteRuleResponse,
  IEditCustomerRule,
  IEditRuleForm,
  IFillCalendar,
  IFindCadences,
  IFindRules,
  IGetPickUpLocations,
  IGetRules,
  IListRules,
  IGetSeedTypes,
} from '../interfaces/ScheduleRules';
import { ISettings } from '../interfaces/Settings';
import ScheduleRulesQueries from '../queries/ScheduleRules';

const buildSlug = (rules: IGetRules[]) => {
  return rules?.map(rule => ({
    ...rule,
    slug: Formatter.removeAccents(
      `${rule.pickUpLocation.title} ${rule.seedType.name} ${rule.startDate} ${rule.endDate} ${rule.releaseSubmissionDate} ${rule.schedulingStart} ${rule.capacityPerCompany} ${rule.whiteSeedSchedulingTime} ${rule.tsiSeedSchedulingTime} ${rule.id}`.toLowerCase()
    ),
  }));
};

const filterRuleByCulture = (rules: IGetRules[], seedName?: string) => {
  return seedName != undefined
    ? rules.filter(rule => rule.seedTypeId === seedName)
    : rules;
};

const filterRulePickUpLocation = (
  rules: IGetRules[],
  pickUpLocation?: string
) =>
  pickUpLocation != undefined
    ? rules.filter(rule => rule.pickUpLocation.id === pickUpLocation)
    : rules;

class ScheduleRulesController {
  static getCulture = (): Promise<IGetSeedTypes[]> =>
    new Promise((resolve, reject) =>
      client
        .query(ScheduleRulesQueries.getCultures())
        .then(({ getSeedTypes }) => {
          resolve(getSeedTypes);
        })
        .catch(err => reject(err))
    );

  static getPickUpLocations = ({
    seedTypeId,
    organizationId,
  }: {
    seedTypeId?: string;
    organizationId?: string;
  }): Promise<IGetPickUpLocations[]> =>
    new Promise((resolve, reject) =>
      client
        .query(ScheduleRulesQueries.getPickUpLocationsBySeedTypes(), {
          where: {
            name_without_accent: seedTypeId,
            organization_id: organizationId
              ? { equals: organizationId }
              : undefined,
          },
        })
        .then(({ getLocales }) => {
          resolve(getLocales);
        })
        .catch(err => reject(err))
    );

  static createRule = ({
    createRule,
  }: {
    createRule: ICreateRule;
  }): Promise<ICreateRule[]> =>
    new Promise((resolve, reject) =>
      client
        .mutation(ScheduleRulesQueries.createRule(), {
          input: {
            unity: createRule.unity,
            end_date: createRule.endDate,
            start_date: createRule.startDate,
            seed_type_id: createRule.seedTypeId,
            create_cadences: createRule.createCadences,
            scheduling_start: createRule.schedulingStart,
            load_report_timeout: createRule.loadReportTimeout,
            pick_up_location_ids: createRule.pickUpLocationIds,
            capacity_per_company: createRule.capacityPerCompany,
            driver_report_timeout: createRule.driverReportTimeout,
            release_submission_date: createRule.releaseSubmissionDate,
            tsi_seed_scheduling_time: createRule.tsiSeedSchedulingTime,
            white_seed_scheduling_time: createRule.whiteSeedSchedulingTime,
            general_capacity_per_day: createRule.generalCapacityPerDay,
            general_saturday_capacity: createRule.generalSaturdayCapacity,
            general_sunday_capacity: createRule.generalSundayCapacity,
          },
        })
        .then(({ createRule }) => {
          resolve(createRule);
        })
        .catch(err => reject(err))
    );

  static createCadence = ({
    ruleId,
    cadences,
  }: {
    ruleId: string;
    cadences: IFillCalendar[];
  }): Promise<{ success: boolean }> => {
    const cadenceArray = cadences
      .map(({ capacityPerDay, endTime, startTime }) => {
        if (capacityPerDay && endTime && startTime) {
          return {
            available_units: Number(capacityPerDay),
            end_time: endTime.toISOString(),
            start_time: startTime.toISOString(),
            capacity_per_day: Number(capacityPerDay),
            rule_id: ruleId,
          };
        }
      })
      .filter(cad => cad) as ICreateCadencesInput[];

    return new Promise((resolve, reject) =>
      client
        .mutation(ScheduleRulesQueries.createCadences(), {
          input: {
            cadences: cadenceArray,
          },
        })
        .then(({ createCadences }) => {
          resolve(createCadences);
        })
        .catch(reject)
    );
  };

  static createCustomerRule = ({
    customerRules,
    referenceRuleId,
  }: {
    customerRules: ICreateCustomerRule[];
    referenceRuleId: string;
  }): Promise<PromiseSettledResult<ICreateCustomerRuleResponse>[]> => {
    const responses = customerRules.map(
      ({ customerId, startDate, endDate, capacity, timezone }) =>
        new Promise<ICreateCustomerRuleResponse>((resolve, reject) =>
          client
            .mutation(ScheduleRulesQueries.createCustomerRules(), {
              input: {
                customer_id: customerId,
                reference_rule_id: referenceRuleId,
                start_date: startDate,
                end_date: endDate,
                capacity: capacity,
                timezone: timezone,
              },
            })
            .then(({ customerRule }) => {
              resolve(customerRule);
            })
            .catch(reject)
        )
    );
    return Promise.allSettled(responses);
  };

  static deleteCustomerRule = ({
    customerRuleId,
  }: {
    customerRuleId: string;
  }): Promise<{ success: boolean }> => {
    return new Promise((resolve, reject) =>
      client
        .mutation(ScheduleRulesQueries.deleteCustomerRules(), {
          where: {
            id: customerRuleId,
          },
        })
        .then(({ deleteCustomerRule }) => {
          resolve(deleteCustomerRule);
        })
        .catch(reject)
    );
  };

  static getRules = ({ orgId }: { orgId: string }): Promise<IListRules[]> =>
    new Promise((resolve, reject) =>
      client
        .query(ScheduleRulesQueries.getRules(), {
          where: { organization_id: { equals: orgId } },
        })
        .then(({ getRules }) => {
          resolve(getRules);
        })
        .catch(err => reject(err))
    );

  static editRules = ({
    ruleId,
    rulesInput,
  }: {
    ruleId: string;
    rulesInput: IEditRuleForm;
  }): Promise<IGetRules[]> =>
    new Promise(resolve =>
      client
        .mutation(ScheduleRulesQueries.editRules(), {
          where: {
            id: ruleId,
          },
          input: {
            seed_type_id: rulesInput.seedTypeId,
            pick_up_location_id: rulesInput.pickUpLocationId,
            release_submission_date: rulesInput.releaseSubmissionDate,
            scheduling_start: rulesInput.schedulingStart,
            unity: rulesInput.unity,
            unity_weight: rulesInput.unityWeight,
            load_report_timeout: rulesInput.loadReportTimeout,
            driver_report_timeout: rulesInput.driverReportTimeout,
            white_seed_scheduling_time: rulesInput.whiteSeedSchedulingTime,
            tsi_seed_scheduling_time: rulesInput.tsiSeedSchedulingTime,
            capacity_per_company: rulesInput.capacityPerCompany,
            start_date: rulesInput.startDate,
            end_date: rulesInput.endDate,
            general_capacity_per_day: rulesInput.generalCapacityPerDay,
            general_sunday_capacity: rulesInput.generalSundayCapacity,
            general_saturday_capacity: rulesInput.generalSaturdayCapacity,
            update_cadences: rulesInput.updateCadences.map(cadence => ({
              where: {
                id: cadence.where.id,
              },
              input: {
                capacity_per_day: cadence.input.capacityPerDay,
                available_units: cadence.input.availableUnits,
                start_time: cadence.input.startTime,
                end_time: cadence.input.endTime,
              },
            })),
          },
        })
        .then(({ editRules }) => {
          resolve(editRules);
        })
        .catch(rejects)
    );

  static findCadences = ({
    ruleId,
  }: {
    ruleId: string;
  }): Promise<IFindCadences> =>
    new Promise((resolve, reject) =>
      client
        .query(ScheduleRulesQueries.findCadencesInRules(), {
          where: {
            id: ruleId,
          },
        })
        .then(({ findCadences }) => {
          resolve(findCadences);
        })
        .catch(err => reject(err))
    );

  static findRule = ({ ruleId }: { ruleId: string }): Promise<IFindRules> =>
    new Promise((resolve, reject) =>
      client
        .query(ScheduleRulesQueries.findRule(), {
          where: {
            id: ruleId,
          },
        })
        .then(({ findRule: findRules }) => {
          resolve(findRules);
        })
        .catch(err => reject(err))
    );

  static filterRules = (
    getRules: IGetRules[],
    {
      search = '',
      seedName,
      pickUpLocation,
    }: {
      search?: string;
      seedName?: string;
      pickUpLocation?: string;
    }
  ): IGetRules[] => {
    return filterRuleByCulture(
      filterRulePickUpLocation(
        buildSlug(getRules).filter(rule =>
          rule.slug.includes(
            Formatter.removeAccents(search.toLowerCase().trim())
          )
        ),
        pickUpLocation
      ),
      seedName
    );
  };

  static deleteScheduleRule = ({
    id,
  }: {
    id: string;
  }): Promise<IDeleteRuleResponse> =>
    new Promise((resolve, reject) =>
      client
        .mutation(ScheduleRulesQueries.deleteScheduleRule(), {
          where: {
            id,
          },
        })
        .then(({ success }) => {
          resolve(success);
        })
        .catch(err => {
          reject(err);
        })
    );

  static editCustomerRule = ({
    id,
    input,
  }: {
    id: string;
    input: CustomerRuleInput;
  }): Promise<IEditCustomerRule> =>
    new Promise((resolve, reject) =>
      client
        .mutation(ScheduleRulesQueries.editCustomerRule(), {
          where: {
            id,
          },
          input: {
            start_date: input.startDate,
            end_date: input.endDate,
            capacity: input.capacity,
          },
        })
        .then(({ editCustomerRule }) => {
          resolve(editCustomerRule);
        })
        .catch(err => {
          reject(err);
        })
    );

  static deleteCadence = ({
    cadence_ids,
  }: {
    cadence_ids: string[];
  }): Promise<{ success: boolean }> =>
    new Promise((resolve, reject) =>
      client
        .mutation(ScheduleRulesQueries.deleteCadence(), {
          where: {
            cadence_ids,
          },
        })
        .then(({ deleteCadence }) => {
          resolve(deleteCadence);
        })
        .catch(err => {
          reject(err);
        })
    );

  // Settings

  static getSettings = ({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<ISettings> =>
    new Promise((resolve, reject) =>
      client
        .query(ScheduleRulesQueries.getSettings(), {
          where: {
            organization_id: organizationId,
          },
        })
        .then(({ getSettings }) => {
          resolve(getSettings);
        })
        .catch(err => {
          reject(err);
        })
    );

  static updateSettings = ({
    organizationId,
    tolerancePercentage,
    commonTsiSeedSchedulingTime,
    commonWhiteSeedSchedulingTime,
  }: {
    organizationId: string;
    tolerancePercentage?: number | null;
    commonTsiSeedSchedulingTime?: number;
    commonWhiteSeedSchedulingTime?: number;
  }): Promise<ISettings> =>
    new Promise((resolve, reject) =>
      client
        .mutation(ScheduleRulesQueries.updateSettings(), {
          where: {
            organization_id: organizationId,
          },
          input: {
            tolerance_percentage: tolerancePercentage,
            common_tsi_seed_scheduling_time: commonTsiSeedSchedulingTime,
            common_white_seed_scheduling_time: commonWhiteSeedSchedulingTime,
          },
        })
        .then(({ updateSettings }) => {
          resolve(updateSettings);
        })
        .catch(err => {
          reject(err);
        })
    );

  static countRuleTruckLoads = ({
    ruleId,
  }: {
    ruleId: string;
  }): Promise<number> =>
    new Promise((resolve, reject) =>
      client
        .query(ScheduleRulesQueries.countRuleTruckLoads(), {
          where: {
            rule_id: ruleId,
          },
        })
        .then(({ countRule }) => {
          resolve(countRule);
        })
        .catch(err => {
          reject(err);
        })
    );
}

export default ScheduleRulesController;
