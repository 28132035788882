import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Row, Space } from 'antd';
import moment from 'moment';
import { ReactElement, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../../../../../contexts/user';
import { useRequest } from '../../../../../hooks/useRequest';
import TruckLoadsController from '../../../../../structures/controllers/TruckLoad';
import {
  IStepOneData,
  ITruckLoadCadences,
} from '../../../../../structures/interfaces/TruckLoad';
import TruckLoadFooter from '../../../components/TruckLoadFooter';
import BasicData, { IBasicDataFormValues } from './StepOne.basicData';
import TruckLoadsCalendar from './StepOne.calendar';

interface IStepOneProps {
  nextStep: () => void;
  setStepOneData: (values: IStepOneData) => void;
}

const StepOne = ({ nextStep, setStepOneData }: IStepOneProps): ReactElement => {
  const { userData, customerData } = useContext(UserContext);

  const [form] = Form.useForm<IBasicDataFormValues>();
  const history = useHistory();

  const [createPreSchedule, isCreatingPreSchedule] = useRequest(
    TruckLoadsController.createPreSchedule
  );

  const [cadences, setCadences] = useState<ITruckLoadCadences[]>([]);
  const [selectedCadence, setSelectedCadence] = useState<ITruckLoadCadences>();

  const onFinish = (value: IBasicDataFormValues) => {
    const preScheduleAuthor = customerData ? customerData.id : userData?.id;
    const orgId = userData?.organization.id || customerData?.organizationId;
    const { schedulingType } = form.getFieldsValue();

    let cifCadence: string | undefined = undefined;
    let fobCadence: string | undefined = undefined;

    if (userData && schedulingType === 'cif') cifCadence = selectedCadence?.id;
    else if (userData && schedulingType === 'fob')
      fobCadence = selectedCadence?.id;

    if (customerData) fobCadence = selectedCadence?.id;

    if (preScheduleAuthor) {
      createPreSchedule({
        data: {
          // Will make sure that backend will always receice undefined fields in
          // case both cif and fob cadences data are defined;
          cif_cadence_id: fobCadence ? undefined : cifCadence,
          fob_cadence_id: cifCadence ? undefined : fobCadence,
          created_by: preScheduleAuthor,
          pick_up_location_id: value.pickUpLocation,
          vehicle_id: value.vehicle,
        },
      })
        .then(res => {
          const aux = {
            cadence: selectedCadence,
            loadName: value.loadName,
            customerCapacity: 0, // tem que fazer uma request somente para esse campo, por isso será temporariamente removido!
            organizationId: orgId,
            pickupLocation: value.pickUpLocation,
            preScheduleId: res.id,
            seedType: value.seedType,
            selectedDate: moment(selectedCadence?.startTime),
            unity: value.unity,
            cancel: res,
            vehicle: {
              id: res.vehicle.id,
              bagsCapacity: res.vehicle.bagsCapacity,
              vehicleModel: res.vehicle.vehicleModel,
            },
          } as IStepOneData;
          setStepOneData(aux);
          nextStep();
        })
        .catch(() => null);
    }
  };

  return (
    <>
      <Row gutter={[24, 24]} style={{ marginBottom: 88 }}>
        <Col span={24}>
          <Card title="Informe os dados básicos da carga">
            <BasicData
              form={form}
              onFinish={onFinish}
              setCadences={setCadences}
            />
          </Card>
        </Col>

        <Col span={24}>
          <Card title="Selecione o dia para agendamento">
            <TruckLoadsCalendar
              cadences={cadences}
              setSelectedCadence={setSelectedCadence}
            />
          </Card>
        </Col>
      </Row>

      <TruckLoadFooter>
        <Space style={{ margin: '24px 0' }}>
          <Button
            danger
            onClick={() => history.push('/cargas')}
            icon={<CloseOutlined />}
          >
            Cancelar
          </Button>
          <Button
            type="primary"
            onClick={() => form.submit()}
            icon={<ArrowRightOutlined />}
            loading={isCreatingPreSchedule}
            disabled={!selectedCadence}
          >
            Próximo
          </Button>
        </Space>
      </TruckLoadFooter>
    </>
  );
};

export default StepOne;
