import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space } from 'antd';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { IGetRules } from '../../structures/interfaces/ScheduleRules';
import { FilterByCulture, FilterByLocation } from './Rules.utils';

interface IRulesFilterFormValues {
  search: string;
  culture: string;
  pickUpLocation: string;
}
interface IRulesFilterProps {
  rules: IGetRules[];
  setFilteredRules: Dispatch<SetStateAction<IGetRules[]>>;
}
const RulesFilter = ({
  rules,
  setFilteredRules,
}: IRulesFilterProps): ReactElement => {
  const [form] = Form.useForm<IRulesFilterFormValues>();

  const cultureOptions = rules.map(item => ({
    value: item.seedTypeId,
    label: item.seedType.name,
  }));

  const pickUpLocationOptions = rules.map(item => ({
    value: item.pickUpLocation.id,
    label: item.pickUpLocation.title,
  }));

  const onFinish = ({
    culture,
    pickUpLocation,
    search = '',
  }: IRulesFilterFormValues) => {
    const value = search.toLocaleLowerCase().trim();
    const filteredItems = rules.filter(rule => rule.slug?.includes(value));
    const filterByCulture = FilterByCulture(filteredItems, culture);
    const filterByLocation = FilterByLocation(filterByCulture, pickUpLocation);

    setFilteredRules(filterByLocation);
  };

  return (
    <Form layout="vertical" size="small" form={form} onFinish={onFinish}>
      <Row gutter={[24, 0]}>
        <Col span={6}>
          <Form.Item label="Pesquisa" name={'search'}>
            <Input.Search
              enterButton
              placeholder="Pesquise itens na tabela"
              onSearch={form.submit}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Cultura" name={'culture'}>
            <Select
              onChange={form.submit}
              placeholder="Selecione"
              allowClear
              options={cultureOptions}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Local de embarque" name={'pickUpLocation'}>
            <Select
              onChange={form.submit}
              placeholder="Selecione"
              allowClear
              options={pickUpLocationOptions}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="Período da regra" name={'rulePeriod'}>
            <DatePicker.RangePicker
              onChange={form.submit}
              style={{ width: '100%' }}
              allowClear
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Space>
            <Button icon={<ClearOutlined />} onClick={() => form.resetFields()}>
              Limpar filtro
            </Button>
            <Button
              icon={<FilterOutlined />}
              type="primary"
              onClick={form.submit}
            >
              Filtrar
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default RulesFilter;
