import { Card, Col, Form, FormInstance, Row, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { ReactElement, useContext, useEffect, useState } from 'react';
import UserContext from '../../../contexts/user';
import { useRequest } from '../../../hooks/useRequest';
import RulesController from '../../../structures/controllers/Rules';
import { fieldsSize } from '../RulesForm.utils';
import { ISchedulingConfig } from '../../../structures/interfaces/Rules';
import { useRuleContext } from '../../../contexts/rule/RuleContext.hooks';

interface IBasicInformationFormProps {
  isLoading: boolean;
  form: FormInstance<ISchedulingConfig>;
}

const BasicInformationForm = ({
  isLoading,
  form,
}: IBasicInformationFormProps): ReactElement => {
  const { userData } = useContext(UserContext);
  const { operation } = useRuleContext();

  const [locations, setLocations] = useState<DefaultOptionType[]>([]);
  const [seedTypes, setSeedTypes] = useState<DefaultOptionType[]>([]);

  const [fetchRequirements] = useRequest(RulesController.fetchRuleRequirements);

  const disableFields = operation === 'update';

  useEffect(() => {
    const orgId = userData?.organization.id;
    if (orgId) {
      fetchRequirements({ orgId })
        .then(res => {
          if (res.locations?.length === 1) {
            form?.setFieldsValue({
              pickUpLocationIds: [res.locations[0].id],
            });
          }
          const pickUpLocations = res.locations?.map(locale => ({
            value: locale.id,
            label: locale.title,
          }));
          const seedTypes = res.seedTypes?.map(seedType => ({
            value: seedType.nameWithoutAccent,
            label: seedType.nameWithoutAccent,
          }));
          setLocations(pickUpLocations);
          setSeedTypes(seedTypes);
        })
        .catch();
    }
  }, []);

  return (
    <Card title="Informações básicas" loading={isLoading}>
      <Row gutter={24}>
        <Col {...fieldsSize}>
          <Form.Item name={'seedTypeId'} label={'Cultura'} required>
            <Select
              allowClear
              placeholder={'Selecione'}
              loading={isLoading}
              options={seedTypes}
              disabled={disableFields}
            />
          </Form.Item>
        </Col>

        <Col {...fieldsSize}>
          <Form.Item
            name={'pickUpLocationIds'}
            label={'Local de embarque'}
            required
          >
            <Select
              allowClear
              placeholder={'Selecione'}
              mode="multiple"
              loading={isLoading}
              options={locations}
              disabled={disableFields}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default BasicInformationForm;
