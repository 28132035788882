import {
  CheckOutlined,
  ClockCircleTwoTone,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  PlusOutlined,
  TableOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  CardProps,
  Col,
  Dropdown,
  List,
  MenuProps,
  message,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { History } from 'history'; // Tipo History
import moment from 'moment';
import { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import UserContext from '../../contexts/user';
import { IUseRequestAction, useRequest } from '../../hooks/useRequest';
import TruckLoadController from '../../structures/controllers/TruckLoad';
import { IGetCustomer } from '../../structures/interfaces/Clients';
import { IGetTruckLoadsResponse } from '../../structures/interfaces/TruckLoad';
import { IGetLoginDataUser } from '../../structures/interfaces/User';

interface ITruckLoadsTableProps {
  cardProps?: CardProps;
  tableProps?: TableProps<IGetTruckLoadsResponse>;
  truckLoadStatus?: DefaultOptionType[];
  customerData?: IGetCustomer | null;
  userData?: IGetLoginDataUser | null;
}

const renderContent = (text?: string) => {
  if (text) return text;
  else return '---';
};

const RenderTableActions = (
  truckLoad: IGetTruckLoadsResponse,
  history: History,
  userData?: IGetLoginDataUser | null,
  filterByUpdate?: any[],
  filterByDelete?: any[],
  customerData?: IGetCustomer | null,
  cancelTruckLoad?: IUseRequestAction<{ loadId: string }, { success: boolean }>
) => {
  const updateTruckLoadPermission = filterByUpdate?.find(
    permission => permission?.userPermissions.module === 'CARGAS'
  );
  const deleteTruckLoadPermission = filterByDelete?.find(
    permission => permission?.userPermissions.module === 'CARGAS'
  );

  const items: MenuProps['items'] = [
    {
      key: 'view',
      label: 'Visualizar',
      icon: <EyeOutlined />,
      onClick: () =>
        history.push({
          pathname: `/cargas/${truckLoad.id}`,
          state: {
            truckLoadId: truckLoad.id,
            status: truckLoad.status,
          },
        }),
    },
  ];

  if (updateTruckLoadPermission || customerData) {
    if (
      truckLoad.status !== 'AUTHORIZED_LOAD' &&
      truckLoad.status !== 'LOADED' &&
      truckLoad.status !== 'EXPIRED' &&
      truckLoad.status !== 'CANCELED'
    ) {
      if (!truckLoad.driver) {
        items.push({
          key: 'setDriver',
          label: 'Informe o motorista',
          icon: <EditOutlined />,
          onClick: () =>
            history.push({
              pathname: `/cargas/${truckLoad.id}`,
              state: {
                truckLoadId: truckLoad.id,
                status: truckLoad.status,
              },
            }),
        });
      }
      items.push({
        key: 'edit',
        label: 'Editar',
        icon: <EditOutlined />,
        onClick: () =>
          history.push({
            pathname: `/cargas/${truckLoad.id}`,
            state: {
              truckLoadId: truckLoad.id,
              status: truckLoad.status,
            },
          }),
      });
    }
  }
  if (userData) {
    if (truckLoad.status === 'WAITING_RELEASE') {
      items.push({
        key: 'auth',
        label: 'Autorizar',
        icon: <CheckOutlined />,
        onClick: () =>
          history.push({
            pathname: `/cargas/${truckLoad.id}`,
            state: {
              truckLoadId: truckLoad.id,
              status: truckLoad.status,
            },
          }),
      });
    }
    if (truckLoad.status === 'AUTHORIZED_LOAD') {
      items.push({
        key: 'loadTruck',
        label: 'Carregar',
        icon: <TableOutlined />,
        onClick: () =>
          history.push({
            pathname: `/cargas/${truckLoad.id}`,
            state: {
              truckLoadId: truckLoad.id,
              status: truckLoad.status,
            },
          }),
      });
    }
  }
  if (
    deleteTruckLoadPermission &&
    truckLoad.status !== 'LOADED' &&
    truckLoad.status !== 'EXPIRED' &&
    truckLoad.status !== 'CANCELED' &&
    truckLoad.status !== 'AUTHORIZED_LOAD'
  ) {
    items.push({
      key: 'cancel',
      label: 'Cancelar agendamento',
      danger: true,
      icon: <CloseOutlined />,
      onClick: () =>
        cancelTruckLoad?.({ loadId: truckLoad.id })
          .then(() => message.success('Agendamento cancelado com sucesso!'))
          .catch(() =>
            message.error('Não foi possível cancelar o agendamento')
          ),
    });
  }

  return (
    <Dropdown menu={{ items }} placement="top" trigger={['click']}>
      <EllipsisOutlined style={{ fontSize: 24 }} />
    </Dropdown>
  );
};

const rowExpandable = (record: IGetTruckLoadsResponse) => {
  const uniqueItems = record.truckLoadItems.filter(
    (item, index, self) =>
      index ===
      self.findIndex(t => {
        return (
          t.contractItem.contract.farm.name ===
          item.contractItem.contract.farm.name
        );
      })
  );
  return uniqueItems.length > 1 ? true : false;
};
const expandedRowRender = (record: IGetTruckLoadsResponse) => {
  const uniqueItems = record.truckLoadItems.filter(
    (item, index, self) =>
      index ===
      self.findIndex(t => {
        return (
          t.contractItem.contract.farm.name ===
          item.contractItem.contract.farm.name
        );
      })
  );
  const data = uniqueItems.map(tli => ({
    title: tli.contractItem.contract.farm.name,
  }));
  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta avatar={<UserOutlined />} title={item.title} />
        </List.Item>
      )}
    />
  );
};
const TruckLoadsTable = ({
  cardProps,
  tableProps,
  customerData,
  userData,
}: ITruckLoadsTableProps): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const { filterByUpdate, filterByDelete } = useContext(UserContext);
  const [pdfRequest] = useRequest(TruckLoadController.downloadLoadingOrder);
  const [cancelTruckLoad] = useRequest(TruckLoadController.cancelTruckLoad);
  const [loadsToDownload, setLoadsToDownload] = useState<string[]>([]);

  const pdfDownload = (truckLoadId: string) => {
    setLoadsToDownload(downloadingTruckLoadIds => [
      ...downloadingTruckLoadIds,
      truckLoadId,
    ]);

    const download = (fileUrl: string) => {
      const browserUrl = window.location.href.split('/');
      if (`/${browserUrl.pop()}` === '/cargas') {
        const a = document.createElement('a');
        a.href = fileUrl;
        a.download = `${fileUrl}`;
        a.click();
      }
    };

    pdfRequest({ loadId: truckLoadId })
      .then(value => download(value.url))
      .catch(error =>
        message.error({
          content: t(error.message),
          style: { marginTop: '4rem' },
        })
      )
      .finally(() =>
        setLoadsToDownload(downloadingTruckLoadIds =>
          downloadingTruckLoadIds.filter(
            downloadingTruckLoadId => downloadingTruckLoadId !== truckLoadId
          )
        )
      );
  };

  const formLoadButton = (
    <Button
      children="Formar carga"
      type="primary"
      icon={<PlusOutlined />}
      onClick={() => history.push('/cargas/formar-carga')}
    />
  );

  const columns: ColumnsType<IGetTruckLoadsResponse> = [
    {
      title: 'Cliente',
      dataIndex: ['farmName'],
      key: 'name',
      render: (_, record) => {
        const uniqueTruckLoadItems = record.truckLoadItems.filter(
          (item, index, self) =>
            index ===
            self.findIndex(t => {
              return (
                t.contractItem.contract.farm.name ===
                item.contractItem.contract.farm.name
              );
            })
        );
        if (uniqueTruckLoadItems.length === 1) {
          return uniqueTruckLoadItems[0].contractItem.contract.farm.name;
        } else {
          return `${uniqueTruckLoadItems.length} Clientes`;
        }
      },
    },
    {
      title: 'O.C',
      dataIndex: ['loadingOrder', 'loadingOrder'],
      key: 'loadingOrder',
      render: (text, record) => {
        return record ? (
          <a
            style={{ marginLeft: 13 }}
            onClick={() => pdfDownload(record.id)}
            download
          >
            {text && record.loadingOrder ? (
              <Tooltip title={t('pages.truckLoads.downloadLOTooltip')}>
                <Button
                  type="primary"
                  shape="round"
                  ghost
                  loading={loadsToDownload.includes(record.id)}
                  icon={<DownloadOutlined />}
                >
                  {text}
                </Button>
              </Tooltip>
            ) : (
              <Button type="text" shape="round" disabled>
                ---
              </Button>
            )}
          </a>
        ) : null;
      },
    },
    {
      title: 'Nome da carga',
      dataIndex: ['name'],
      key: 'loadName',
      render: renderContent,
    },
    {
      title: 'Data agendada',
      dataIndex: ['cadence', 'startTime'],
      key: 'scheduleDate',
      render: (_, record) => {
        const cadence =
          record.cif_cadence || record.fob_cadence || record.farm_cadence;
        return moment(cadence.startTime).format('DD/MM/YYYY');
      },
    },
    {
      title: 'Veículo',
      dataIndex: ['vehicle', 'vehicleModel'],
      key: 'vehicle',
      render: renderContent,
    },
    {
      title: 'Peso estimado',
      dataIndex: ['estimatedWeight'],
      key: 'estimatedWeight',
      render: renderContent,
    },
    {
      title: 'Cargas carregadas',
      dataIndex: ['loadedAmount'],
      key: 'loadedLoads',
      render: renderContent,
    },
    {
      title: 'Motorista',
      dataIndex: ['driver', 'name'],
      key: 'driver',
      render: (driver, data) => {
        if (driver) return driver;
        if (!driver && data.status === 'CANCELED') return '---';
        if (!driver && data.status === 'EXPIRED') {
          const timeExpired = 'pages.truckLoads.expiredDriver';
          return (
            <Row gutter={6} style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Col>
                <Typography.Text type="secondary">
                  {t(`${timeExpired}.time`)}
                </Typography.Text>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography.Text type="secondary">
                  {t(`${timeExpired}.expired`)}
                </Typography.Text>
                &nbsp;&nbsp;
                <ClockCircleTwoTone twoToneColor="#C8C8C8" />
              </Col>
            </Row>
          );
        }
        if (data.status === 'WAITING_ACTIONS') return '---';
        return `${t('pages.truckLoads.setDriverUntil')} ${moment(
          data.expiresAt
        ).format('DD/MM/YYYY')} às ${moment(data.expiresAt).format('HH:mm')}`;
      },
    },
    {
      title: 'Status',
      dataIndex: ['status'],
      key: 'status',
      render: (_, record) => {
        switch (record.status) {
          case 'WAITING_DRIVER':
            return (
              <Tag color="orange">{t('pages.truckLoads.waitingDriver')}</Tag>
            );

          case 'WAITING_RELEASE':
            return (
              <Tag color="purple">{t('pages.truckLoads.waitingComercial')}</Tag>
            );

          case 'LOADED':
            return <Tag color="green">{t('pages.truckLoads.loaded')}</Tag>;

          case 'AUTHORIZED_LOAD':
            return <Tag color="blue">{t('pages.truckLoads.loadReleased')}</Tag>;

          case 'CANCELED':
            return <Tag color="red">{t('pages.truckLoads.canceled')}</Tag>;

          case 'EXPIRED':
            return <Tag color="default">{t('pages.truckLoads.expired')}</Tag>;

          case 'LOADING':
            return <Tag color="magenta">{t('pages.truckLoads.loading')}</Tag>;

          case 'WAITING_ACTIONS':
            return (
              <Tag color="purple">{t('pages.truckLoads.waitingComercial')}</Tag>
            );
        }
      },
    },
    {
      title: 'Ações',
      dataIndex: ['actions'],
      key: 'actions',
      render: (_, record) =>
        RenderTableActions(
          record,
          history,
          userData,
          filterByUpdate,
          filterByDelete,
          customerData,
          cancelTruckLoad
        ),
    },
  ];

  const filteredColumns = columns.filter(col => col.key !== 'estimatedWeight');

  return (
    <Card title="Cargas cadastradas" extra={formLoadButton} {...cardProps}>
      <Table
        size="small"
        rowKey={load => load.id}
        columns={filteredColumns}
        expandable={{ expandedRowRender, rowExpandable }}
        {...tableProps}
      />
    </Card>
  );
};

export default TruckLoadsTable;
