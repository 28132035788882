import { EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { IFetchContracts } from '../../structures/interfaces/Contract';

interface IHomeTableProps {
  data: IFetchContracts[];
  isLoading: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedContract: Dispatch<SetStateAction<IFetchContracts | undefined>>;
}
const HomeTable = ({
  data,
  isLoading,
  setSelectedContract,
  setOpen,
}: IHomeTableProps): ReactElement => {
  const { t } = useTranslation();

  const statusMessage = (
    <div>
      {t('pages.home.promptText')}
      <p style={{ paddingTop: 24 }}>{t('pages.home.waitingSchedule')}</p>
      <p>{t('pages.home.scheduleFinished')}</p>
      <p>{t('pages.home.waitingRelease')}</p>
    </div>
  );
  const columnsContract: ColumnsType<IFetchContracts> = [
    {
      title: t('pages.home.clients'),
      dataIndex: ['farm', 'name'],
      key: 'farmId',
      sorter: (a, b) => a.farm.name.localeCompare(b.farm.name),
    },
    {
      title: t('pages.home.stateRegistration'),
      dataIndex: ['farm', 'stateRegistration'],
      key: 'stateRegistration',
    },
    {
      title: t('pages.home.contract'),
      dataIndex: ['referenceNumber'],
      key: 'referenceNumber',
      render: contractNumber => <Tag>{contractNumber}</Tag>,
    },
    {
      title: () => (
        <>
          {t('pages.home.status')}{' '}
          <Tooltip title={statusMessage}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: ['status'],
      key: 'status',
      render: status => {
        if (status == 'WAITING_SCHEDULE') {
          return <Tag color="orange">{t('pages.home.waitingSchedule')}</Tag>;
        } else if (status == 'SCHEDULE_FINISHED') {
          return <Tag color="green">{t('pages.home.scheduleFinished')}</Tag>;
        } else if (status == 'WAITING_RELEASE') {
          return <Tag color="blue">{t('pages.home.waitingRelease')}</Tag>;
        }
      },
    },
    {
      title: t('pages.home.contractDate'),
      dataIndex: ['contractDate'],
      key: 'date',
      render: date => moment(date).format('DD/MM/YYYY'),
    },
    {
      title: t('pages.home.items'),
      dataIndex: ['contractItems'],
      key: 'items',
      render: items => <div>{items.length}</div>,
    },
    {
      title: t('pages.home.action'),
      key: 'action',
      render: (_, record) => (
        <Button
          icon={<EyeOutlined />}
          type="link"
          onClick={() => {
            setSelectedContract(record);
            setOpen(true);
          }}
        >
          {t('pages.home.view')}
        </Button>
      ),
    },
  ];

  return (
    <Table
      rowKey={contract => contract.id}
      columns={columnsContract}
      dataSource={data}
      loading={isLoading}
      scroll={{ x: 1300 }}
      size="small"
    />
  );
};

export default HomeTable;
