import React from 'react';
import { IContextValue } from './RuleContext.types';

const defaultValue: IContextValue = {
  calendarDates: [],
  customerRules: [],
  operation: 'create',
  ruleType: undefined,
  disableCadence: false,
  handleSetDisableCadence: () => {
    return;
  },
  handleSetCalendarDates: () => {
    return;
  },
  handleSetOperation: () => {
    return;
  },
  handleSetRuleType: () => {
    return;
  },
  handleSetCustomerRules: () => {
    return;
  },
};

export const RuleContext = React.createContext<IContextValue>(defaultValue);
