import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import { Moment } from 'moment';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { IFetchContracts } from '../../structures/interfaces/Contract';
import {
  FilterContractsByDate,
  FilterContractsByStatus,
} from './Contracts.utils';

interface IContractsFilterProps {
  contracts: IFetchContracts[];
  setFilteredContracts: Dispatch<SetStateAction<IFetchContracts[]>>;
}
interface IFormValues {
  search: string;
  status: string;
  contractDate: [Moment, Moment];
  salesmen: string;
  searchContracts: string;
}

const ContractsFilter = ({
  contracts,
  setFilteredContracts,
}: IContractsFilterProps): ReactElement => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IFormValues>();

  const statusOptions = [
    { label: t('pages.home.waitingSchedule'), value: 'WAITING_SCHEDULE' },
    { label: t('pages.home.scheduleFinished'), value: 'SCHEDULE_FINISHED' },
    { label: t('pages.home.waitingRelease'), value: 'WAITING_RELEASE' },
  ];

  const onFinish = ({
    search = '',
    searchContracts = '',
    contractDate,
    status,
  }: IFormValues) => {
    const filteredItems = contracts.filter(item => {
      if (searchContracts && search) {
        return (
          item?.contractsSlug
            ?.split(' ')
            .includes(searchContracts.toLowerCase().trim()) &&
          item?.slug?.includes(search.toLowerCase().trim())
        );
      } else if (searchContracts) {
        return item?.contractsSlug
          ?.split(' ')
          .includes(searchContracts.toLowerCase().trim());
      } else {
        return item?.slug?.includes(search.toLowerCase().trim());
      }
    });

    setFilteredContracts(
      FilterContractsByDate(
        FilterContractsByStatus(filteredItems, status),
        contractDate
      )
    );
  };

  return (
    <Card>
      <Form layout="vertical" size="small" form={form} onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item name={'searchContracts'} label={'Contratos'}>
              <Input.Search
                allowClear
                enterButton
                onSearch={() => form.submit()}
                placeholder="Procure por contratos na tabela"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'search'} label={'Pesquisa'}>
              <Input.Search
                allowClear
                enterButton
                onSearch={() => form.submit()}
                placeholder="Procure itens na tabela"
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item name={'status'} label={'Status'}>
              <Select
                allowClear
                placeholder="Selecione"
                onSelect={() => form.submit()}
                onClear={() => form.submit()}
                options={statusOptions}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item name={'contractDate'} label={'Datas do contrato'}>
              <DatePicker.RangePicker
                allowClear
                style={{ width: '100%' }}
                format={'DD/MM/YYYY'}
                onChange={() => form.submit()}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Space>
              <Button
                icon={<ClearOutlined />}
                children={'Limpar filtro'}
                onClick={() => form.resetFields()}
              />
              <Button
                icon={<FilterOutlined />}
                type="primary"
                children={'Filtrar'}
                onClick={() => form.submit()}
              />
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ContractsFilter;
