interface ILanguage {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const br: ILanguage = {
  translations: {
    tab: {
      authentication: 'Autenticação',
      signUp: 'Cadastro',
      home: 'Home',
      truckLoads: 'Cargas',
      createTruckLoad: 'Formar Carga',
      viewTruckLoad: 'Visualizar Carga',
      settings: 'Configurações',
      configureAccount: 'Configurar conta',
      // sub-config
      myCompany: 'Minha empresa',
      editCompany: 'Editar empresa',
      scheduleRules: 'Regras',
      ruleSettings: 'Configurações',
      tutorials: 'Tutoriais',
      reports: 'Relatórios',
      graphs: 'Gráficos',
      icons: 'Ícones',
      validateEmail: 'Verificando Email...',
      registerCompany: 'Cadastrar Empresa',
      createPassword: 'Criar Senha',
      termsOfUse: 'Termos de Uso',
      companyCreated: 'Empresa Registrada',
      contracts: 'Contratos',
      clients: 'Clientes',
      users: 'Usuários',
      cargos: 'Cargas',
      myAccount: 'Minha conta',
    },
    languages: {
      ptBR: 'Português',
      enUS: 'English',
      es: 'Español',
    },
    headerMenu: {
      logOut: 'Sair',
      myAccount: 'Minha conta',
      help: {
        tutorialPage: 'Tutoriais do sistema',
      },
      crop: {
        selectPh: 'Selecionar safra',
        canNotChange: 'Não é possível alterar a safra nesta tela.',
        errors: {
          cantFind: 'Não foi possível encontrar safras, recarregue a página',
        },
      },
      organization: {
        title: 'Selecionar empresa',
        errors: {
          cantFind: 'Não é possível alterar a sementeira nesta tela.',
        },
      },
    },
    general: {
      logoAlt: 'Logo da LogMetrics',
      yes: 'Sim',
      no: 'Não',
      close: 'Fechar',
      back: 'Voltar',
      save: 'Salvar',
      cancel: 'Cancelar',
      create: 'Criar',
      update: 'Editar',
      select: 'Selecione',
    },
    sidebar: {
      options: {
        home: 'Home',
        contracts: 'Contratos',
        clients: 'Clientes',
        users: 'Usuários',
        truckLoads: 'Cargas',
        settings: 'Regras',
        // sub-config
        myCompany: 'Minha empresa',
        editCompany: 'Editar empresa',
        scheduleRules: 'Cadastro',
        ruleSettings: 'Configurações',
        tutorials: 'Tutoriais',
        reports: 'Relatórios',
        graphs: 'Gráficos',
        icons: 'Ícones',
      },
    },
    input: {
      rules: {
        required: 'Campo obrigatório',
        invalidEmail: 'Email inválido',
        minPassword: 'Mínimo 6 caracteres',
        invalidDocument: 'CPF/CNPJ inválido',
        invalidDocumentCPF: 'CPF inválido',
        invalidDocumentCNPJ: 'CNPJ inválido',
        invalidPhoneNumber: 'Telefone inválido',
        invalidZipCode: 'CEP inválido',
      },
    },
    pages: {
      customerAccount: {
        firstConfig: {
          title: 'Criar conta',
          subtitle: 'Crie sua conta para agendar seus contratos',

          // form titles
          basicData: 'Dados básicos',
          companyData: 'Dados da empresa',
          contact: 'Contato',
          address: 'Endereço da empresa',
          security: 'Segurança',

          // label
          name: 'Nome',
          lastName: 'Sobrenome',
          documentNumberCpf: 'CPF',

          tradeName: 'Empresa',
          companyName: 'Razão social da empresa',
          documentNumberCnpj: 'CNPJ',
          stateRegistration: 'Inscrição Estadual',

          phoneNumber: 'Telefone',
          email: 'E-mail',

          zipCode: 'CEP',
          stateShortName: 'Estado',
          cityName: 'Cidade',
          district: 'Bairro',
          streetName: 'Logradouro',
          streetNumber: 'Número',

          password: 'Senha',
          repeatNewPassword: 'Repetir senha',

          // placeholders
          phName: 'Informe o nome',
          phLastName: 'Informe o sobrenome',
          phDocumentNumberCpf: 'Informe o CPF',

          phTradeName: 'Informe o nome da empresa',
          phCompanyName: 'Informe a razão social da empresa',
          phDocumentNumberCnpj: 'Informe o CNPJ',
          phStateRegistration: 'Informe a inscrição estadual',

          phPhoneNumber: 'Telefone da empresa',
          phEmail: 'E-mail da empresa',

          phZipCode: 'Informe o CEP',
          phStateShortName: 'Informe o Estado',
          phCityName: 'Informe a cidade',
          phDistrict: 'Informe o bairro',
          phStreetName: 'Informe o logradouro',
          phStreetNumber: 'Informe o número',

          phPassword: 'Informe sua senha',
          phRepeatNewPassword: 'Repita a senha',

          // rule messages
          rmName: 'O nome é obrigatório!',
          rmLastName: 'O sobrenome é obrigatório!',
          rmDocumentNumberCpf: 'O CPF é obrigatório!',
          rmDocumentNumberCpfMinSize: 'O CPF deve ter no mínimo 11 dígitos!',

          rmTradeName: 'O nome da empresa é obrigatório!',
          rmCompanyName: 'A razão social da empresa é obrigatória!',
          rmDocumentNumberCnpj: 'O CNPJ é obrigatório!',
          rmDocumentNumberCnpjMinSize: 'O CNPJ deve ter no mínimo 14 dígitos!',
          rmStateRegistration: 'A inscrição estadual é obrigatória',

          rmPhoneNumber: 'O telefone é obrigatório!',
          rmEmail: 'O e-mail é obrigatório!',

          rmZipCode: 'O CEP é obrigatório!',
          rmStateShortName: 'O Estado é obrigatório!',
          rmCityName: 'A cidade é obrigatória!',
          rmDistrict: 'O bairro é obrigatório!',
          rmStreetName: 'O logradouro é obrigatório!',
          rmStreetNumber: 'O número é obrigatório!',

          rmPassword: 'A senha é obrigatória!',
          rmRepeatNewPassword: 'Confirmar sua senha é obrigatório!',
          rmMinLenPassword: 'A senha deve ter no mínimo 6 caracteres',
          rmComparePasswords: 'As senhas que você inseriu não são iguais!',

          // infos
          success: {
            message: 'Conta atualizada com sucesso',
          },
          error: {
            message: 'Não foi possível atualizar a conta!',
          },
        },
      },
      account: {
        tradeName: 'Empresa',
        companyName: 'Razão social',
        cnpj: 'CNPJ',
        cpf: 'CPF',
        stateRegistration: 'Inscrição Estadual',
        phoneNumber: 'Telefone',
        email: 'E-mail',
        zipCode: 'CEP',
        stateShortName: 'Estado',
        cityName: 'Cidade',
        district: 'Bairro',
        street: 'Logradouro',
        streetNumber: 'Número',
        // actions
        editAccountBtn: 'Editar',
        changePasswordBtn: 'Alterar senha',
        error: {
          errToAccess:
            'Não foi possível acessar a conta! tente novamente mais tarde',
        },
        updateAccount: {
          title: 'Editar conta',
          name: 'Nome',
          documentNumberCpf: 'CPF',
          phoneNumber: 'Telefone',
          email: 'E-mail',

          // placeholder
          phName: 'Insira o nome',
          phDocumentNumberCpf: 'Insira o CPF',
          phPhoneNumber: 'Insira o telefone',
          phEmail: 'Insira o e-mail',

          // rule message
          rmName: 'Informe o nome!',
          rmDocumentNumberCpf: 'Informe o CPF!',
          rmMinSizeDocumentNumberCpf: 'O CPF deve ter 11 dígitos',
          rmPhoneNumber: 'Informe o telefone!',
          rmEmail: 'Informe o e-mail!',

          // buttons
          cancelButton: 'Cancelar',
          confirmButton: 'Confirmar',

          // messages
          errors: {
            message: 'Não foi possível editar a conta!',
            description: 'Tente novamente ou espere um momento',
          },
          success: {
            message: 'Conta atualizada com sucesso',
          },
        },
        changePassword: {
          title: 'Alterar Senha',

          labelNewPassword: 'Nova senha',
          labelRepeatNewPassword: 'Repetir senha',

          phNewPassword: 'Insira a senha',
          phRepeatNewPassword: 'Repita a senha',

          rmNewPassword: 'Informe a nova senha!',
          rmRepeatNewPassword: 'Repita a nova senha!',
          rmMinLenPassword: 'A senha deve ter no mínimo 6 caracteres',
          rmComparePasswords: 'As senhas que você inseriu não são iguais!',

          confirmButton: 'Confirmar',
          cancelButton: 'Cancelar',

          success: {
            message: 'Senha alterada com sucesso!',
          },
        },
      },
      loading: {
        text: 'Carregando',
      },
      login: {
        title: 'Login',
        subtitle: 'Informe seus dados para entrar na sua conta.',
        userFirstLoginTitle: 'Primeiro Login',
        customerFirstLoginTitle: 'Primeiro Login',
        fields: {
          email: {
            label: 'E-mail',
            placeholder: 'Seu E-mail',
          },
          password: {
            label: 'Senha',
            placeholder: 'Sua senha',
          },
          keepConnected: 'Mantenha-me conectado',
          passwordRecovery: 'Recuperar senha',
          loginButton: 'Entrar',
          // messages and helps
          invalidCredentials: 'Credenciais inválidas',
          requiredField: 'Esse campo é obrigatório',
          invalidEmail: 'Email inválido',
          minimumDigits: 'A senha deve ter no mínimo 6 digitos',
          maximumDigits: 'A senha deve ter no máximo 128 digitos',
        },
        footer: {
          text: 'Não possui uma conta? ',
          signup: 'Cadastre-se!',
        },
        success: {
          message: 'Login efetuado!',
          description: 'Redirecionando para home do sistema',
        },
        recoveryPassword: {
          title: 'Redefinir senha',
          subtitle: 'Informe seu e-mail',
          requiredMessage: 'Código enviado no e-mail',
          placeholder: 'Seu e-mail',
          resendEmail: 'Reenviar e-mail',
          confirmButton: 'Confirmar',
          codePlaceholder: 'Digite aqui o código enviado no seu email',
          codeMessage: 'Codigo enviado no email',
          newPassword: 'Nova senha',
          confirmPassword: 'Confirmar senha',
          newPasswordPlaceholder: 'Digite a nova senha',
        },
      },
      signUp: {
        title: 'Criando sua conta',
        subtitle: 'Informe os dados abaixo para se cadastrar no sistema',
        fields: {
          labels: {
            name: 'Nome',
            email: 'E-mail',
            phone: 'Telefone',
            companyFunction: 'Segmento da sua empresa',
            contract: 'Número do contrato',
            tmpPassword: 'Senha temporária',
          },
          placeholders: {
            name: 'Seu nome',
            email: 'Seu e-mail',
            phone: 'Seu número de telefone',
            companyFunction: 'Segmento da sua empresa',
            contract: 'Insira o número do seu contrato',
            password: 'Sua senha',
          },
          ruleMessages: {
            rmContract: 'O número de contrato obrigatório!',
            rmEmail: 'O e-mail é obrigatório!',
            rmPassword: 'A senha é obrigatória!',
            rmPasswordMinSize: 'A senha deve ter no mínimo 6 digitos!',
            rmIncorrectPwdEmail: 'E-mail e/ou senha incorretos',
            rmInvalidEmail: 'E-mail inválido',
          },
        },
        action: {
          cancel: 'Cancelar',
          submit: 'Criar conta',
          return: 'Voltar',
        },
        terms: {
          text: 'Ao clicar em <strong>criar conta</strong> você concorda com os',
          link: 'termos de uso',
        },
        confirm: {
          cancel: 'Você realmente deseja cancelar o cadastro?',
        },
        messages: {
          accountCreated: {
            title: 'Conta criada!',
            description:
              'Foi enviado para o e‑mail informando um link para validação da conta. Complete seu cadastro a partir do link do email.',
            buttonText: 'Voltar para tela de login',
          },
          shipperCreated: {
            title: 'Embarcador cadastrado!',
            description:
              'Estamos validando seus dados. Aguarde o retorno no seu email.',
            buttonText: 'Voltar para tela de login',
          },
          carrierCreated: {
            title: 'Transportador cadastrado!',
            description:
              'Estamos validando seus dados. Aguarde o retorno no seu email.',
            buttonText: 'Voltar para tela de login',
          },
          accountInApproval: {
            title: 'Conta em aprovação',
            description: [
              'Sua conta está aguardando aprovação.',
              'Em breve você recebera um email com novidades.',
            ],
            buttonText: 'Voltar para tela de login',
          },
          accountDisapproved: {
            title: 'Conta reprovada',
            explanation: {
              reasons: 'Motivos',
              description: 'Descrição',
            },
            buttonText: 'Regularizar cadastro',
          },
          completeRegistration: {
            title: 'Completar cadastro',
            description:
              'Precisamos de mais algumas informações da sua empresa para ela estar ativa.',
            buttonText: 'Completar cadastro',
          },
        },
        firstSignIn: {
          title: 'Cadastrando conta cliente',
          subtitle: 'Informe os dados abaixo para iniciar o cadastro',
        },
      },
      home: {
        title: 'Bem vindo a Home!',
        contracts: 'Contratos',
        client: 'Cliente',
        stateRegistration: 'Inscrição estadual',
        cpf: 'CPF',
        cnpj: 'CNPJ',
        contract: 'Contrato',
        status: 'Status',
        email: 'Email',
        phone: 'Telefone',
        items: 'Itens',
        bags: 'Bags',
        totalWeight: 'Peso est. total',
        tsi: 'TSI',
        action: 'Ações',
        searchItens: 'Pesquisar itens na tabela',
        selectStatus: 'Selecionar status',
        contractDate: 'Data do contrato',
        cleanFilter: 'Limpar filtro',
        filter: 'Filtrar',
        pendencies: 'Pendências',
        companyData: 'Dados empresa',
        users: 'Usuários',
        clients: 'Clientes',
        schedulingRules: 'Regras agendamento',
        totalLoads: 'Total de cargas',
        formLoadBtn: 'Formar carga',
        pendingContracts: 'Contratos pendentes',
        loadsWaitingForDriver: 'Aguardando motorista',
        loadsReleased: 'Cargas liberadas',
        loadedLoads: 'Cargas carregadas',
        canceledLoads: 'Cargas canceladas',
        finishedContracts: 'Contratos finalizados',
        alt: 'Sem imagem',
        // action table
        editAction: 'Editar',
        deleteAction: 'Excluir',
        view: 'Visualizar',
        yesTsi: 'Sim',
        NoTsi: 'Não',
        // info
        promptText: 'Status possíveis:',
        waitingSchedule: 'AGUARDANDO AGENDAMENTO',
        scheduleFinished: 'FINALIZADO',
        waitingRelease: 'AGUARDANDO LIBERAÇÃO',
        pendencieLinkMyOrganization: 'Minha empresa',
        pendencieLinkMyUsers: 'Meus usuários',
        pendencieLinkMyClients: 'Meus clientes',
        pendencieLinkScheduleRules: 'Regras',
        pendencieLinkMyContracts: 'Meus contratos',
        // labels
        statusLabel: 'Status',
        contractDateLabel: 'Data do contrato',
      },
      contracts: {
        title: 'Contratos',
        subtitle: 'Visualize e gerencie seus contratos',
        importContractsBtn: 'Importar contratos',
        searchItemPlaceholder: 'Pesquisar item da tabela',
        statusLabel: 'Status',
        localLabel: 'Local',
        contractDateLabel: 'Data de contrato',
        initDate: 'Início',
        finalDate: 'Fim',
        salesmen: 'Vendedores',
        phSalesmen: 'Selecionar vendedores',
        allContracts: 'Todos contratos',
        myContracts: 'Meus contratos',
        subordinatesContracts: 'Contratos de subordinados',
        clearFilter: 'Limpar filtro',
        filter: 'Filtrar',
        // error messages
        errorMessage: 'Este campo é obrigatório',
        // select
        option: 'opção 1',
        // table columns
        seedType: 'Tipo',
        itemID: 'Item ID',
        cultivate: 'Cultivar',
        sieve: 'Peneira',
        category: 'Cat',
        packing: 'Embalagem',
        tsi: 'TSI',
        estimatedWeight: 'Peso est. Total',
        remainingAmount: 'Quantidade restante',
        fullAmount: 'Quantidade total',
        clients: 'Cliente',
        stateRegistration: 'Inscrição Estadual',
        contracts: 'Contrato',
        status: 'Status',
        date: 'Data',
        email: 'E-mail',
        phoneNumber: 'Telefone',
        bags: 'Bags',
        actions: 'Ações',

        salesmanName: 'Nome do vendedor',
        salesmanDocumentNumberCpf: 'CPF do vendedor',
        // action table
        view: 'Visualizar',
        yesTsi: 'Sim',
        noTsi: 'Não',
        // info
        waitingSchedule: 'AGUARDANDO AGENDAMENTO',
        scheduleFinished: 'FINALIZADO',
        waitingRelease: 'AGUARDANDO LIBERAÇÃO',
        remainingAmountMessage:
          'É a quantidade restante deste item no contrato. Essa quantia representa o número de itens disponíveis.',
        fullAmountMessage:
          'É a quantidade total deste item que foi contratada.',
        // Buttons
        formLoadBtn: 'Formar carga',
        listingCardTitle: 'Itens de contratos',
        items: 'Itens do contrato',

        importModal: {
          title: 'Importar arquivo',
          dragFile: 'Arraste ou selecione um arquivo',
          acceptedFiles: 'Tipos de arquivos aceitos',
          filesType: '.csv, .xlsx ou .xltx',
          confirmButton: 'Confirmar',
          cancelButton: 'Cancelar',

          infoConfigTable: 'Configure a tabela',
          rmFileType: 'O arquivo não é do tipo csv, xlsx ou xltx!',
          rmHasFile: 'Você deve inserir um arquivo!',

          errors: {
            message: 'Não foi possível importar o arquivo!',
            description: 'Tente novamente ou espere um momento',
          },
          success: {
            message: 'Arquivo importado com sucesso',
          },
        },

        importConfig: {
          title: 'Importar contratos',
          subtitle: 'Configure os dados de importação',
          formTitle: 'Configurando importação',

          labelFirstLine: 'Primeira linha após o cabeçalho',
          labelReferenceNumber: 'Número do item do contrato',
          tooltipReferenceNumber: 'É a identificação do item de contrato.',
          labelCultivation: 'Cultivar',
          labelPacking: 'Embalagem',
          labelSieve: 'Peneira',
          labelSeedType: 'Semente',
          labelCategory: 'Categoria',
          labelTsi: 'TSI',
          labelEstimatedWeight: 'Peso estimado',
          labelFullAmount: 'Quantidade total',
          labelRemainingAmount: 'Quantidade restante',
          labelContractReferenceNumber: 'Número do contrato',
          tooltipContractReferenceNumber: 'É a identificação do contrato.',
          labelContractCustomerStateRegistration:
            'Inscrição estadual do cliente',
          labelContractUnity: 'Unidade',
          labelContractUnityWeight: 'Peso da unidade',
          labelContractDate: 'Data do contrato',
          labelContractSalesmanDocumentNumberCpf: 'CPF do vendedor',
          labelContractReferenceContract: 'Número do contrato de referência',
          tooltipContractReferenceContract:
            'Se o contrato é proveniente de outro contrato, você deve inserir o número de referência do contrato de origem',
          labelContractPickuLocation: 'Local de retirada',
          tooltipContractPickupLocation:
            'Indica para qual local de retirada os items deste contrato serão assinalados',

          rmFirstLine: 'Informe a linha!',
          rmReferenceNumber:
            'Informe o número de identificação do item do contrato!',
          rmCultivation: 'Informe o cultivo!',
          rmPacking: 'Informe a embalagem',
          rmSieve: 'Informe a peneira',
          rmSeedType: 'Informe a semente',
          rmCategory: 'Informe a categoria',
          rmTsi: 'Informe o TSI!',
          rmEstimatedWeight: 'Informe o peso estimado',
          rmFullAmount: 'Informe a quantidade total',
          rmRemainingAmount: 'Informe a quantidade restante',
          rmContractReferenceNumber:
            'Informe o número de identificação do contrato',
          rmContractCustomerStateRegistration:
            'Informe a Inscrição estadual do cliente',
          rmContractUnity: 'Informe a unidade',
          rmContractDate: 'Informe a data do contrato',
          rmContractSalesmanDocumentNumberCpf: 'Informe o CPF do vendedor',
          rmContractReferenceContract:
            'Informe o número de identificação do contrato de referência',
          rmContractPickupLocation:
            'Informe o local de retirada deste contrato',

          rmSameValueField: 'Há campos de colunas iguais!',
          rmSameValueFieldDescription:
            'Você deve relacionar uma coluna da tabela que está tentando importar com a coluna (A, B, C...) correspondente da tabela de pré-visualização abaixo! ',
          rmSameValueFieldReview:
            'Verifique os campos acima e tente novamente!',

          phFirstLine: 'Selecione a linha',
          phSelect: 'Selecione',

          selectLine: 'Linha',

          tableTitle: 'Tabela de clientes',

          confirmButton: 'Confirmar',
          cancelButton: 'Cancelar',

          success: {
            message: 'Arquivo importado com sucesso',
          },

          errors: {
            message: 'Não foi possível importar o arquivo!',
            description: 'Tente novamente ou espere um momento',
            verifyInputs:
              'Verifique se o tipo de dado de cada coluna está correto.',
            sameValue:
              'Não pode haver campos com valores iguais na configuração!',
            api: 'Ocorreu um erro inesperado. Recarregue a página e tente novamente!',
            importFailed: {
              title: 'Ocorreu um erro ao importar os contratos abaixo',
              description: 'Contratos sem erros foram importados com sucesso',
              customerStateRegistration: 'Inscrição estadual',
              salesmanCpf: 'CPF do vendedor',
              contractReferenceNumber: 'Número do contrato',
              reason: 'Motivo',
            },
            contractsNotImporteds:
              'Os contratos com erros não foram importados',
          },

          downloadTemplateWorksheet: 'Baixar planilha modelo',
          templateWorksheet: 'Planilha modelo',
        },
      },
      activate: {
        modalTitle: 'Faça a ativação dos seus clientes',
        feedbackModal: {
          title: 'Cliente notificado com sucesso!',
          contentText: {
            partOne: 'Assim que ',
            partTwo: 'fizer seu primeiro login seu status mudará para',
          },
        },
        errors: {
          1: 'Não foi possível fazer a ativação do usuário!',
        },
        notification: {
          notShowMore: 'Não mostre mais essa mensagem',
          okButton: 'OK',
        },
      },
      clients: {
        title: 'Clientes',
        subtitle: 'Visualize e importe seus clientes',
        importClientsBtn: 'Importar clientes',
        searchItemPlaceholder: 'Pesquisar item da tabela',
        customerStatusLabel: 'Status do cliente',
        clearFilter: 'Limpar filtro',
        filter: 'Filtrar',

        listingCardTitle: 'Meus clientes cadastrados',
        table: {
          name: 'Nome',
          stateRegistration: 'Inscrição estadual',
          companyName: 'Razão social',
          documentNumberCnpj: 'CNPJ',
          documentNumberCpf: 'CPF',
          phoneNumber: 'Telefone',
          email: 'E-mail',
          actions: 'Ações',
        },

        clientTitle: 'Cliente',
        clientName: 'Nome',
        documentNumberCpf: 'CPF',
        documentNumberCnpj: 'CNPJ',
        clientEmail: 'E-mail',
        clientPhoneNumber: 'Telefone',
        view: 'Visualizar',
        farms: 'Fazendas',

        importModal: {
          title: 'Importar arquivo',
          setSeparator: 'Definir separador',
          comma: 'Vírgula',
          semiColon: 'Ponto vírgula',
          custom: 'Personalizado',
          dragFile: 'Arraste ou selecione um arquivo',
          acceptedFiles: 'Tipos de arquivos aceitos',
          filesType: '.csv, .xlsx ou .xltx',
          confirmButton: 'Confirmar',
          cancelButton: 'Cancelar',

          infoConfigTable: 'Configure a tabela',
          rmFileType: 'O arquivo não é do tipo csv, xlsx ou xltx!',
          rmHasFile: 'Você deve inserir um arquivo!',

          errors: {
            message: 'Não foi possível importar o arquivo!',
            description: 'Tente novamente ou espere um momento',
          },
          success: {
            message: 'Arquivo importado com sucesso',
          },
        },

        importConfig: {
          title: 'Importar clientes',
          subtitle: 'Configure os dados de importação',
          formTitle: 'Configurando importação',

          labelFirstLine: 'Primeira linha após o cabeçalho',
          labelName: 'Nome do cliente',
          labelFarmName: 'Nome da fazenda',
          labelCompanyName: 'Razão social da empresa',
          labelTradeName: 'Nome fantasia da empresa',
          labelStateRegistration: 'Inscrição estadual',
          labelDocumentNumberCpf: 'CPF',
          labelDocumentNumberCnpj: 'CNPJ',
          labelReferenceId: 'Número de identificação',
          tooltipReferenceId:
            'Se o cliente tiver um número de identificação no sistema de origem, você pode importá-lo',
          labelPhoneNumber: 'Celular',
          labelEmail: 'E-mail',

          rmFirstLine: 'Informe a linha!',
          rmName: 'Informe o nome!',
          rmCompanyName: 'Informe o nome da empresa!',
          rmStateRegistration: 'Informe a inscrição estadual!',
          rmDocumentNumberCpf: 'Informe o CPF!',
          rmDocumentNumberCnpj: 'Informe o CNPJ!',
          rmPhoneNumber: 'Informe o telefone!',
          rmEmail: 'Informe o e-mail!',
          rmSameValueField: 'Há campos de colunas iguais!',

          phFirstLine: 'Selecione a linha',
          phSelect: 'Selecione',

          selectLine: 'Linha',

          tableTitle: 'Tabela de clientes',

          confirmButton: 'Confirmar',
          cancelButton: 'Cancelar',

          errors: {
            message: 'Não foi possível importar o arquivo!',
            description: 'Tente novamente ou espere um momento',
            verifyInputs:
              'Verifique se o tipo de dado de cada coluna está correto.',

            sameValue:
              'Não pode haver campos com valores iguais na configuração!',
            api: 'Ocorreu um erro inesperado. Recarregue a página e tente novamente!',
            userAlreadyRegistered:
              'Os seguintes usuários presentes na planilha já estão cadastrados no sistema',
            repeatedInserts:
              'Os usuários a seguir estão repetidos na planilha, revise os dados e tente novamente',
            importFailedDescription:
              'Clientes sem erros foram importados com sucesso.',
            cnpjAndCpfNotInserted:
              'Você deve inserir o CPF ou CNPJ dos clientes no sistema',
          },

          success: {
            message: 'Arquivo importado com sucesso!',
          },
          downloadTemplateWorksheet: 'Baixar planilha modelo',
          templateWorksheet: 'Planilha modelo',
        },
      },
      users: {
        title: 'Usuários',
        titleCard: 'Usuários cadastrados',
        userType: 'Tipo de usuário',
        userStatus: 'Status de usuário',
        creationDate: 'Data de criação',
        userName: 'Nome',
        documentNumberCpf: 'CPF',
        company: 'Empresa',
        cellPhone: 'Celular',
        email: 'E-mail',
        actions: 'Ações',
        editAction: 'Editar',
        deleteAction: 'Excluir',
        listingCardTitle: 'Usuários cadastrados',
        subtitle: 'Visualize e crie novos usuários',
        clearFilter: 'Limpar filtro',
        filter: 'Filtrar',
        searchItemPlaceholder: 'Pesquisar item da tabela',
        phUserType: 'Selecione',
        newUserBtn: 'Cadastrar novo usuário',

        errors: {
          hasNoReadPermission:
            'O usuário não pode ter uma permissão de Edição, Criação ou Exclusão se ele não tiver a permissão de Leitura da página',
          mustHaveHomeReadPermission:
            'O usuário deve ter a permissão de leitura da HOME (página padrão)',
        },
        newUser: {
          title: 'Cadastrar novo usuário',
          subtitle: 'Crie novos usuários para a sua empresa',

          // placeholders
          phUserType: 'Selecione',
          phUserName: 'Informe o nome',
          phDocumentNumberCpf: 'Informe o CPF',
          phCompany: 'Selecione',
          phPhoneNumber: 'Informe o número de celular',
          phEmail: 'Informe o email',

          // rules
          rmUserType: 'O tipo do usuário é obrigatório!',
          rmName: 'O nome é obrigatório!',
          rmDocumentNumberCpf: 'O CPF é obrigatório!',
          rmMinSizeDocumentNumberCnpj: 'O CPF deve possuir 11 dígitos!',
          rmOrganizationName: 'O nome da empresa é obrigatório!',
          rmPhoneNumber: 'O celular é obrigatório!',
          rmEmail: 'O email é obrigatório!',

          warningValidEmail: 'Insira um email válido!',

          labels: {
            title: 'Dados básicos do usuário',
            userType: 'Tipo de usuário',
            userName: 'Nome do usuário',
            documentNumberCpf: 'CPF',
            organizationName: 'Empresa',
            phoneNumber: 'Celular',
            email: 'E-mail',
          },
          userPermissions: {
            title: 'Permissões do usuário',
            pages: 'Páginas',
            reading: 'Leitura',
            edit: 'Edição',
            create: 'Criação',
            delete: 'Exclusão',
          },
          success: {
            message: 'Conta de usuário criada com sucesso!',
            description:
              'Anote a senha pois você não terá mais acesso à ela! A senha é: ',
          },
          footer: {
            confirmBtn: 'Confirmar',
            cancelBtn: 'Cancelar',
          },
        },
        updateUser: {
          title: 'Editar usuário',
          // labels
          typeId: 'Tipo de usuário',
          name: 'Nome',
          documentNumberCpf: 'CPF',
          userCompany: 'Empresa',
          phoneNumber: 'Celular',
          email: 'E-mail',
          // rule messages
          rmTypeId: 'O tipo de usuário é obrigatório!',
          rmName: 'O nome é obrigatório!',
          rmDocumentNumberCpf: 'O CPF é obrigatório!',
          rmMinSizeDocumentNumberCpf: 'O CPF deve ter 11 dígitos!',
          rmUserCompany: 'A empresa é obrigatória!',
          rmPhoneNumber: 'O telefone é obrigatório!',
          rmEmail: 'O email é obrigatório!',
          // placeholders
          phTypeId: 'Informe o tipo do usuário',
          phName: 'Informe o nome',
          phDocumentNumberCpf: 'Informe o CPF',
          phUserCompany: 'Informe a empresa',
          phPhoneNumber: 'Informe o número de celular',
          phEmail: 'Informe o email',

          confirmButton: 'Confirmar',
          cancelButton: 'Cancelar',
          success: {
            message: 'Conta de usuário editada com sucesso!',
          },
        },
        deleteUser: {
          title: 'Excluir usuário',
          info: 'Atenção! Você está prestes a excluir o usuário ',
          warning: 'Esta ação é irreversível.',
          confirmMessage: 'Tem certeza que deseja prosseguir?',

          successMessage: 'Usuário excluído com sucesso!',
          errorMessage: 'Não foi possível excluir o usuário',

          cancelButton: 'Cancelar',
          confirmButton: 'Confirmar',
        },
        regularizeUser: {
          userNotified: 'Usuário notificado com sucesso!',
          waitingAccess: {
            theUser: 'O usuário ',
            warning:
              'Já foi notificado! Agora basta esperar que ele acesse o sistema e conclua seu cadastro para ter seu status como "Ativo"',
          },
          status: {
            notActivated: 'Inativo',
            waitingActivation: 'Aguardando ativação',
            activated: 'Ativo',
          },
          regActions: {
            activate: 'Ativar',
            waitingAccess: 'Aguardando acesso...',
          },
          error: 'Não foi possível realizar a ativação!',
        },
      },
      truckLoads: {
        title: 'Cargas',
        subtitle: 'Visualize e gerencie cargas',

        // filter
        statusLabel: 'Status',
        loadDateLabel: 'Data do contrato',
        pickUpLocationLabel: 'Locais de retirada',

        phSearchItens: 'Pesquisar itens na tabela',
        phSelectStatus: 'Selecionar status',
        phLoadDate: 'Data do contrato',
        phPickUpLocation: 'Selecione o local de retirada',

        cleanFilter: 'Limpar filtro',
        filter: 'Filtrar',

        // table
        listingCardTitle: 'Cargas cadastradas',
        formLoadBtn: 'Formar carga',

        farmName: 'Nome',
        customer: 'Cliente',
        stateRegistration: 'Inscrição Estadual',
        loadOrder: 'OC',
        loadName: 'Nome da carga',
        scheduledDate: 'Data agendada',
        vehicle: 'Veículo',
        fullLoad: 'Carga total',
        estimatedWeight: 'Peso estimado',
        estimatedWeightTooltip: 'Peso estimado da carga em toneladas',
        loadedBags: 'Cargas carregadas',
        driver: 'Motorista',
        setDriver: 'Informar motorista',
        setDriverUntil: 'Informe o motorista até',
        status: 'Status',
        actions: {
          title: 'Ações',
          details: 'Detalhes',
          authorize: 'Autorizar',
          loadTruck: 'Carregar',
          update: 'Editar',
          updateNotAllowed: 'Não é possível atualizar esta carga',
          view: 'Visualizar',
          delete: 'Cancelar',
        },
        descriptions: {
          name: 'Nome da carga',
          vehicle: 'Veículo selecionado',
          date: 'Dia selecionado',
          maxCapacity: 'Capacidade máxima do veículo',
          availability: 'Disponibilidade do dia',
          location: 'Endereço de retirada',
          seedType: 'Cultura',
          status: 'Status',
          observation: 'Observação sobre carga',
          loadOrder: 'Ordem de carregamento',
        },
        // info
        promptText: 'Status possíveis:',
        loading: 'Carregando',
        waitingDriver: 'Aguardando motorista',
        waitingComercial: 'Aguardando comercial',
        waitingActions: 'Aguardando ações',
        refuseReason: 'Razões de recusa',
        loadReleased: 'Carregamento liberado',
        loaded: 'Carregado',
        canceled: 'Cancelada',
        expired: 'Expirada',
        downloadLOTooltip: 'Fazer download',
        loadOrderTooltip: 'Ordem de Carregamento',
        salesmen: 'Vendedores',
        phSalesmen: 'Selecionar vendedores',
        allTruckLoads: 'Todas cargas',
        myTruckLoads: 'Minhas cargas',
        subordinatesTruckLoads: 'Cargas de subordinados',
        createLoad: {
          title: 'Formar carga',
          subtitle: 'Selecione os itens para formar sua carga',
          timeout: 'Tempo de reserva esgotado',
          steps: {
            one: 'Data e veículo',
            two: 'Itens e organização',
            three: 'Confirmação de carga',
          },
          buttons: {
            filter: 'Filtrar',
            clearFilter: 'Limpar',
            addItems: 'Adicionar itens a carga',
            cancel: 'Cancelar',
            saveChanges: 'Salvar alterações',
          },
          footer: {
            countdown: 'Tempo restante:',
            finishBtn: 'Finalizar agendamento',
            confirmBtn: 'Avançar',
            cancelBtn: 'Cancelar',
            backBtn: 'Voltar',
          },
          cardTitles: {
            descriptions: 'Informe os dados básicos da carga',
            contractItems: 'Itens do contrato',
            loadInBags: 'Carga em bags',
            loadInSmallBags: 'Carga em sacas',
            loadInTons: 'Carga em toneladas',
            loads: 'Cargas formadas',
          },
          contractItems: {
            quantity: 'QUANTIDADES',
            columns: {
              client: 'Cliente',
              stateRegister: 'Inscrição estadual',
              contract: 'Contrato',
              date: 'Data do contrato',
              id: 'Item ID',
              cultivation: 'Cultivar',
              sieve: 'Peneira',
              category: 'Cat',
              package: 'Embalagem',
              tsi: 'TSI',
              weight: 'Peso Est. Total',
              contractAmount: 'Contrato',
              pickupAmount: 'Disponível',
              pickup: 'Retirar',
              actions: 'Ações',
            },
          },
          createdLoads: {
            columns: {
              organization: 'Org.',
              order: 'Ordem',
              allocated: 'QTD. alocada',
              actions: 'Ações',
              loaded: 'QTD. Carregada',
            },
          },
          dropdown: {
            all: 'Todos',
          },
          placeholders: {
            search: 'Pesquisar itens da tabela',
            quantity: 'QTD',
          },
          labels: {
            cultivation: 'Cultivar',
            contractDate: 'Data do contrato',
            driver: 'Motorista',
            driverCpf: 'CPF do motorista',
            driverCellphone: 'Telefone',
            vehicleModel: 'Modelo do veículo',
            vehicleLicensePlate: 'Placa do veículo',
            notes: 'Observações',
          },
          messages: {
            preSchedule: 'Pré agendamento efetuado com sucesso',
            preScheduleCancel: 'Pré agendamento cancelado',
            formedLoad: 'Carga formada com sucesso',
            fillDriverFields:
              'Os dados da transportadora/motorista não foram preenchidos!',
          },
          warnings: {
            createdLoad:
              'Organize a ordem de descarga arrastando o ícone na coluna <strong>Org.</strong> da tabela <strong>Cargas formadas</strong>',
            noCadences:
              'Nenhuma data de agendamento encontrada para a cultura, local de embarque e unidade de medida selecionados.',
            noLocation:
              'Nenhum local de embarque encontrado para a cultura selecionada',
          },
          stepOne: {
            cardTitles: {
              inputs: 'Informe os dados básicos da carga',
              calendar: 'Selecione uma data',
            },
            calendarCell: {
              unavailable: 'Indisponível',
              availability: 'Disponível',
              start: 'Início',
              end: 'Fim',
            },
            labels: {
              loadName: 'Nome da carga',
              vehicle: 'Veículo',
              seedType: 'Cultura',
              location: 'Local de embarque',
              unity: 'Unidade de medida',
              unityWeight: 'Peso da saca',
            },
            placeholders: {
              loadName: 'Informe o nome',
              vehicle: 'Selecionar',
              seedType: 'Selecionar',
              location: 'Selecionar',
              unity: 'Selecionar',
            },
            whiteSeedsSelectedDayModal: {
              title: 'Atenção',
              cancelBtn: 'Cancelar',
              confirmBtn: 'Confirmar',
              content:
                'O dia selecionado é menor que o período minimo de TSI, você poderá agendar sementes brancas nessa data. Se deseja agendar sementes com TSI escolha outra data a partir de ',
            },
            unityFilter: {
              bags: 'Bags',
              smallBags: 'Sacas',
            },
          },
          stepTwo: {
            contractItemsModal: {
              nonExistContractItemsCustomer:
                'Você não possui nenhum item de contrato de contrato (para esta cultura e/ou unidade) disponível para agendamento',
              nonExistContractItems:
                'Para a regra selecionada não existem items de contrato de nenhum cliente!',
              back: 'Deseja voltar para a listagem?',
            },
            warnings: {
              bag: 'Com base no veículo selecionado foram reservadas <1>{{amount}} bags</1> da cadência do dia selecionado.',
              smallBag:
                'Com base no veículo selecionado foram reservadas <1>{{amount}} toneladas</1> da cadência do dia selecionado.',
              timeout:
                'Caso não finalize a formação de carga em <1>{{timeout}}</1> sua reserva será liberada.',
              contractItems:
                'Na coluna <strong>Retirar</strong> da tabela de <strong>Itens do contrato</strong> informe a quantidade que deseja retirar de cada cultivar e clique em <strong>Adicionar itens a carga.</strong>',
              maxCapacity:
                'Verifique se sua carga não ultrapassa a capacidade máxima do seu veículo. Cargas maiores do que a capacidade máxima excedente serão retornadas ao contrato aguardando uma nova formação de carga.',
            },
            renderRemoveButton: 'Remover',
            renderRemoveAllButton: 'Remover tudo',
            modal: 'A carga deve cobrir 30% da capacidade máxima do veículo!',
            customerRemainingAmount: 'Limite diário',
            percentages: {
              smallBags: 'sacas',
              bags: 'bags',
              tons: 'toneladas',
            },
            filter: {
              weightOverTheLimit:
                'Peso total acima do limite do usuário ou do caminhão',
              bagsOverTheLimit:
                'Bags totais acima do limite do usuário ou do caminhão',
            },
            extraButtons: {
              maximumCapacity: 'Quantidade máxima: ',
              maximumCapacityPerCompany: 'Quantidade máxima da regra: ',
            },
            customerLimitExceeded: {
              confirm: 'Sim',
              cancel: 'Não',
              exceededWeight:
                'Sua ação excederá o a capacidade máxima diária do(a) cliente ',
              continue: ' Deseja continuar?',
              warning: ' Atenção!',
            },
          },
          stepThree: {
            cardTitles: {
              observation: 'Observações sobre carga',
              driver: 'Dados da transportadora/motorista',
            },
            labels: {
              observation: 'Se necessário faça observações sobre a carga',
              shippingCompany: 'Transportadora',
              driver: 'Nome do motorista',
              vehicle: 'Modelo veículo',
              driverDocument: 'CPF do motorista',
              licensePlate: 'Placa veículo',
              driverObservation: 'Observações sobre o motorista',
              phoneNumber: 'Telefone',
              email: 'Email',
            },
            placeholders: {
              observation: 'Observação',
              driver: 'Informe o nome',
              driverDocument: 'Informe o CPF',
              licensePlate: 'Informe a placa',
              driverObservation: 'Infome observações sobre o motorista',
              select: 'Selecionar',
              phoneNumber: 'Informe o número de celular',
              email: 'Informe o email',
              search: 'Pesquisar itens da tabela',
            },
            contractItems: {
              columns: {
                client: 'Cliente',
                stateRegister: 'Inscrição estadual',
                contract: 'Contrato',
                seedType: 'Tipo',
                id: 'Item ID',
                harvest: 'Cultivar',
                sieve: 'Peneira',
                category: 'Cat',
                package: 'Embalagem',
                tsi: 'TSI',
                weight: 'Peso Est. Total',
                contractAmount: 'Contrato',
                pickupAmount: 'Para retirar',
                pickup: 'Retirar',
              },
            },
            createdLoads: {
              columns: {
                organization: 'Org.',
                order: 'Ordem',
                allocated: 'QTD alocada',
                actions: 'Ações',
              },
            },
            dropdown: {
              all: 'Todos',
            },
            buttons: {
              filter: 'Filtrar',
              clearFilter: 'Limpar',
              addItems: 'Adicionar itens a carga',
            },
            shippingCompany: {
              createError: 'Não foi possível criar a transportadora',
              shippingCompanyNotFound: 'Transportadora não encontrada',
              driverNotFound: 'Motorista não encontrado',
              labels: {
                cnpj: 'CNPJ',
                companyName: 'Nome da transportadora',
                tradeName: 'Nome fantasia',
                email: 'E-mail',
                phoneNumber: 'Telefone',
              },
              placeholders: {
                cnpj: 'Informe o CNPJ',
                companyName: 'Selecione a transportadora',
                tradeName: 'Informe o nome fantasia da transportadora',
                email: 'Informe o email',
                phoneNumber: 'Informe o telefone',
              },
            },
            modal: 'A carga deve cobrir 30% da capacidade máxima do veículo!',
            informDriverLater: 'Informar transportadora/motorista depois',
            wrongVehicleWarning:
              'Atenção! O veículo escolhido é diferente do selecionado na carga',
            createLoadSuccess: 'Carga formada com sucesso',
          },
          validData: {
            invalidCellphone: 'Telefone inválido',
            invalidCpf: 'CPF inválido',
            invalidCnpj: 'CNPJ inválido',
            invalidEmail: 'Email inválido',
            invalidLicensePlate: 'Placa inválida',
          },
          viewLoad: {
            backToListing: 'Voltar',
            cancelBtn: 'Cancelar',
            synchronizeTruckLoadBtn: 'Sincronizar',
          },
        },
        authorization: {
          labels: {
            authorizedName: 'Autorizado por',
            unauthorizedName: 'Usuário',
            document: 'CPF',
            phoneNumber: 'Celular',
            email: 'E-mail',
            date: 'Data',
          },
          input: {
            label: 'Informar senha para autorização:',
            error: 'Senha obrigatória para autorização!',
            placeholder: 'Senha',
          },
          button: {
            authorize: 'Autorizar',
            authorizeLater: 'Autorizar depois',
          },
        },
        viewLoad: {
          title: 'Detalhes da carga',
          subtitle: 'Visualize informações sobre a carga',
          updateSuccess: 'Atualização concluida com sucesso',
          status: {
            WAITING_DRIVER: 'Aguardando motorista',
            WAITING_RELEASE: 'Aguardando comercial',
            WAITING_ACTIONS: 'Aguardando ações',
            AUTHORIZED_LOAD: 'Carregamento liberado',
            LOADED: 'Carregado',
            CANCELED: 'Carregamento cancelado',
            COMPLETED: 'Carregamento completo',
            EXPIRED: 'Expirado',
            LOADING: 'Carregando',
          },
          cardTitles: {
            descriptions: 'Dados básicos da carga',
            load: 'Carga formada',
            driver: 'Dados da transportadora/motorista',
            authorization: 'Autorizado',
            authorize: 'Autorizar carga',
            synchronization: 'Sincronização de carga',
          },
          mustFinishUpdate:
            'Você deve finalizar a edição em andamento para sincronizar a carga',
          synchronizing:
            'A carga está sendo sincronizada, aguarde um momento e recarregue a página',
          synchronizeTruckLoadBtn: 'Sincronizar',
          cancellationReasonsTable: {
            reasons: 'Razões de recusa',
          },
        },
        cancel: {
          title: 'Cancelar carga',
          info: 'Cuidado! Você está prestes a cancelar a carga', // info is completed with the truck load name in page
          confirmMessage:
            'Essa ação não poderá ser desfeita, tem certeza que deseja prosseguir?',

          // buttons
          cancelButton: 'Voltar',
          confirmButton: 'Confirmar',

          errors: {
            message: 'Não foi possível cancelar a carga!',
          },
          success: {
            message: 'Carga cancelada com sucesso',
          },
        },
        pdfDownload: {
          errors: {
            message:
              'Não é possível fazer o download do PDF da ordem de carregamento enquanto a carga não for carregada',
          },
        },
        expiredDriver: { time: 'Tempo', expired: 'expirado' },
      },

      myCompany: {
        title: 'Minha empresa',
      },
      scheduleRules: {
        title: 'Regras Agendamento',

        insertAValidValue: 'Insira um valor válido',

        ruleSuccess: 'Dados cadastrados com sucesso',
        editRuleSuccess: 'Dados editados com sucesso',
        alertMessageToUnities:
          'Caso a unidade de medida for sacas, o peso de cada saca deve ser inserido. Caso seja bags, você pode optar por enviar ou não o peso de cada bag. Esses valores serão utilizados para o cálculo de bags e sacas comportadas por cada caminhão.',
        alertMessageToCapacities:
          'A capacidade máxima valerá para todas empresas que fizerem um agendamento referente á essa regra, capacidades exclusivas para clientes poderão ser cadastradas no fim do formulário abaixo.',
        subtitle: 'Visualize e configure regras',

        whiteSeeds: 'Sementes brancas',
        tsiSeeds: 'Sementes com TSI',
        seedsTreatment: 'Tratamento de sementes',

        // filters
        searchLabel: 'Pesquisar',
        cultureLabel: 'Cultura',
        locationLabel: 'Local de embarque',

        phInputSearch: 'Pesquisar item da tabela',
        phCultureFilter: 'Selecione uma cultura',
        phPickupLocFilter: 'Selecione um local de embarque',

        clearFilter: 'Limpar filtro',
        filter: 'Filtrar',

        // table
        cardTitle: 'Regras cadastradas para agendamento',
        newRuleBtn: 'Nova regra',

        seedCulture: 'Cultura',
        pickUpLocation: 'Local de embarque',
        rulePeriodDate: 'Período da regra',
        releaseSubmissionDate: 'Data envio da liberação',
        schedulingStart: 'Ini. Agendamento',
        maximumPerDay: 'Máxima por dia',
        maximumPerCompany: 'Máxima por empresa',
        tsiTimeLimit: 'TSI',
        actions: 'Ações',

        pickUpLocationMessage: 'Local de embarque das sementes',
        rulePeriodDateMessage: 'Período em que a regra está vigente',
        releaseSubmissionDateMessage:
          'Data em que será informado ao cliente o início de agendamento',
        schedulingMessage: 'Data de inicio de agendamento',
        maximumPerDayMessage:
          'Quantidade máxima de carga por dia dentro da regra',
        maximumPerCompanyMessage:
          'Quantidade máxima de carga por empresa dentro da regra',
        tsiTimeLimitMessage: 'Tempo limite do TSI (em dias)',

        ruleSettings: {
          title: 'Configurações',
          subtitle: 'Gerencie as configurações gerais do seu agendamento',
          percentageTolerance: 'Porcentagem de tolerância',
          defaultPercentageRuleLabel: 'Regra geral de tolerância',
          toleranceRulePlaceholder: 'Informe o %',
          operation: 'Funcionamento',
          checkbox: {
            enabled: 'Habilitar regra geral de tolerância',
          },
          alerts: {
            example: 'Exemplo: ',
            explanationMessage:
              'A regra de tolerância para agendamento de cargas permite que o cliente ultrapasse seu limite diário de agendamento caso este tenha separado todos os seus itens para o respectivo dia e ainda exista uma quantidade X para preencher a capacidade do veículo selecionado. Para isso o cliente deve ter itens disponíveis para retirada em seu contrato e a sementeira deve ter itens disponíveis para retirada no mesmo dia',
            exampleMessage:
              'João tinha 50 bags de limite restante para o dia, o caminhão selecionado tinha capacidade de 64 bags, ele separou suas 50 bags disponíveis e esgotou seu limite diário, porém ainda haviam bags para retirada em seu contrato e a sementeira possuía bags disponíveis naquele dia, logo João pôde retirar 64 bags de seu contrato pois o seu limite restante diário contemplava mais que 70% da capacidade do caminhão.',
            optionalRuleAlert: {
              start: 'A regra é opcional.',
              content: 'Caso deseje desativá-la, insira a porcentagem como 0',
            },
          },
          feedbacks: {
            success: { operationSuccess: 'Operação efetuada com sucesso' },
            errors: {
              percentageOverLimit:
                'A porcentagem da regra não pode ultrapassar 100%',
              percentageUnderLimit:
                'A porcentagem da regra deve ser maior que 0%',
              treatmentPeriodTsi:
                'O período de tratamento de sementes com TSI deve ser maior que o período de tratamento de sementes brancas',
            },
          },
          tsiExplainMessage:
            'Período de carência (em dias) para sementes com e sem tratamento de TSI. Estes valores serão utilizados para preencher automaticamente os campos de carência durante a criação de novas regras de agendamento.',
          tsiCardTitle: 'Regra geral para tempo de tratamento',
          whiteSeedTreatment: 'Tratamento sementes brancas',
          tsiSeedTreatment: 'Tratamento sementes com TSI',
          tsiPlaceholder: 'Tempo para tratamento',
          confirmButton: 'Confirmar',
        },
        newRules: {
          title: 'Cadastrar nova regra',
          subtitle: 'Defina as regras para seus agendamentos',
          popoverWarning:
            'Se a duração do tratamento for alterada, os campos terão que ser atualizados!',
          submissionDatePopover:
            'Como a data de envio de liberação influencia em outros campos, caso esta seja atualizada, os campos também terão que ser atualizados novamente.',
          basicInformation: {
            title: 'Informações básicas',
            cultureLabel: 'Cultura',
            locationLabel: 'Local de embarque',
            dateReleaseSentLabel: 'Data envio de liberação',
            startSchedulingLabel: 'Início agendamento',
            startShipmentLabel: 'Início - embarque',
            endShipmentLabel: 'Fim - embarque',
            limitTimeToInformLoadLabel: 'Tempo limite - carga',
            limitTimeToInformDriverLabel: 'Nomeação do motorista',
            limitTimeToInformLoadMessage:
              'Tempo máximo de reserva de itens na tela de formação de carga ',
            limitTimeToInformDriverMessage:
              'Limite de tempo antecedente ao dia do embarque para vincular o motorista à carga',
            rulePeriodLabel: 'Período da regra',
          },
          unityInformation: {
            unityTitle: 'Informações da unidade',
            tsiTitle: 'TSI',
            capacityTitle: 'Capacidades',
            measurementUnityLabel: 'Unidade de medida',
            whiteSeedsTreatment: 'Tratamento de sementes brancas',
            tsiSeedsTreatment: 'Tratamento de sementes com TSI',
            maximumCapacityForDayLabel: 'Capacidade MAX dia embarque',
            maximumCapacityForOrganizationLabel: 'Capacidade MAX dia cliente',
            tooltipGeneralCapacity:
              'Este campo deve conter a capacidade máxima por dia para cadências desta cultura e local de embarque',
            generalHours: 'Horários',
            bagsOption: 'Bags',
            smallBagOption: 'Sacas',
            weight: 'Peso estimado',
            smallBagWeightPlaceholder: 'Ex: 50',
            addonAfterKG: 'KG',
            addonAfterBag: 'Bags',
            addonAfterSmallBag: 'Sacas',
          },
          cadences: {
            title: 'Cadências e horários',
            tradeWeek: 'Segunda a sexta', //se refere a semana toda
            saturday: 'Sábado',
            sunday: 'Domingo',
            quantity: 'Quantidade',
            schedule: 'Horários',
            quantityPlaceholder: 'Ex: 50',
          },
          tsiTooltipTitle:
            'Este campo se refere ao tratamento de TSI e poderá ser alterado posteriormente',
          alertOfDisableDates:
            'Todos os dias anteriores a data de início de agendamento são automaticamente desabilitados',
          errOfDailyCapacities:
            'A capacidade diária deve ser maior do que a cedida por empresa',
          checkboxLabel: 'Informar capacidade máxima por cliente',
          clientLabel: 'Cliente',
          rulePeriodLabel: 'Período da regra',
          maximumCapacityPerCompanyLabel: 'Capacidade máxima por empresa',
          buttonContent: 'Adicionar a empresa lista',
          CRweightOverLimit:
            'A capacidade do cliente não pode ultrapassar a capacidade máxima diária da sementeira',
          cadencesTable: {
            organization: 'Empresa',
            rulePeriod: 'Período da regra',
            capacity: 'Capacidade',
            actions: 'Ações',
          },
          calendarTitle: 'Calendário de cadências',
          defaultValueSelect: 'Selecione',
          addonAfterHours: 'Horas',
          addonAfterDays: 'Dias',
          hoursInputMessages: 'Este campo deve receber apenas números inteiros',
          quantityInHours: 'Quantidade em horas',
          quantityInDays: 'Quantidade em dias',
          capacityPerCompanyMessage:
            'É necessário haver uma capacidade máxima por dia para habilitar esse campo',

          errorMessageCadenceQuantity:
            'O valor deve ser inteiro e maior que 0 (zero)',

          // error dates
          schedulingStartDateError:
            'O início de agendamento não pode ser menor que a data de liberação',
          startDateError:
            'O início de embarque não pode ser menor que o início de agendamento',
          endDateError: 'O final do embarque não pode ser menor que o início',
          workingDayAlert:
            'Não existem dias úteis dentro do período selecionado, para adicionar finais de semana clique no checkbox abaixo',
        },
        editRules: {
          title: 'Editar regra',
          subtitle: 'Edite as regras dos seus agendamentos',
          basicInformation: {
            title: 'Informações básicas',
            cultureLabel: 'Cultura',
            locationLabel: 'Local de embarque',
            dateReleaseSentLabel: 'Data envio de liberação',
            startSchedulingLabel: 'Início agendamento',
            startShipmentLabel: 'Início embarque',
            limitTimeToInformLoadLabel: 'Tempo limite - carga',
            limitTimeToInformDriverLabel: 'Nomeação do motorista',
            limitTimeToInformLoadMessage:
              'Tempo máximo de reserva de itens na tela de formação de carga ',
            limitTimeToInformDriverMessage:
              'Limite de tempo antecedente ao dia do embarque para vincular o motorista à carga',
            rulePeriodLabel: 'Período da regra',
          },
          unityInformation: {
            unityTitle: 'Informações da unidade',
            tsiTitle: 'TSI',
            capacityTitle: 'Capacidades',
            measurementUnityLabel: 'Unidade de medida',
            executionTimeOfTsiLabel: 'Tempo de execução do TSI',
            maximumCapacityForDayLabel: 'Capacidade MAX dia embarque',
            maximumCapacityForOrganizationLabel: 'Capacidade MAX dia cliente',
            generalHours: 'Horários',
            bagsOption: 'Bags',
            smallBagOption: 'Sacas',
            weight: 'Peso estimado',
            smallBagWeightPlaceholder: 'Ex: 50',
            addonAfterKG: 'KG',
          },
          cadences: {
            title: 'Cadências e horários',
            tradeWeek: 'Segunda a sexta', //se refere a semana toda
            saturday: 'Sábado',
            sunday: 'Domingo',
            quantity: 'Quantidade',
            schedule: 'Horários',
            quantityPlaceholder: 'Ex: 50',
          },
          disableCadencesTooltip:
            'A cadência não pode ser deletada pois está sendo usada em alguma carga',
          featuredCadencesWarning:
            'As cadências que estão em destaque não podem ser deletadas pois estão sendo usadas em alguma carga',
          checkboxLabel: 'Informar capacidade máxima por cliente',
          clientLabel: 'Cliente',
          rulePeriodLabel: 'Período da regra',
          maximumCapacityPerCompanyLabel: 'Capacidade máxima por empresa',
          buttonContent: 'Adicionar a empresa lista',
          cadencesTable: {
            organization: 'Empresa',
            rulePeriod: 'Período da regra',
            capacity: 'Capacidade',
            actions: 'Ações',
          },
          calendarTitle: 'Calendário de cadências',
          defaultValueSelect: 'Selecione',
          addonAfterHours: 'Horas',
          hoursInputMessages: 'Este campo deve receber apenas números inteiros',
          hoursQuantityPlaceholder: 'Quantidade em horas',
          errorMessageCadenceQuantity:
            'O valor deve ser inteiro e maior que 0 (zero)',
          confirmRuleCard: {
            title: 'Edição de cadências',
            modalTitle: 'Edição de regra do cliente',
            warningText:
              'Atenção! Você está prestes a editar um grupo de cadências, ou ainda todas as cadências desta regra. ',
            warningConfirmText:
              'Caso confirme, esses dados serão sobrepostos e não poderão ser recuperados!',
            confirmText: 'Tem certeza que deseja prosseguir?',
          },
          // placeholders
          startDate: 'Data inicial',
          endDate: 'Data inicial',
          startTime: 'Horário inicial',
          endTime: 'Horário final',
          selectDate: 'Selecione a data',
          feedbacks: {
            deletedCustomerRule: 'Regra de cliente excluída com sucesso',
          },
        },
        createCadenceBtn: 'Criar cadência',
        customerRuleModals: {
          delete: {
            title: 'Atenção!',
            confirmDeletion: 'Tem certeza que deseja excluir esta regra?',
            warningOfActionDelete: 'Esta ação não pode ser desfeita',
            ruleAlreadyUse: {
              firstPart: 'Esta regra está sendo usada em ',
              secondPart: ' carga(s)!',
              warningUserOfAction:
                'Caso decida excluir não se preocupe, as cargas serão mantidas, no entanto, não será possível criar mais cargas desta regra!',
            },
          },
        },
        tooltipToEnableButton:
          'Para liberar a edição da regra as alterações de capacidades devem ser aplicadas',
        applyCadenceButton: 'Aplicar',
        applyCadenceMessage:
          'A capacidade só será adicionada ao calendário ao aplicá-la no botão abaixo!',
        phCulture: 'Selecione',
        phLocation: 'Selecione',
        clientNotificationDate: 'Data envio da liberação',
        clientNotificationDateMessage:
          'Data em que será informado ao cliente o início de agendamento',
        shipmentStart: 'In. Embarque',
        shipmentStartMessage: 'Data de início do embarque',
        editCalendarInputPlaceholder: 'Digite aqui a capacidade máxima',
        editCalendarLabelPlaceholder: 'Horários',
        confirmChangeBtn: 'Confirmar alteração',
        maxTitleDateCellContent: 'Max',
        rulePeriodAlertError: 'A data deve estar entre o período da regra',
        schedulingStartAlertError:
          'O início de agendamento deve ser maior que a data de início de liberação',

        maxCapacityPerDayError:
          'A capacidade diária da empresa deve ser maior do que a capacidade máxima diária do cliente',
        maxCapacityAlertError:
          'A capacidade máxima por empresa não pode exceder a capacidade máxima por dia',
        weekDetails: 'Finais de semana',

        // messages
        releaseSubmissionDateInfo:
          'Data em que os clientes receberão um e-mail contendo a data que eles poderão acessar o sistema para agendar.',
        schedulingStartInfo:
          'Data em que os clientes poderão acessar o sistema para agendar cargas.',
        startShipmentInfo: 'Primeiro dia do período de embarque',
        endShipmentInfo: 'Último dia do período de embarque',
      },
      settings: {
        pageTitle: 'Minha empresa',
        subtitle: 'Configure os dados da sua empresa',
        cardTitle: 'Dados da empresa',
        companyName: 'Razão social',
        companyAdress: 'Endereço da empresa',
        actions: 'Ações',
        pick_up_location: 'Local de embarque',
        pick_up_locations: 'Locais de embarque',
        mySubsidiarys: 'Minhas filiais',
        complement: 'Complemento',
        phoneNumber: 'Telefone',
        email: 'E-mail',
        contact: 'Contatos',

        // TreatmentPeriod

        treatmentTimeTsi:
          'O período de TSI deve ser maior do que o tratamento de sementes brancas',

        negativeDayError: 'Esta quantidade deve ser positiva',

        // Endereço da empresa

        title: 'Nome do local',
        zip_codes: 'CEP',
        state_name: 'Estado',
        city_name: 'Cidade',
        districts: 'Bairro',
        street: 'Logradouro',
        street_number: 'Número',
        btnEditCompany: 'Editar empresa',
        btnNewPickUpLocation: 'Novo local de embarque',
        btnNewSubsidiary: 'Nova filial',
        action: 'Ações',

        // actions

        actionEdit: 'Editar',
        actionDelete: 'Excluir',

        // page header

        settings: 'Configurações',
        myCompany: 'Minha empresa',

        settingsBreadcrumb: 'configurações',

        // filiais

        trade_name: 'Nome filial',
        document_number: 'CNPJ',
        company_name: 'Nome da empresa',

        //Locais de retirada - modal
        pickUpLocations: {
          title: 'Novo local de embarque',

          requiredRuleMessage: 'Este campo é obrigatório',

          localName: 'Nome do local',
          localNamePlaceholder: 'Insira o local de embarque',

          zipcode: 'CEP',
          zipcodeRuleMinSize: 'O CEP precisa ter exatamente 8 dígitos',
          zipcodePlaceholder: 'Insira o CEP',

          state: 'Estado',
          statePlaceholder: 'Selecione o estado',

          city: 'Cidade',
          cityPlaceholder: 'Selecione a cidade',

          district: 'Bairro',
          districtPlaceholder: 'Insira o bairro',

          street: 'Logradouro',
          streetPlaceholder: 'Insira o logradouro',

          streetNumber: 'Número',
          streetNumberPlaceholder: 'Insira o número',

          complement: 'Complemento',
          complementPlaceholder: 'Insira o complemento',

          cancelButton: 'Cancelar',
          confirmButton: 'Confirmar',
          submitButton: 'Criar',
        },

        newBranchCompany: {
          title: 'Nova empresa filial',

          // labels
          titleCompanyInfo: 'Informações da empresa',
          tradeName: 'Nome da empresa',
          companyName: 'Razão social',
          documentNumberCnpj: 'CNPJ',

          titleInfoAddress: 'Endereço da empresa',
          localName: 'Nome do local',
          zipCode: 'CEP',
          stateShortName: 'Estado',
          cityName: 'Cidade',
          district: 'Bairro',
          street: 'Logradouro',
          streetNumber: 'Número',

          // placeholder
          phTradeName: 'Insira o nome da filial',
          phCompanyName: 'Insira a razão social da filial',
          phDocumentNumberCnpj: 'Insira o CNPJ',

          phLocalName: 'Insira o nome do local',
          phZipCode: 'Insira o CEP',
          phStateShortName: 'Selecione o estado',
          phCityName: 'Selecione a cidade',
          phDistrict: 'Insira o bairro',
          phStreet: 'Insira o logradouro',
          phStreetNumber: 'Insira o número',

          // rule message
          rmTradeName: 'o nome da filial é obrigatório!',
          rmCompanyName: 'A razão social da filial é obrigatória!',
          rmDocumentNumberCnpj: 'O CNPJ é obrigatório!',
          rmMinSizeDocumentNumberCnpj:
            'O CNPJ precisa ter no mínimo 14 dígitos!',

          rmLocalName: 'O nome do local é obrigatório!',
          rmZipCode: 'O CEP é obrigatório!',
          rmZipCodeMinSize: 'O CEP precisa ter exatamente 8 dígitos!',
          rmStateShortName: 'O estado é obrigatório!',
          rmCityName: 'O nome da cidade é obrigatório!',
          rmDistrict: 'O bairro é obrigatório!',
          rmStreet: 'O logradouro é obrigatório!',
          rmStreetNumber: 'O número é obrigatório!',

          // buttons
          cancelButton: 'Cancelar',
          confirmButton: 'Confirmar',

          // messages
          errors: {
            message: 'Não foi possível criar a empresa filial!',
            description: 'Tente novamente ou espere um momento',
          },
          success: {
            message: 'Empresa filial criada com sucesso',
          },
        },

        editBranchCompany: {
          title: 'Editar filial',
          tradeName: 'Nome',
          companyName: 'Razão social',
          documentNumberCnpj: 'CNPJ',
          zipCode: 'CEP',
          stateShortName: 'Estado',
          cityName: 'Cidade',
          district: 'Bairro',
          street: 'Logradouro',
          streetNumber: 'Número',

          // placeholder
          phTradeName: 'Insita o nome da filial',
          phCompanyName: 'Insira a razão social da filial',
          phDocumentNumberCnpj: 'Insira o CNPJ',
          phZipCode: 'Insira o CEP',
          phStateShortName: 'Selecione o estado',
          phCityName: 'Selecione a cidade',
          phDistrict: 'Insira o bairro',
          phStreet: 'Insira o logradouro',
          phStreetNumber: 'Insira o número',

          // rule message
          rmTradeName: 'Informe o nome da filial!',
          rmCompanyName: 'Informe a razão social da filial!',
          rmDocumentNumberCnpj: 'Informe o CNPJ!',
          rmMinSizeDocumentNumberCnpj:
            'O CNPJ precisa ter no mínimo 14 dígitos!',
          rmZipCode: 'Informe o CEP!',
          rmMinSizeZipCode: 'O CEP precisa ter exatamente 8 dígitos!',
          rmStateShortName: 'Informe o estado!',
          rmCityName: 'Informe o nome da cidade!',
          rmDistrict: 'Informe o bairro!',
          rmStreet: 'Informe o logradouro!',
          rmStreetNumber: 'Informe o número!',

          // buttons
          cancelButton: 'Cancelar',
          confirmButton: 'Confirmar',

          // messages
          errors: {
            message: 'Não foi possível editar a empresa filial!',
            description: 'Tente novamente ou espere um momento',
          },
          success: {
            message: 'Empresa filial atualizada com sucesso',
          },
        },
        editPickUpLocation: {
          title: 'Editar local de embarque',
          name: 'Nome do local',
          complement: 'Complemento',
          tradeName: 'Nome',
          companyName: 'Razão social',
          documentNumberCnpj: 'CNPJ',
          zipCode: 'CEP',
          stateShortName: 'Estado',
          cityName: 'Cidade',
          district: 'Bairro',
          street: 'Logradouro',
          streetNumber: 'Número',

          // placeholders
          phInfoName: 'Informe o nome',
          phZipCode: 'informe o CEP',
          phStateShortName: 'Informe o estado',
          phCityName: 'Informe a cidade',
          phDistrict: 'Informe o bairro',
          phStreet: 'Informe o logradouro',
          phStreetNumber: 'Informe o número',
          phComplement: 'Informe o complemento',

          // rules messages
          rmInfoName: 'Nome do local de embarque obrigatório!',
          rmStateShortName: 'Nome do estado obrigatório!',
          rmZipCode: 'CEP obrigatório!',
          zipCodeRuleMinSize: 'O CEP precisa ter exatamente 8 dígitos',
          rmCityName: 'Cidade obrigatória!',
          rmDistrict: 'Bairro obrigatório!',
          rmStreet: 'Logradouro obrigatório!',
          rmStreetNumber: 'Número obrigatório!',

          // buttons
          cancelButton: 'Cancelar',
          confirmButton: 'Confirmar',

          errors: {
            message: 'Não foi possível editar o local de embarque!',
            description: 'Tente novamente ou espere um momento',
          },
          success: {
            message: 'Local de embarque atualizado com sucesso',
          },
        },
        deletePickUpLocation: {
          title: 'Excluir local de embarque',
          info: 'Cuidado! Você está prestes a excluir o local de embarque', // info é completada na tela com o nome do local
          warning:
            'Ao confirmar, o dado será perdido e você não conseguirá recuperá-lo!',
          confirmMessage: 'Tem certeza que deseja prosseguir?',

          // buttons
          cancelButton: 'Cancelar',
          confirmButton: 'Confirmar',

          errors: {
            message: 'Não foi possível excluir o local de embarque!',
          },
          success: {
            message: 'Local de embarque excluido com sucesso',
          },
        },
        deleteBranchCompany: {
          title: 'Excluir empresa filial',
          info: 'Cuidado! Você está prestes a excluir a empresa filial', // info é completada na tela com o nome da empresa
          warning:
            'Ao confirmar, o dado será perdido e você não conseguirá recuperá-lo!',
          confirmMessage: 'Tem certeza que deseja prosseguir?',

          // buttons
          cancelButton: 'Cancelar',
          confirmButton: 'Confirmar',

          errors: {
            message: 'Não foi possível excluir a empresa filial!',
          },
          success: {
            message: 'Empresa filial excluida com sucesso',
          },
        },
        deleteScheduleRule: {
          title: 'Excluir regra de agendamento',
          info: 'Cuidado! Você está prestes a excluir uma regra de agendamento',
          warning:
            'Ao confirmar, o dado será perdido e você não conseguirá recuperá-lo!',
          confirmMessage: 'Tem certeza que deseja prosseguir?',

          // buttons
          cancelButton: 'Cancelar',
          confirmButton: 'Confirmar',

          errors: {
            message: 'Não foi possível excluir a regra de agendamento!',
          },
          success: {
            message: 'Regra de agendamento excluida com sucesso',
          },
        },
        tutorials: {
          title: 'Tutoriais do sistema',
          subtitle: 'Veja como o sistema funciona',
          totalVideos: 'Total de vídeos: ',
          searchVideoPlaceholder: 'Pesquisar vídeo',
        },
      },
      editCompany: {
        title: 'Editar empresa',
        subtitle: 'Configure os dados da sua empresa',
        companyData: 'Dados da empresa',
        tradeName: 'Nome da empresa',
        companyName: 'Razão social',
        cnpj: 'CNPJ',
        drawLogo: 'Arraste o logo da empresa',
        or: 'ou',
        selectFile: 'Selecione um arquivo',
        contact: 'Contato',
        phoneNumber: 'Telefone',
        email: 'Email',
        adress: 'Endereço da empresa',
        zipCode: 'CEP',
        stateName: 'Estado',
        cityName: 'Cidade',
        districts: 'Bairro',
        street: 'Logradouro',
        streetNumber: 'Número',
        // placeholders
        phInfoName: 'Informe o nome',
        phcompanyName: 'Informe a razão social',
        phCnpj: 'Informe o CNPJ',
        phPhone: 'Telefone da empresa',
        phEmail: 'Informe o email',
        phZipCode: 'informe o CEP',
        phCity: 'Informe a cidade',
        phDistrict: 'Informe o bairro',
        phStreet: 'Informe o logradouro',
        phStreetNumber: 'Informe o número',
        // rules messages
        rmInfoName: 'Informe o nome da empresa!',
        rmcompanyName: 'Informe a razão social!',
        rmCnpj: 'Informe o CNPJ!',
        rmPhoneNumber: 'Informe o telefone da empresa!',
        rmStateName: 'Informe o nome do estado!',
        rmEmail: 'Informe um email válido!',
        rmZipCode: 'Informe o CEP!',
        rmMinSizeZipCode: 'O CEP precisa ter exatamente 8 dígitos!',
        rmCityName: 'Informe a cidade!',
        rmDistricts: 'Informe o bairro!',
        rmStreet: 'Informe o logradouro!',
        rmStreetNumber: 'Informe o número!',
        // PageHeader Routes
        first: 'Configurações',
        second: 'Minha empresa',
        third: 'Editar empresa',
        // title: 'Página das Configurações',
        pageTitle: 'Minha empresa',
        cardTitle: 'Dados da empresa',
        companyAdress: 'Endereço da empresa',
        actions: 'Ações',
        pick_up_location: 'Local de embarque',
        pick_up_locations: 'Locais de embarque',
        mySubsidiarys: 'Minhas filiais',
        // Endereço da empresa
        zip_codes: 'CEP',
        state_name: 'Estado',
        city_name: 'Cidade',
        street_number: 'Número',
        btnEditCompany: 'Editar empresa',
        btnNewPickUpLocation: 'Novo local de embarque',
        btnNewSubsidiary: 'Nova filial',
        action: 'Ações',
        // actions
        actionEdit: 'Editar',
        actionDelete: 'Excluir',
        // page header
        firstLevel: 'Primeira página',
        secondLevel: 'Segunda página',
        thirdLevel: 'Terceira página',
        // filiais
        trade_name: 'Nome filial',
        document_number: 'CNPJ',
        company_name: 'Nome da empresa',
        // botões de confirmação
        cancelBtn: 'Cancelar',
        confirmBtn: 'Confirmar',
        errors: {
          message: 'Não foi possível editar a empresa!',
          description: 'Tente novamente ou espere um momento',
        },
        success: {
          message: 'Empresa atualizada com sucesso',
        },
        validateCnpj: 'O CNPJ precisa ter no mínimo 14 dígitos',
      },
      reports: {
        pageheader: {
          title: 'Relatórios',
          subtitle: 'Controle e faça downloads dos relatórios do sistema',
        },
        table: {
          title: 'Relatórios do sistema',
          columns: {
            type: 'Tipo',
            loadingOrder: 'O.C',
            status: 'Status',
            downloads: 'Downloads',
          },
          content: {
            loadingOrder: 'Ordem de carregamento',
            status: {
              processing: 'Processando...',
              processed: 'Concluído',
            },
            downloadBtn: 'Baixar PDF',
          },
        },
        errors: {
          message: 'Não foi possível buscar os relatórios!',
        },
      },
      icons: {
        title: 'Página dos Ícones',
      },
      validateEmail: {
        notification: {
          success: {
            message: 'Email verificado com sucesso!',
            description:
              'O seu email foi verificado com sucesso! Por favor, prossiga com o cadastro',
          },
          error: {
            message: 'Erro ao verificar email...',
            description: 'O link que você acessou está expirado ou é inválido',
          },
        },
      },
      registerCompany: {
        shipperTitle: 'Cadastrar Embarcador',
        carrierTitle: 'Cadastrar Transportadora',
        subtitle: 'Escolha qual o seguimento da sua empresa',
        sections: {
          companyData: {
            title: 'Dados da empresa',
            fields: {
              labels: {
                companyType: 'Tipo de empresa',
                hasFleet: 'Possui frota própria?',
                fleetQuantity: 'Tamanho da frota',
                logo: 'Clique ou arraste o arquivo para fazer upload',
                logoDescription:
                  'O arquivo deve possuir no máximo 5 MB e deve ser do tipo .png, .jpg ou .jpeg',
              },
              placeholders: {
                companyType: 'Tipo da sua empresa',
                fleetQuantity: 'Informe a quantidade de caminhões',
              },
              rules: {
                companyFunctionNotMatch:
                  'Empresa cadastrada para outro segmento',
                companyTypeNotMatch:
                  'O seu tipo de empresa é incompatível com esta',
                companyStatusBlocked:
                  'Empresa indisponível para vincular novos dependentes',
                tradeNameLength:
                  'O nome fantasia deve ter menos de 100 caracteres',
                companyNameLength:
                  'A razão social deve ter menos de 100 caracteres',
                minimumFleetQuantity:
                  'O tamanho mínimo da frota é de 1 caminhão',
                maximumFileSize: 'O arquivo deve ter no máximo 5 MB',
                accepCodetedFileTypes:
                  'Somente arquivos .jpg, .jpeg e .png são aceitos',
              },
            },
            parent: {
              fields: {
                labels: {
                  companyDocument: 'CNPJ ou CPF',
                  companyTradeName: 'Nome fantasia',
                  tradeName: 'Razão social',
                },
                placeholders: {
                  companyDocument: 'Seu CNPJ ou CPF',
                  companyTradeName: 'Nome fantasia da sua empresa',
                  tradeName: 'Razão social da sua empresa',
                },
                rules: {
                  invalidCompanyDocument: 'CNPJ ou CPF em uso',
                },
              },
              notifications: {
                preRegisteredCompany: {
                  message: 'Empresa pré-cadastrada',
                  description:
                    'Alguns dados já foram preenchidos por alguma filial. Por favor, preencha os dados restantes para concluir o cadastro',
                },
              },
            },
            branch: {
              fields: {
                labels: {
                  companyDocument: 'CNPJ da filial',
                  companyTradeName: 'Nome fantasia',
                  tradeName: 'Razão social',
                },
                placeholders: {
                  companyDocument: 'Seu CNPJ',
                  companyTradeName: 'Nome fantasia da sua empresa',
                  tradeName: 'Razão social da sua empresa',
                },
                rules: {
                  invalidCompanyDocument: 'CNPJ em uso',
                  sameDocumentNumber:
                    'O seu CNPJ não pode ser igual ao da Matriz',
                },
              },
              notifications: {
                preRegisteredCompany: {
                  message: 'Empresa pré-cadastrada',
                  description:
                    'Alguns dados já foram preenchidos por alguma unidade. Por favor, preencha os dados restantes para concluir o cadastro',
                },
              },
            },
            unit: {
              fields: {
                labels: {
                  companyDocument: 'CNPJ da unidade',
                  companyTradeName: 'Nome fantasia',
                  tradeName: 'Razão social',
                },
                placeholders: {
                  companyDocument: 'Seu CNPJ',
                  companyTradeName: 'Nome fantasia da sua empresa',
                  tradeName: 'Razão social da sua empresa',
                },
                rules: {
                  invalidCompanyDocument: 'CNPJ em uso',
                },
              },
            },
            modal: {
              title: {
                details: 'Dados da Matriz',
                createParent: 'Cadastrar Matriz',
                createBranchOrUnit: 'Cadastrar Matriz ou Filial',
              },
              fields: {
                rules: {
                  tradeNameLength:
                    'O nome fantasia deve ter menos de 100 caracteres',
                  companyNameLength:
                    'A razão social deve ter menos de 100 caracteres',
                },
              },
            },
          },
          companyAddress: {
            title: 'Endereço da empresa',
            fields: {
              labels: {
                zipCode: 'CEP',
                state: 'UF',
                city: 'Cidade',
                address: 'Logradouro',
                district: 'Bairro',
                addressNumber: 'Número',
                complement: 'Complemento/Observação',
              },
              placeholders: {
                zipCode: 'Seu CEP',
                state: 'UF',
                city: 'Cidade onde fica a empresa',
                address: 'Logradouro onde fica a empresa',
                district: 'Bairro onde fica a empresa',
              },
              rules: {
                addressMaxLength:
                  'O logradouro deve ter menos de 100 caracteres',
                districtMaxLength: 'O bairro deve ter menos de 100 caracteres',
                numberMaxLength: 'O número deve ter menos de 10 caracteres',
                complementMaxLength:
                  'O complemento deve ter menos de 100 caracteres',
              },
            },
          },
          companyContact: {
            title: 'Contato da empresa',
            fields: {
              labels: {
                email: 'E-mail',
                phoneNumber: 'Telefone',
              },
              placeholders: {
                email: 'E-mail da empresa',
                phoneNumber: 'Telefone da empresa',
              },
            },
          },
          carrierPolicies: {
            title: 'Apólices',
            fields: {
              labels: {
                expiresIn: 'Validade',
                name: 'Nome',
                logo: 'Clique ou arraste o arquivo para fazer upload',
                logoDescription:
                  'O arquivo deve possuir no máximo 5 MB e deve ser do tipo .png, .jpg, .jpeg ou .pdf',
              },
              placeholders: {
                name: 'Ex. apólice de seguro',
                expiresIn: 'dd/mm/aa',
              },
              rules: {
                maximumFileSize: 'O arquivo deve ter no máximo 5 MB',
                aczipCodetedFileTypes:
                  'Somente arquivos .jpg, .jpeg, .png e .pdf são aceitoalerts',
                nameLength:
                  'O nome do arquivo deve ter menos de 100 caracteres',
                maximumFileAmount: {
                  message: 'Limite de arquivos atingido',
                  description: 'O número máximo de arquivos é de 10',
                },
              },
            },
            fileList: {
              title: 'Arquivos das apólices',
              tooltips: {
                remove: 'Remover',
                preview: 'Visualizar',
                retry: 'Reenviar',
              },
            },
            action: {
              addPolicy: 'Adicionar apólice',
            },
          },
          carrierCompanyOperationAreas: {
            title: 'Área de atuação',
          },
        },
        action: {
          cancel: 'Cancelar',
          submit: 'Criar conta',
        },
        confirm: {
          cancel: 'Você realmente deseja cancelar o cadastro?',
        },
      },
      createPassword: {
        title: 'Segurança',
        description: 'Cadastre e confirme uma senha segura para sua conta',
        fields: {
          labels: {
            password: 'Senha',
            confirmPassword: 'Confirmar senha',
          },
          placeholders: {
            password: 'Informe uma senha válida',
            confirmPassword: 'Confirme sua senha',
          },
          rules: {
            passwordNotMatch: 'As senhas não são iguais',
            passwordMinLength: 'A senha deve ter no mínimo 6 caracteres',
            passwordMaxLength: 'A senha deve ter no máximo 128 caracteres',
            differentPassword: 'As senhas não são iguais',
          },
        },
        action: {
          cancel: 'Cancelar',
          submit: 'Confirmar',
        },
        confirm: {
          cancel: 'Você realmente deseja cancelar o cadastro?',
        },
      },
      termsOfUse: {
        subtitle: 'Leia e analise os termos de uso',
        carrier: {
          title: 'Termos de Uso e Consentimento do Transportador',
        },
        shipper: {
          title: 'Termos de Uso e Consentimento do Embarcador',
        },
        fields: {
          labels: {
            accepCodetTerms: 'Eu li e concordo com os termos de uso',
          },
        },
        action: {
          cancel: 'Não aceito',
          submit: 'Concordar e continuar',
          return: 'Voltar',
        },
        confirm: {
          cancel: 'Você realmente deseja recusar os termos?',
        },
      },
      sync: {
        labels: {
          syncAll: 'Atualizar',
          syncCustomers: 'Sincronizar clientes',
          syncContracts: 'Sincronizar contratos',
          syncShippingCompanies: 'Sincronizar transportadoras',
          syncUsers: 'Sincronizar usuários',
        },
        success: {
          syncAllSuccess: 'Dados sincronizados com sucesso',
          syncCustomersSuccess: 'Clientes sincronizados com sucesso',
          syncContractsSuccess: 'Contratos sincronizados com sucesso',
          syncUsersSuccess: 'Usuários sincronizados com sucesso',
          syncShippingCompaniesSuccess:
            'Transportadoras sincronizadas com sucesso',
        },
        errors: {
          syncAllError: 'Erro na sincronização de dados',
        },
      },
      vehicles: {
        card: {
          title: 'Veículos',
          add: 'Criar veículo',
        },
        table: {
          vehicleModel: 'Modelo do veículo',
          weightCapacity: 'Capacidade de peso',
          bagsCapacity: 'Capacidade de bags',
          axlesAmount: 'Quantidade de eixos',
          bodywork: 'Carroceria',
          action: 'Ações',
          update: 'Editar',
          delete: 'Excluir',
          confirm: {
            cancel: 'Tem certeza que deseja cancelar esta ação?',
            delete: 'Tem certeza que deseja excluir este veículo?',
          },
        },
        modal: {
          create: 'Criar veículo',
          update: 'Editar veículo',
          vehicleModel: 'Modelo',
          weightCapacity: 'Capacidade de peso',
          bagsCapacity: 'Capacidade de bags',
          axlesAmount: 'Quantidade de eixos',
          bodywork: 'Carroceria',
          errors: {
            weightCapacityMinValue: 'O peso deve ser positivo',
            bagsCapacityMinValue: 'A quantidade de bags deve ser positiva',
            axlesAmountMinValue: 'A quantidade de eixos deve ser positiva',
          },
          confirmCancel: 'Tem certeza que deseja cancelar esta ação?',
        },
        success: {
          create: 'Veículo criado com sucesso!',
          update: 'Veículo editado com sucesso!',
          delete: 'Veículo excluido com sucesso!',
        },
        alerts: {
          listingAlert: {
            description:
              'A listagem abaixo mostra apenas os veículos que sua organização criar. Os veículos padrões do sistema não serão listados aqui, nem podem ser alterados.',
          },
        },
      },
    },
    enums: {
      reasonType: {
        COMPANY_DATA: 'Dados da empresa',
        COMPANY_ADDRESS: 'Endereço da empresa',
        COMPANY_CONTACT: 'Contato da empresa',
        COMPANY_POLICIES: 'Apólices',
        COMPANY_OPERATION: 'Áreas de atuação',
        OTHER: 'Outro',
      },
      companyType: {
        parent: 'Matriz',
        branch: 'Filial',
        unit: 'Unidade',
      },
      companyFunction: {
        carrier: 'Transportadora',
        shipper: 'Embarcador',
      },
      userStatus: {
        NOT_ACTIVATED: 'Inativo',
        NOTIFIED: 'Notificado',
        WAITING_ACTIVATION: 'Aguardando ativação',
        ACTIVATED: 'Ativado',
      },
      customerStatus: {
        NOT_ACTIVATED: 'Inativo',
        NOTIFIED: 'Notificado',
        WAITING_ACTIVATION: 'Aguardando ativação',
        ACTIVATED: 'Ativado',
      },
    },
    errors: {
      default: {
        message: 'Não foi possível concluir a operação!',
        description:
          'Recarregue a página e tente realizar a ação novamente, caso o erro ocorra novamente entre em contato com nosso suporte: suporte@suporte.com.br',
      },
      invalidToken: {
        message: 'Token inválido',
        description:
          'Realize um novo login em nossa plataforma para continuar utilizando o sistema',
      },
      // User errors
      1: {
        message: 'Usuário não encontrado',
        description: '',
      },
      2: {
        message: 'Tipo do usuário não encontrado',
        description: '',
      },
      3: {
        message: 'Este email já está cadastrado!',
        description: '',
      },
      4: {
        message: 'Permissão de usuário não encontrada',
        description: '',
      },
      5: {
        message: 'Este CPF já está cadastrado!',
        description: '',
      },
      6: {
        message: 'Este usuário já possui essa permissão',
        description: '',
      },
      7: {
        message: 'O usuário não possui permissão para deletar',
        description: '',
      },
      8: {
        message: 'Não é possível criar um novo usuário sem permissões',
        description: '',
      },
      9: {
        message:
          'A permissão de leitura da Home é obrigatório ao criar o novo usuário',
        description: '',
      },
      10: {
        message: 'O usuário não tem permissão para criar outro usuário',
        description: '',
      },
      11: {
        message:
          'Não é permitido que um usuário possua permissões de edição e deleção sem ter a permissão de leitura da respectiva tela',
        description: '',
      },
      12: {
        message: 'Este usuário já está ativo!',
        description: '',
      },

      // Organization errors
      100: {
        message: 'Empresa não encontrada',
        description: '',
      },
      101: {
        message: 'Este CNPJ já está cadastrado!',
        description: '',
      },
      102: {
        message: 'A empresa não tem filiais!',
        description: '',
      },
      103: {
        message: 'Tipo de arquivo não permitido!',
        description: '',
      },
      104: {
        message: 'O tamanho do arquivo excede 2mb!',
        description: '',
      },
      105: {
        message: 'Empresa é obrigatória!',
        description: '',
      },

      // Address errors
      200: { message: 'Endereço não encontrado!', description: '' },
      201: {
        message: 'Este endereço não é um local de retirada!',
        description: '',
      },
      202: {
        message: 'Endereço de retirada não encontrado',
        description: '',
      },

      // Auth errors
      300: {
        message: 'Credenciais inválidas',
        description: '',
      },
      305: {
        message: 'Login não encontrado',
        description: '',
      },
      307: {
        message: 'Login não encontrado',
        description: '',
      },
      308: {
        message: 'Não é possível criar o cliente usando este contrato',
        description: '',
      },
      309: {
        message: 'Este usuário não possui as permissões necessárias',
        description: '',
      },

      // Contract errors
      400: {
        message: 'Contrato(s) não encontrado(s)',
        description: '',
      },
      401: {
        message: 'Item(s) de contrato(s) não encontrado(s)',
        description: '',
      },

      // Rule errors
      500: {
        message: 'Regra não encontrada',
        description: '',
      },
      501: {
        message: 'As datas inseridas são inválidas',
        description: '',
      },
      502: {
        message: 'Essa fazenda já possui uma regra',
        description: '',
      },
      503: {
        message: 'Regra de cliente não encontrada',
        description: '',
      },
      504: {
        message: 'Existe outra regra com os mesmos dados em vigência',
        description: '',
      },
      506: {
        message: 'As cadências desta regra estão sendo usadas',
        description: '',
      },
      525: {
        message: 'Já existe outra regra com os mesmos dados para este período!',
        description: '',
      },

      // Seed type errors
      600: {
        message: 'Tipo de semente não encontrada',
        description: '',
      },

      // Farm erros
      700: {
        message: 'Fazenda não encontrada',
        description: '',
      },

      // Prisma errors (API)
      800: { message: 'Não foi possível concluir a operação', description: '' },

      // Customer errors
      900: { message: 'Cliente não encontrado', description: '' },

      901: {
        message: 'Cliente já ativado',
        description: '',
      },

      902: {
        message: 'É obrigatório inserir CPF ou CNPJ',
        description: '',
      },

      // Truck Load errors
      1000: {
        message: 'Carga não encontrada',
        description: '',
      },
      1001: {
        message: 'Carga já autorizada',
        description: '',
      },
      1002: {
        message: 'Cadência indisponível',
        description: '',
      },
      1003: {
        message: 'Tempo de pré agendamento esgotado',
        description: '',
      },
      1004: {
        message: 'Pré agendamento inválido',
        description: '',
      },
      1005: {
        message: 'Cadência não encontrada',
        description: '',
      },
      1006: {
        message: 'Veículo não encontrado',
        description: '',
      },
      1007: {
        message: 'Ordem de carregamento não encontrada',
        description: '',
      },
      1008: {
        message: 'Transportadora não encontrada',
        description: '',
      },
      1009: {
        message: 'Quantidade de carga requisitada é maior que o disponível',
        description: '',
      },
      1010: {
        message: 'Motorista não encontrado',
        description: '',
      },
      1011: {
        message: 'A data de agendamento não existe nos contratos disponíveis',
        description: '',
      },
      1012: {
        message: 'Carga alocada abaixo de 30% da capacidade do veículo',
        description: '',
      },
      1013: {
        message: 'Unidade de saca deve ter peso definido',
        description: '',
      },
      1014: {
        message: 'Um motorista deve ter uma transportadora e vice-versa',
        description: '',
      },
      1015: {
        message: 'Nenhuma quantidade disponível na cadência',
        description: '',
      },
      1016: {
        message: 'Pré agendamento não encontrado',
        description: '',
      },
      1030: {
        message:
          'Não é possível fazer o download desta ordem de carregamento pois a carga ainda não foi carregada',
        description: '',
      },
      1034: {
        message:
          'Não é possível gerar o PDF de ordem de carregamento de uma carga sem motorista',
        description: '',
      },
      1036: {
        message: 'O cliente não tem limite disponível para o dia solicitado',
        description: '',
      },
      1037: {
        message: 'Regra não disponível para agendamento',
        description: '',
      },
      1040: {
        message:
          'O valor alocado pela carga do caminhão é maior que o reservado pelo pré agendamento',
        description: '',
      },

      // Tutorial errors
      1200: {
        message: 'Tutoriais não encontrados',
        description: '',
      },

      // Other errors
      204: {
        message: 'Credenciais incompletas',
        description: 'É necessário informar um email para realizar o login',
      },
      404: {
        message: 'Rota não encontrada',
        description: 'Não encontramos a rota destino dessa requisição',
      },
      1103: {
        message: 'Erro de validação',
        description: '',
      },
    },
  },
};
export default br;
