import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Layout,
  message,
  PageHeader,
  Popconfirm,
  Row,
  Space,
} from 'antd';
import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  INewRuleInput,
  ISchedulingConfig,
  RuleUpdateInput,
} from '../../structures/interfaces/Rules';
import RulesForm from './RulesForm';
import { useRuleContext } from '../../contexts/rule/RuleContext.hooks';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import RulesController from '../../structures/controllers/Rules';
import moment, { Moment } from 'moment';

const boxShadow =
  '0 -6px 16px -8px rgba(0,0,0,.08),0 -9px 28px 0 rgba(0,0,0,.05),0 -12px 48px 16px rgba(0,0,0,.03)';

const momentData = (data?: string | Moment) =>
  moment(data).clone().toISOString();
const RulesFormPage = (): ReactElement => {
  const history = useHistory();
  const { t } = useTranslation();
  const [form] = Form.useForm<ISchedulingConfig>();
  const { userData } = useContext(UserContext);
  const { calendarDates, handleSetCalendarDates, operation } = useRuleContext();
  const { id } = useParams<{ id: string }>();

  const [createRule, isCreating] = useRequest(RulesController.createRule);
  const [updateRule, isUpdating] = useRequest(RulesController.updateRule);
  const isLoading = isCreating || isUpdating;

  const organizationId = userData?.organization.id || '';

  const onFinish = (values: ISchedulingConfig) => {
    const fobCadences = calendarDates.map(calendarDate => {
      const { fob } = calendarDate.fillCalendar;
      return {
        available_units: fob?.capacityPerDay,
        capacity_per_day: fob?.capacityPerDay,
        end_time: moment(fob?.endTime).toISOString(),
        start_time: moment(fob?.startTime).toISOString(),
        organization_id: organizationId,
      };
    });
    const cifCadences = calendarDates.map(calendarDate => {
      const { cif } = calendarDate.fillCalendar;
      return {
        available_units: cif?.capacityPerDay,
        capacity_per_day: cif?.capacityPerDay,
        end_time: moment(cif?.endTime).toISOString(),
        start_time: moment(cif?.startTime).toISOString(),
        organization_id: organizationId,
      };
    });

    if (operation === 'create') {
      const aux = {
        fob_cadences: { createMany: { data: fobCadences } },
        cif_cadences: { createMany: { data: cifCadences } },
        organization: { connect: { id: organizationId } },
        seed_type: { connect: { name_without_accent: values.seedTypeId } },

        capacity_per_company: values.fob.capacityPerDay,
        driver_report_timeout: values.driverReportTimeout * 60,
        load_report_timeout: values.loadReportTimeout,

        end_date: momentData(values.endDate),
        start_date: momentData(values.startDate),
        scheduling_start: momentData(values.schedulingStart),
        release_submission_date: momentData(values.releaseSubmissionDate),

        tsi_seed_scheduling_time: values.tsiSeedsSchedulingTime,
        unity: values.unity,
        white_seed_scheduling_time: values.whiteSeedsSchedulingTime,
        pick_up_location: { connect: { id: values.pickUpLocationIds[0] } },

        cif_general_capacity_per_day: values.cif.generalCapacity,
        cif_general_saturday_capacity: values.cif.saturday?.capacity,
        cif_general_sunday_capacity: values.cif.sunday?.capacity,
        fob_general_capacity_per_day: values.fob.generalCapacity,
        fob_general_saturday_capacity: values.fob.saturday?.capacity,
        fob_general_sunday_capacity: values.fob.sunday?.capacity,

        general_start_time: momentData(values.scheduleHours[0]),
        general_cif_saturday_end_time: momentData(
          values.cif.saturday?.hours[1]
        ),
        general_cif_saturday_start_time: momentData(
          values.cif.saturday?.hours[0]
        ),
        general_cif_sunday_end_time: momentData(values.cif.sunday?.hours[1]),
        general_cif_sunday_start_time: momentData(values.cif.sunday?.hours[0]),
        general_end_time: momentData(values.scheduleHours[1]),
        general_fob_saturday_end_time: momentData(
          values.fob.saturday?.hours[1]
        ),
        general_fob_saturday_start_time: momentData(
          values.fob.saturday?.hours[0]
        ),
        general_fob_sunday_end_time: momentData(values.fob.sunday?.hours[1]),
        general_fob_sunday_start_time: momentData(values.fob.sunday?.hours[0]),
      } as INewRuleInput;

      const cleanData = Object.entries(aux)
        .filter(([_, value]) => value !== undefined)
        .reduce((obj: Record<string, unknown>, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {}) as unknown as INewRuleInput;

      createRule({ data: cleanData })
        .then(() => {
          handleSetCalendarDates([]);
          history.push('/regras');
          message.success('Regra cadastrada com sucesso!');
        })
        .catch(err => {
          message.error({
            content: t(err.message),
            style: { marginTop: 60 },
            duration: 3,
          });
        });
    } else {
      const updateObj = {
        capacity_per_company: values.fob.capacityPerDay,
        cif_general_capacity_per_day: values.cif.generalCapacity,
        cif_general_saturday_capacity: values.cif.saturday?.capacity,
        cif_general_sunday_capacity: values.cif.sunday?.capacity,
        fob_general_capacity_per_day: values.fob.generalCapacity,
        fob_general_saturday_capacity: values.fob.saturday?.capacity,
        fob_general_sunday_capacity: values.fob.sunday?.capacity,
        driver_report_timeout: values.driverReportTimeout * 60,
        load_report_timeout: values.loadReportTimeout,
        general_start_time: momentData(values.scheduleHours[0]),
        general_cif_saturday_end_time: momentData(
          values.cif.saturday?.hours[1]
        ),
        general_cif_saturday_start_time: momentData(
          values.cif.saturday?.hours[0]
        ),
        general_cif_sunday_end_time: momentData(values.cif.sunday?.hours[1]),
        general_cif_sunday_start_time: momentData(values.cif.sunday?.hours[0]),
        general_end_time: momentData(values.scheduleHours[1]),
        general_fob_saturday_end_time: momentData(
          values.fob.saturday?.hours[1]
        ),
        general_fob_saturday_start_time: momentData(
          values.fob.saturday?.hours[0]
        ),
        general_fob_sunday_end_time: momentData(values.fob.sunday?.hours[1]),
        general_fob_sunday_start_time: momentData(values.fob.sunday?.hours[0]),
      } as RuleUpdateInput;

      const cleanData = Object.entries(updateObj)
        .filter(([_, value]) => value !== undefined)
        .reduce((obj: Record<string, unknown>, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {}) as unknown as RuleUpdateInput;

      updateRule({ id, data: cleanData })
        .then(() => {
          handleSetCalendarDates([]);
          history.push('/regras');
        })
        .catch(() =>
          message.error('Não foi possível atualizar a regra! Tente novamente!')
        );
    }
  };

  const rulesFooter = (
    <Layout.Footer style={{ background: '#FFFF', boxShadow, textAlign: 'end' }}>
      <Space size={12}>
        <Popconfirm
          title={'Tem certeza que deseja cancelar a operação?'}
          okText={'Sim'}
          cancelText={'Não'}
          cancelButtonProps={{ danger: true }}
          onConfirm={() => {
            handleSetCalendarDates([]);
            history.push('/regras');
          }}
        >
          <Button danger icon={<CloseOutlined />}>
            Cancelar
          </Button>
        </Popconfirm>

        <Button
          type="primary"
          icon={<CheckOutlined />}
          htmlType="submit"
          onClick={() => form.submit()}
          disabled={calendarDates.length === 0}
          loading={isLoading}
        >
          {operation === 'update' ? 'Atualizar regra' : 'Criar regra'}
        </Button>
      </Space>
    </Layout.Footer>
  );

  const pageHeaderTitle =
    operation === 'update'
      ? 'Atualizar regra'
      : t('pages.scheduleRules.newRules.title');

  return (
    <Layout>
      <PageHeader
        ghost={false}
        title={pageHeaderTitle}
        onBack={() => history.push('/regras')}
        subTitle={t('pages.scheduleRules.newRules.subtitle')}
      />
      <Layout.Content style={{ margin: 24, marginBottom: '1%' }}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <RulesForm form={form} isLoading={isLoading} onFinish={onFinish} />
          </Col>
        </Row>
      </Layout.Content>
      {rulesFooter}
    </Layout>
  );
};

export default RulesFormPage;
