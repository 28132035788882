import client from '../../api/urql/customClient';
import Normalizer from '../../classes/Normalizer';
import {
  IAddShippingCompany,
  IFindDriverResponse,
  IFindShippingCompanyResponse,
  IUpdateShippingCompany,
} from '../interfaces/ShippingCompany';
import ShippingCompanyQueries from '../queries/ShippingCompany';

class ShippingCompanyController {
  static listShippingCompanies = ({
    cnpj,
  }: {
    cnpj?: string;
  }): Promise<IFindShippingCompanyResponse[]> =>
    new Promise((resolve, reject) => {
      client
        .query(ShippingCompanyQueries.listShippingCompanies(), {
          where: cnpj ? { document_number_cnpj: { equals: cnpj } } : undefined,
        })
        .then(({ shippingCompanies }) => resolve(shippingCompanies))
        .catch(err => reject(err));
    });

  static findDriver = ({
    cpf,
  }: {
    cpf: string;
  }): Promise<IFindDriverResponse> =>
    new Promise((resolve, reject) => {
      client
        .query(ShippingCompanyQueries.findDriver(), {
          where: {
            document_number_cpf: {
              equals: cpf,
            },
          },
        })
        .then(({ driver }) => resolve(driver))
        .catch(err => reject(err));
    });

  static addNewShippingCompany = (
    input: IAddShippingCompany
  ): Promise<{ id: string }> => {
    const treatedInput = {
      ...input,
      document_number_cnpj: Normalizer.onlyNumbers(input.document_number_cnpj),
      phone_number: Normalizer.onlyNumbers(input.phone_number),
    };

    return new Promise((resolve, reject) =>
      client
        .mutation(ShippingCompanyQueries.addNewShippingCompany(), {
          data: { ...treatedInput },
        })
        .then(({ newShippingCompany }) => resolve(newShippingCompany))
        .catch(err => reject(err))
    );
  };

  static updateShippingCompany = ({
    input,
    where,
  }: {
    where: { id: string };
    input: IUpdateShippingCompany;
  }): Promise<{ id: string }> =>
    new Promise((resolve, reject) =>
      client
        .mutation(ShippingCompanyQueries.updateShippingCompany(), {
          input,
          where,
        })
        .then(({ update }) => resolve(update))
        .catch(err => reject(err))
    );
}

export default ShippingCompanyController;
