import { IGetRules } from '../../structures/interfaces/ScheduleRules';

export const BuildRulesSlug = (rules: IGetRules[]): IGetRules[] => {
  return rules?.map(rule => ({
    ...rule,
    slug: `${rule.seedType.name} ${rule.pickUpLocation.title} ${rule.capacityPerCompany} ${rule.whiteSeedSchedulingTime} ${rule.tsiSeedSchedulingTime}`.toLowerCase(),
  }));
};

export const FilterByCulture = (
  rules: IGetRules[],
  seedName?: string
): IGetRules[] => {
  return seedName != undefined
    ? rules.filter(
        rule =>
          rule.seedType.name.toLowerCase().trim() ===
          seedName.toLowerCase().trim()
      )
    : rules;
};

export const FilterByLocation = (
  rules: IGetRules[],
  locale?: string
): IGetRules[] => {
  return locale != undefined
    ? rules.filter(
        rule =>
          rule.seedTypeId.toLowerCase().trim() === locale.toLowerCase().trim()
      )
    : rules;
};
