import client from '../../api/urql/customClient';
import {
  IFetchRuleToUpdate,
  INewRuleInput,
  IRulesRequirements,
  RuleUpdateInput,
} from '../interfaces/Rules';
import { ICreateCustomerRule } from '../interfaces/ScheduleRules';
import RulesQueries from '../queries/Rules';

class RulesController {
  static fetchRuleRequirements = ({
    orgId,
  }: {
    orgId: string;
  }): Promise<IRulesRequirements> => {
    return new Promise((resolve, reject) =>
      client
        .query(RulesQueries.fetchRuleRequirements(), {
          where: {
            organization_id: { equals: orgId },
            pick_up_location: { equals: true },
          },
        })
        .then(resolve)
        .catch(reject)
    );
  };
  static createRule = ({
    data,
  }: {
    data: INewRuleInput;
  }): Promise<[{ id: string }]> => {
    return new Promise((resolve, reject) =>
      client
        .mutation(RulesQueries.createRule(), { data })
        .then(({ createRule }) => resolve(createRule))
        .catch(reject)
    );
  };
  static updateRule = ({
    id,
    data,
  }: {
    id: string;
    data: RuleUpdateInput;
  }): Promise<[{ id: string }]> => {
    return new Promise((resolve, reject) =>
      client
        .mutation(RulesQueries.updateRule(), { where: { id }, data })
        .then(({ updateRule }) => resolve(updateRule))
        .catch(reject)
    );
  };
  static createCustomerRule = ({
    customerRules,
    referenceRuleId,
  }: {
    customerRules: ICreateCustomerRule[];
    referenceRuleId: string;
  }): Promise<PromiseSettledResult<{ id: string }>[]> => {
    const responses = customerRules.map(
      ({ customerId, startDate, endDate, capacity, timezone }) =>
        new Promise<{ id: string }>((resolve, reject) =>
          client
            .mutation(RulesQueries.createCustomerRules(), {
              input: {
                customer_id: customerId || '',
                reference_rule_id: referenceRuleId,
                start_date: startDate,
                end_date: endDate,
                capacity: capacity,
                timezone: timezone,
              },
            })
            .then(({ customerRule }) => resolve(customerRule))
            .catch(reject)
        )
    );
    return Promise.allSettled(responses);
  };
  static fetchRuleToUpdate = ({
    id,
  }: {
    id: string;
  }): Promise<IFetchRuleToUpdate> => {
    return new Promise((resolve, reject) =>
      client
        .query(RulesQueries.fetchRule(), { where: { id } })
        .then(({ rule }) => resolve(rule))
        .catch(reject)
    );
  };
  static deleteCifCadence = ({
    id,
  }: {
    id: string;
  }): Promise<{ success: boolean }> =>
    new Promise((resolve, reject) =>
      client
        .mutation(RulesQueries.deleteCifCadence(), { where: { id } })
        .then(({ deleteCifCadence }) => resolve(deleteCifCadence))
        .catch(err => reject(err))
    );
  static deleteFobCadence = ({
    id,
  }: {
    id: string;
  }): Promise<{ success: boolean }> =>
    new Promise((resolve, reject) =>
      client
        .mutation(RulesQueries.deleteFobCadence(), { where: { id } })
        .then(({ deleteFobCadence }) => resolve(deleteFobCadence))
        .catch(err => reject(err))
    );
  static fetchFarms = ({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<Array<{ id: string; name: string }>> =>
    new Promise((resolve, reject) =>
      client
        .query(RulesQueries.fetchFarms(), {
          where: { organization_id: organizationId },
        })
        .then(({ farms }) => resolve(farms))
        .catch(err => reject(err))
    );
}

export default RulesController;
