import { gql, TypedDocumentNode } from 'urql';
import {
  IContractItems,
  IFetchContracts,
  IGetContract,
  IGetContractWhere,
  IGetCustomerContractsResponse,
  IViewContracts,
} from '../interfaces/Contract';

const queries = {
  getContracts: (): TypedDocumentNode<
    { getContracts: IGetContract[] },
    {
      where: IGetContractWhere;
    }
  > => gql`
    query getContracts($where: GetContractsInput!) {
      getContracts: GetContracts(where: $where) {
        id
        farm {
          id
          name
          stateRegistration: state_registration
          email
          customer {
            id
            tradeName: trade_name
            companyName: company_name
            phoneNumber: phone_number
            documentNumberCpf: document_number_cpf
            email
            documentNumberCnpj: document_number_cnpj
          }
        }
        status
        contractDate: contract_date
        contractItems: contract_items {
          id
          contractId: contract_id
          referenceNumber: reference_number
          cultivation
          packing
          sieve
          category
          tsi
          estimatedWeight: estimated_weight
          seedType: seed_type {
            name
          }
          fullAmount: full_amount
          remainingAmount: remaining_amount
        }
        referenceNumber: reference_number
        organizationId: organization_id
        farmId: farm_id
        salesmanId: salesman_id
        salesman {
          name
          documentNumberCpf: document_number_cpf
        }
      }
    }
  `,

  getViewContracts: (): TypedDocumentNode<
    { viewContracts: IViewContracts[] },
    {
      where: {
        organization_id: string;
      };
    }
  > => gql`
    query viewContract($where: GetContractsInput!) {
      viewContracts: GetContracts(where: $where) {
        contractItems: contract_items {
          seedType: seed_type {
            nameWithoutAccent: name_without_accent
            name
            description
          }
          id
          referenceNumber: reference_number
          cultivation
          sieve
          category
          packing
          tsi
          estimatedWeight: estimated_weight
          fullAmount: full_amount
          remainingAmount: remaining_amount
        }
        farm {
          name
          stateRegistration: state_registration
          customer {
            email
            phoneNumber: phone_number
          }
          contracts {
            referenceNumber: reference_number
          }
        }
        referenceNumber: reference_number
        contractDate: contract_date
        status
      }
    }
  `,

  getContractItems: (): TypedDocumentNode<{
    getContractItems: IContractItems[];
  }> => gql`
    query getContractItems($where: GetContractItemsInput!) {
      getContractItems: GetContractItems(where: $where) {
        id
        contractId: contract_id
        referenceNumber: reference_number
        cultivation
        packing
        sieve
        category
        tsi
        estimatedWeight: estimated_weight
        seedType: seed_type {
          name
        }
        fullAmount: full_amount
        remainingAmount: remaining_amount
      }
    }
  `,

  getCustomerContracts: (): TypedDocumentNode<
    { getContracts: IGetCustomerContractsResponse[] },
    {
      where: {
        customer_id: string;
      };
    }
  > => gql`
    query GetCustomerContracts($where: GetCustomerContractsWhere!) {
      getContracts: GetCustomerContracts(where: $where) {
        id
        farm {
          id
          name
          stateRegistration: state_registration
          email
          customer {
            id
            tradeName: trade_name
            companyName: company_name
            phoneNumber: phone_number
            documentNumberCpf: document_number_cpf
            email
            documentNumberCnpj: document_number_cnpj
          }
        }
        status
        contractDate: contract_date
        contractItems: contract_items {
          id
          contractId: contract_id
          referenceNumber: reference_number
          cultivation
          packing
          sieve
          category
          tsi
          estimatedWeight: estimated_weight
          seedType: seed_type {
            name
          }
          fullAmount: full_amount
          remainingAmount: remaining_amount
        }
        referenceNumber: reference_number
        organizationId: organization_id
        farmId: farm_id
        salesmanId: salesman_id
        salesman {
          name
          documentNumberCpf: document_number_cpf
        }
      }
    }
  `,

  ////////////////////// new queries ///////////////////////////

  fetchContracts: (): TypedDocumentNode<
    { contracts: IFetchContracts[] },
    {
      where: {
        organization_id?: { equals?: string };
        farm_id?: { in?: string[] };
      };
    }
  > => gql`
    query FetchContracts($where: ContractWhereInput!) {
      contracts: GetContracts(where: $where) {
        id
        farm {
          name
          stateRegistration: state_registration
          email
        }
        salesman {
          name
          documentNumberCpf: document_number_cpf
        }
        contractItems: contract_items {
          id
          contractId: contract_id
          referenceNumber: reference_number
          cultivation
          packing
          sieve
          category
          tsi
          estimatedWeight: estimated_weight
          seedType: seed_type {
            nameWithoutAccent: name_without_accent
          }
          fullAmount: full_amount
          remainingAmount: remaining_amount
        }
        contractDate: contract_date
        referenceNumber: reference_number
        status
      }
    }
  `,
};

export default queries;
