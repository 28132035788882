import moment from 'moment';
import {
  IFetchContracts,
  IGetContract,
} from '../../structures/interfaces/Contract';

interface IContractItem {
  id: string;
  referenceNumber: string;
  cultivation: string;
  packing: string;
  sieve: string;
  category: string;
  tsi: string;
  estimatedWeight: number;
  seedType: string;
  fullAmount: number;
  remainingAmount: number;
}
type ContractItemWithId = IContractItem & { contract_item_id: string };

export const BuildSlug = (contracts: IFetchContracts[]): IFetchContracts[] =>
  contracts?.map(contract => ({
    ...contract,
    slug: `${contract.id} ${contract.contractDate} ${contract.farm.name} ${contract.farm.stateRegistration} ${contract.referenceNumber} ${contract.status}`.toLowerCase(),
    contractsSlug: contract.referenceNumber,
  }));

export const BuildContracts = (
  contracts: IGetContract[]
): Array<IGetContract & { contract_item_id: string }> => {
  const contractItems: Array<IGetContract & { contract_item_id: string }> = [];

  // Função para pegar propriedades e renomear se necessário
  const pickWithRename = <T extends Record<string, any>>(
    obj: T,
    keyMap: [string, keyof T][]
  ): Record<string, any> => {
    return keyMap.reduce((result, [newKey, originalKey]) => {
      result[newKey] = obj[originalKey];
      return result;
    }, {} as Record<string, any>);
  };

  contracts?.forEach(contract => {
    const contrato: IGetContract = {
      ...(pickWithRename(contract, [
        ['id', 'id'],
        ['farm', 'farm'],
        ['status', 'status'],
        ['contractDate', 'contractDate'],
        ['contractDateString', 'contractDateString'],
        ['referenceNumber', 'referenceNumber'],
        ['organization_id', 'organizationId'],
        ['farm_id', 'farmId'],
        ['salesman_id', 'salesmanId'],
        ['salesman', 'salesman'],
      ]) as IGetContract),
      contractItems: contract.contractItems, // mantém os itens do contrato
    };

    contract.contractItems.forEach(contractItem => {
      const contractItemWithId: ContractItemWithId = {
        ...(pickWithRename(contractItem, [
          ['contract_item_id', 'id'],
          ['contract_item_reference_number', 'referenceNumber'],
          ['contract_item_cultivation', 'cultivation'],
          ['contract_item_packing', 'packing'],
          ['contract_item_sieve', 'sieve'],
          ['contract_item_category', 'category'],
          ['contract_item_tsi', 'tsi'],
          ['contract_item_estimated_weight', 'estimatedWeight'],
          ['contract_item_seed_type', 'seedType'],
          ['contract_item_full_amount', 'fullAmount'],
          ['contract_item_remaining_amount', 'remainingAmount'],
        ]) as IContractItem), // tipagem correta dos itens
        contract_item_id: contractItem.id, // garante o mapeamento correto
      };

      contractItems.push({
        ...contrato,
        ...contractItemWithId,
      });
    });
  });

  return contractItems;
};
export const FilterContractsByStatus = (
  contracts: IFetchContracts[],
  status?: string
): IFetchContracts[] =>
  status != undefined
    ? contracts.filter(contract => contract.status === status)
    : contracts;

export const FilterContractsByDate = (
  contracts: IFetchContracts[],
  date?: [moment.Moment, moment.Moment]
): IFetchContracts[] =>
  date != undefined
    ? contracts.filter(contract => {
        return moment(contract.contractDate).isBetween(
          date[0],
          date[1],
          'day',
          '[]'
        );
      })
    : contracts;
