import moment, { Moment } from 'moment';
import { ReactElement } from 'react';

const DefaultCalendarCell = ({ date }: { date: Moment }): ReactElement => {
  const classNames = ['ant-picker-cell-inner', 'ant-picker-calendar-date'];
  if (date.isSame(moment(), 'day')) {
    classNames.push('ant-picker-calendar-date-today');
  }

  return (
    <div className={classNames.join(' ')} style={{ marginBottom: 8 }}>
      <div className={`ant-picker-calendar-date-value`}>
        {date.format('DD')}
      </div>
      <div className={`ant-picker-calendar-date-content`} />
    </div>
  );
};

export default DefaultCalendarCell;
