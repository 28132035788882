import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Layout, PageHeader, Row } from 'antd';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import ScheduleRulesController from '../../structures/controllers/ScheduleRules';
import { IGetRules } from '../../structures/interfaces/ScheduleRules';
import { BuildRulesSlug } from './Rules.utils';
import RulesFilter from './RulesPage.filter';
import RulesTable from './RulesPage.table';
import { useRuleContext } from '../../contexts/rule/RuleContext.hooks';

const RulesPage = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const { userData } = useContext(UserContext);
  const [rules, setRules] = useState<IGetRules[]>([]);
  const [filteredRules, setFilteredRules] = useState<IGetRules[]>([]);
  const [fetchRules, isLoading] = useRequest(ScheduleRulesController.getRules);
  const { handleSetOperation } = useRuleContext();

  const addNewRule = (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      onClick={() => {
        handleSetOperation('create');
        history.push('/regras/nova-regra');
      }}
    >
      Criar nova regra
    </Button>
  );
  const doFetchRules = (organizationId: string) => {
    fetchRules({ orgId: organizationId })
      .then(res => {
        const aux = BuildRulesSlug(res);
        setRules(aux);
        setFilteredRules(aux);
      })
      .catch();
  };

  useEffect(() => {
    const organizationId = userData?.organization.id;
    if (organizationId) doFetchRules(organizationId);
  }, []);

  return (
    <Layout>
      <PageHeader
        title={t('pages.scheduleRules.title')}
        subTitle={t('pages.scheduleRules.subtitle')}
        ghost={false}
      />
      <Layout.Content style={{ margin: 24 }}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Card>
              <RulesFilter rules={rules} setFilteredRules={setFilteredRules} />
            </Card>
          </Col>

          <Col span={24}>
            <Card
              title="Regras cadastradas para agendamento"
              extra={addNewRule}
            >
              <RulesTable
                data={filteredRules}
                loading={isLoading}
                doFetchRequest={doFetchRules}
              />
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default RulesPage;
