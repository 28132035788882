import { ClearOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Button,
  Calendar,
  CalendarProps,
  Card,
  Popconfirm,
  Popover,
  Typography,
} from 'antd';
import moment, { Moment } from 'moment';
import { ReactElement, useEffect, useState } from 'react';
import CalendarCell from './CalendarCell';
import { useRuleContext } from '../../../contexts/rule/RuleContext.hooks';
import DefaultCalendarCell from './DefaultCalendarCell';
import CalendarPopoverContent from './CalendarPopover.content';

interface ICadencesCalendarProps extends CalendarProps<Moment> {
  isLoading: boolean;
}

const disableDate = (current: Moment) =>
  !moment(current).isAfter(moment().subtract(1, 'days'), 'day');
const CadencesCalendar = ({
  isLoading,
  ...props
}: ICadencesCalendarProps): ReactElement => {
  const [initialMonthAppear, setInitialMonthAppear] = useState(moment());
  const [selectedDate, setSelectedDate] = useState<Moment>();
  const [openPopover, setOpenPopover] = useState(false);
  const { calendarDates, handleSetCalendarDates, operation } = useRuleContext();

  const renderCell = (value: Moment) => {
    const calendarDate = calendarDates
      .filter(item => item !== undefined)
      ?.find(calendar => moment(calendar.date).isSame(value, 'day'));

    if (!calendarDate) return <DefaultCalendarCell date={value} />;

    const cif = calendarDate?.calendarCell[0]?.cif;
    const fob = calendarDate?.calendarCell[1]?.fob;
    const schedule = calendarDate?.calendarCell[2]?.hours || '';

    const title = (
      <span
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography.Text>{value.format('DD/MM/YYYY')}</Typography.Text>
        <Button
          type="text"
          icon={<CloseOutlined style={{ color: 'gray' }} />}
        />
      </span>
    );

    return (
      <Popover
        key={value.toISOString()}
        open={openPopover && moment(value).isSame(selectedDate, 'day')}
        title={title}
        content={<CalendarPopoverContent currDate={value} />}
      >
        <CalendarCell
          date={value}
          cifCapacity={cif || 0}
          fobCapacity={fob || 0}
          hours={schedule}
        />
      </Popover>
    );
  };

  const clearCadencesBtn = (
    <Popconfirm
      title="Tem certeza que deseja limpar o calendário?"
      okText="Sim"
      cancelText="Não"
      cancelButtonProps={{ danger: true }}
      onConfirm={() => handleSetCalendarDates([])}
    >
      {operation === 'create' && (
        <Button danger icon={<ClearOutlined />}>
          Limpar cadências
        </Button>
      )}
    </Popconfirm>
  );

  const handleSelectDate = (date: Moment) => {
    setOpenPopover(openPopover => !openPopover);
    setSelectedDate(date);
  };

  useEffect(() => {
    if (calendarDates && calendarDates.length > 0) {
      const firstCadence = calendarDates?.[0].date;
      if (firstCadence) setInitialMonthAppear(firstCadence);
    }
  }, [calendarDates]);

  return (
    <Card
      loading={isLoading}
      title="Calendário de cadências"
      id={'calendar'}
      extra={clearCadencesBtn}
    >
      <Calendar
        disabledDate={disableDate}
        key={initialMonthAppear?.month().toString()}
        defaultValue={initialMonthAppear}
        dateFullCellRender={renderCell}
        onSelect={handleSelectDate}
        {...props}
      />
    </Card>
  );
};

export default CadencesCalendar;
