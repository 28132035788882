import { ClearOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  FormInstance,
  InputNumber,
  TimePicker,
  Typography,
} from 'antd';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ISchedulingConfig } from '../../../../structures/interfaces/Rules';

const { Text } = Typography;
const { RangePicker } = TimePicker;

interface IWeekendFormProps {
  ruleType: 'cif' | 'fob';
  form: FormInstance<ISchedulingConfig>;
}
const WeekendForm = ({ ruleType, form }: IWeekendFormProps): ReactElement => {
  const { t } = useTranslation();

  const timePlaceholder = [
    t('pages.scheduleRules.editRules.startTime'),
    t('pages.scheduleRules.editRules.endTime'),
  ] as [string, string];

  return (
    <>
      {/* Sábado */}
      <Col span={24} style={{ marginBottom: 8 }}>
        <Text strong> Sábado </Text>
      </Col>

      <Col span={12}>
        <Form.Item
          label={'Quantidade'}
          name={[ruleType, 'saturday', 'capacity']}
          required
        >
          <InputNumber addonAfter={'Bags'} style={{ width: '100%' }} />
        </Form.Item>
      </Col>

      <Col span={10}>
        <Form.Item
          label={'Horários de trabalho'}
          name={[ruleType, 'saturday', 'hours']}
          required
        >
          <RangePicker
            format={'HH:mm'}
            style={{ width: '100%' }}
            placeholder={timePlaceholder}
          />
        </Form.Item>
      </Col>

      <Col span={2}>
        <Form.Item label={' '}>
          <Button
            style={{ width: '100%' }}
            icon={<ClearOutlined />}
            onClick={() => {
              form.resetFields([
                [ruleType, 'saturday', 'capacity'],
                [ruleType, 'saturday', 'hours'],
              ]);
            }}
          />
        </Form.Item>
      </Col>

      {/* Domingo */}
      <Col span={24} style={{ marginBottom: 8 }}>
        <Text strong> Domingo </Text>
      </Col>
      <Col span={12}>
        <Form.Item
          label={'Quantidade'}
          name={[ruleType, 'sunday', 'capacity']}
          required
        >
          <InputNumber addonAfter={'Bags'} style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item
          label={'Horários de trabalho'}
          name={[ruleType, 'sunday', 'hours']}
          required
        >
          <RangePicker
            format={'HH:mm'}
            style={{ width: '100%' }}
            placeholder={timePlaceholder}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item label={' '}>
          <Button
            icon={<ClearOutlined />}
            style={{ width: '100%' }}
            onClick={() => {
              form.resetFields([
                [ruleType, 'sunday', 'capacity'],
                [ruleType, 'sunday', 'hours'],
              ]);
            }}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default WeekendForm;
