import {
  Card,
  Col,
  DatePicker,
  Form,
  FormInstance,
  InputNumber,
  Row,
} from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import { fieldsSize } from '../RulesForm.utils';
import { ReactElement } from 'react';
import { useRuleContext } from '../../../contexts/rule/RuleContext.hooks';
import { ISchedulingConfig } from '../../../structures/interfaces/Rules';

interface ISeedsTreatmentFormProps {
  form?: FormInstance<ISchedulingConfig>;
  isLoading: boolean;
}

const SeedsTreatmentForm = ({
  form,
  isLoading,
}: ISeedsTreatmentFormProps): ReactElement => {
  const { operation } = useRuleContext();

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    const releaseSubmissionDate = form?.getFieldValue('releaseSubmissionDate');
    const whiteSeedsValue = form?.getFieldValue('whiteSeedsSchedulingTime');

    if (releaseSubmissionDate) {
      return (
        current <=
        moment(releaseSubmissionDate)
          .add(whiteSeedsValue, 'days')
          .startOf('day')
      );
    } else {
      return current <= moment().add(whiteSeedsValue, 'days').startOf('day');
    }
  };

  return (
    <Card title="Tratamento de sementes" loading={isLoading}>
      <Row gutter={24}>
        <Col {...fieldsSize}>
          <Form.Item
            label={'Tratamento de sementes brancas'}
            name={'whiteSeedsSchedulingTime'}
            initialValue={0}
            required
          >
            <InputNumber
              addonAfter={'Dias'}
              style={{ width: '100%' }}
              disabled={operation === 'update'}
            />
          </Form.Item>
        </Col>

        <Col {...fieldsSize}>
          <Form.Item
            label={'Tratamento de sementes com TSI'}
            name={'tsiSeedsSchedulingTime'}
            initialValue={0}
            required
          >
            <InputNumber
              addonAfter={'Dias'}
              style={{ width: '100%' }}
              disabled={operation === 'update'}
            />
          </Form.Item>
        </Col>

        <Col {...fieldsSize}>
          <Form.Item
            required
            label={'Data envio de liberação'}
            name={'releaseSubmissionDate'}
          >
            <DatePicker
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>

        <Col {...fieldsSize}>
          <Form.Item
            label={'Início de agendamento'}
            name={'schedulingStart'}
            required
          >
            <DatePicker
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>

        <Col {...fieldsSize}>
          <Form.Item label={'Início de embarque'} name={'startDate'} required>
            <DatePicker
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
              disabledDate={disabledDate}
              disabled={operation === 'update'}
            />
          </Form.Item>
        </Col>

        <Col {...fieldsSize}>
          <Form.Item label={'Fim de embarque'} name={'endDate'} required>
            <DatePicker
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
              disabledDate={disabledDate}
              disabled={operation === 'update'}
            />
          </Form.Item>
        </Col>

        <Col {...fieldsSize}>
          <Form.Item
            label={'Tempo limite - carga'}
            name={'loadReportTimeout'}
            required
          >
            <InputNumber
              placeholder="Insira o tempo para a formação de cargas"
              addonAfter={'Minutos'}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>

        <Col {...fieldsSize}>
          <Form.Item>
            <Form.Item
              label={'Nomeação do motorista'}
              name={'driverReportTimeout'}
              required
            >
              <InputNumber
                placeholder="Insira o período para informar motorista"
                addonAfter={'Horas'}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default SeedsTreatmentForm;
