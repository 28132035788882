import { Col, Form, FormInstance, FormProps, Row } from 'antd';
import moment from 'moment';
import { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRuleContext } from '../../contexts/rule/RuleContext.hooks';
import { useRequest } from '../../hooks/useRequest';
import RulesController from '../../structures/controllers/Rules';
import {
  ICalendarCadences,
  IFetchRuleToUpdate,
  ISchedulingConfig,
} from '../../structures/interfaces/Rules';
import CadencesCalendar from './calendar/CadencesCalendar';
import BasicInformationForm from './rulesForm/BasicInformationForm';
import CapacitiesForm from './rulesForm/capacities/CapacitiesForm';
import SeedsTreatmentForm from './rulesForm/SeedsTreatmentForm';
import UnityForm from './rulesForm/UnityForm';

interface IRulesFormProps extends FormProps {
  isLoading: boolean;
  form: FormInstance<ISchedulingConfig>;
}

const RulesForm = ({
  form,
  isLoading,
  onFinish,
  ...props
}: IRulesFormProps): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const { handleSetCalendarDates, handleSetOperation } = useRuleContext();
  const [findRule] = useRequest(RulesController.fetchRuleToUpdate);

  const setFieldsValue = (res: IFetchRuleToUpdate) => {
    const cifWeekendValues = {
      sunday: {
        capacity: res.cifGeneralSundayCapacity,
        hours: [
          moment(res.generalCifSundayStartTime),
          moment(res.generalCifSundayEndTime),
        ],
      },
      saturday: {
        capacity: res.cifGeneralSaturdayCapacity,
        hours: [
          moment(res.generalCifSaturdayStartTime),
          moment(res.generalCifSaturdayEndTime),
        ],
      },
    };
    const fobWeekendValues = {
      sunday: {
        capacity: res.fobGeneralSundayCapacity,
        hours: [
          moment(res.generalFobSundayStartTime),
          moment(res.generalFobSundayEndTime),
        ],
      },
      saturday: {
        capacity: res.fobGeneralSaturdayCapacity,
        hours: [
          moment(res.generalFobSaturdayStartTime),
          moment(res.generalFobSaturdayEndTime),
        ],
      },
    };

    form.setFieldsValue({
      driverReportTimeout: res.driverReportTimeout / 60,
      endDate: moment(res.endDate),
      loadReportTimeout: res.loadReportTimeout,
      pickUpLocationIds: [res.pickUpLocation?.id],
      releaseSubmissionDate: moment(res.releaseSubmissionDate),
      schedulingStart: moment(res.schedulingStart),
      seedTypeId: res.seedType?.name,
      startDate: moment(res.startDate),
      tsiSeedsSchedulingTime: res.tsiSeedSchedulingTime,
      unity: res.unity,
      whiteSeedsSchedulingTime: res.whiteSeedSchedulingTime,
      scheduleHours: [moment(res.generalStartTime), moment(res.generalEndTime)],
      cif: {
        generalCapacity: res.cifGeneralCapacityPerDay,
        capacityPerDay: res.capacityPerCompany,
        ...cifWeekendValues,
      },
      fob: {
        generalCapacity: res.fobGeneralCapacityPerDay,
        capacityPerDay: res.capacityPerCompany,
        ...fobWeekendValues,
      },
    });

    const cifDates = res.cifCadences.map(item =>
      moment(item.startTime).clone()
    );
    const fobDates = res.fobCadences.map(item =>
      moment(item.startTime).clone()
    );
    const allDates = [...new Set([...cifDates, ...fobDates])];
    const temp = allDates.map(date => {
      const cifForDate = res.cifCadences.find(cif =>
        moment(cif.startTime).isSame(date, 'day')
      );
      const fobForDate = res.fobCadences.find(fob =>
        moment(fob.startTime).isSame(date, 'day')
      );

      let scheduleHours;
      const {
        generalStartTime,
        generalEndTime,
        generalCifSaturdayStartTime,
        generalCifSaturdayEndTime,
        generalFobSaturdayStartTime,
        generalFobSaturdayEndTime,
        generalCifSundayStartTime,
        generalCifSundayEndTime,
        generalFobSundayStartTime,
        generalFobSundayEndTime,
      } = res;

      const start = moment(generalStartTime).format('HH:mm');
      const end = moment(generalEndTime).format('HH:mm');

      const cifSatStart = moment(generalCifSaturdayStartTime).format('HH:mm');
      const cifSatEnd = moment(generalCifSaturdayEndTime).format('HH:mm');
      const fobSatStart = moment(generalFobSaturdayStartTime).format('HH:mm');
      const fobSatEnd = moment(generalFobSaturdayEndTime).format('HH:mm');

      const cifSunStart = moment(generalCifSundayStartTime).format('HH:mm');
      const cifSunEnd = moment(generalCifSundayEndTime).format('HH:mm');
      const fobSunStart = moment(generalFobSundayStartTime).format('HH:mm');
      const fobSunEnd = moment(generalFobSundayEndTime).format('HH:mm');

      switch (date.isoWeekday()) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          scheduleHours = `${start} - ${end}`;
          break;
        case 6:
          scheduleHours = `${cifSatStart} - ${cifSatEnd} | ${fobSatStart} - ${fobSatEnd}`;
          break;
        case 7:
          scheduleHours = `${cifSunStart} - ${cifSunEnd} | ${fobSunStart} - ${fobSunEnd}`;
          break;
      }

      const data = {
        date,
        calendarCell: [
          { cif: cifForDate?.availableUnits },
          { fob: fobForDate?.availableUnits },
          { hours: scheduleHours },
        ],
        fillCalendar: {
          cif: { ...cifForDate },
          fob: { ...fobForDate },
          ruleId: id,
        },
      } as ICalendarCadences;

      return data;
    });
    handleSetCalendarDates(temp);
  };
  const findRuleRequest = (id: string) => {
    findRule({ id }).then(setFieldsValue).catch();
  };

  useEffect(() => {
    if (id) handleSetOperation('update');
    findRuleRequest(id);
  }, []);

  return (
    <Form layout="vertical" form={form} onFinish={onFinish} {...props}>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <BasicInformationForm isLoading={isLoading} form={form} />
        </Col>

        <Col span={24}>
          <SeedsTreatmentForm form={form} isLoading={isLoading} />
        </Col>

        <Col span={24}>
          <UnityForm isLoading={isLoading} />
        </Col>

        <Col span={24}>
          <CapacitiesForm isLoading={isLoading} form={form} />
        </Col>

        <Col span={24}>
          <CadencesCalendar isLoading={isLoading} />
        </Col>

        {/* <Col span={24}>
          <CustomerRulesForm isLoading={isLoading} />
        </Col> */}
      </Row>
    </Form>
  );
};

export default RulesForm;
