import companyQueries from '../queries/Company';

import client from '../../api/urql/customClient';

import Formatter from '../../classes/Formatter';
import {
  FormUpdateBranchValues,
  FormUpdateValues,
  IBranchCompanyUpdate,
  ICompany,
  IDefaultBranchCompanies,
  IDeleteCarrierPolicy,
  IDeleteCarrierPolicyResponse,
  IDeleteCompanyLogo,
  IDeleteCompanyLogoResponse,
  IFindOrganizationForPickUpAdress as IFindOrganizationForPickUpAddress,
  IFoundCompany,
  IGetOrganizationApiKey,
  INewBranchCompany,
  IUploadCarrierPolicy,
  IUploadCarrierPolicyResponse,
  IUploadCompanyLogo,
  IUploadCompanyLogoResponse,
} from '../interfaces/Company/Company';
import { ICreateCompanyForm } from '../interfaces/Company/CompanyDto';
import { CompanyTypeEnum } from '../interfaces/Company/CompanyType';
import { CompanyFragmentModel, CompanyModel } from '../models/Company';
import { CreateCompanyModel } from '../models/Company/CreateUpdateCompany';

class CompanyController {
  static findCompanyByDocument = ({
    documentNumber,
  }: {
    documentNumber: string;
  }): Promise<IFoundCompany> =>
    new Promise((resolve, reject) =>
      client
        .query(companyQueries.findCompanyByDocument(), {
          documentNumber,
        })
        .then(({ foundCompanies }) => {
          let foundCompany = foundCompanies.find(
            ({ companyType }) => companyType !== CompanyTypeEnum.unit
          );

          if (!foundCompany) {
            foundCompany = foundCompanies.find(
              ({ companyType }) => companyType === CompanyTypeEnum.unit
            );
          }

          if (foundCompany) {
            resolve(new CompanyModel(foundCompany));
          } else {
            reject();
          }
        })
        .catch(reject)
    );

  static createCompany = (
    createCompanyFormValues: ICreateCompanyForm
  ): Promise<ICompany> =>
    new Promise((resolve, reject) =>
      client
        .mutation(companyQueries.registerCompany(), {
          createCompanyDto: new CreateCompanyModel(createCompanyFormValues),
        })
        .then(
          ({
            createdCompany: { motherCompany, referenceCompany, ...company },
          }) => {
            resolve({
              ...new CompanyFragmentModel(company),
              motherCompany: motherCompany
                ? new CompanyFragmentModel(motherCompany)
                : undefined,
              referenceCompany: referenceCompany
                ? new CompanyFragmentModel(referenceCompany)
                : undefined,
            });
          }
        )
        .catch(reject)
    );

  static uploadCompanyLogo = (
    uploadLogoImage: IUploadCompanyLogo
  ): Promise<IUploadCompanyLogoResponse> =>
    new Promise((resolve, reject) =>
      client
        .mutation(companyQueries.uploadCompanyLogo(), {
          where: {
            organization_id: uploadLogoImage.organizationId,
          },
          file: uploadLogoImage.file,
        })
        .then(({ uploadedFile }) => {
          resolve({ ...uploadedFile });
        })
        .catch(reject)
    );

  static deleteCompanyLogo = (
    deleteLogoImage: IDeleteCompanyLogo
  ): Promise<IDeleteCompanyLogoResponse> =>
    new Promise((resolve, reject) =>
      client
        .mutation(companyQueries.deleteCompanyLogo(), { ...deleteLogoImage })
        .then(({ deletedLogo }) => resolve(deletedLogo))
        .catch(reject)
    );

  static uploadCarrierPolicy = (
    uploadPolicy: IUploadCarrierPolicy
  ): Promise<IUploadCarrierPolicyResponse> =>
    new Promise((resolve, reject) =>
      client
        .mutation(companyQueries.uploadCarrierPolicy(), { ...uploadPolicy })
        .then(({ uploadedPolicy }) => resolve(uploadedPolicy))
        .catch(reject)
    );

  static deleteCarrierPolicy = (
    uploadPolicy: IDeleteCarrierPolicy
  ): Promise<IDeleteCarrierPolicyResponse> =>
    new Promise((resolve, reject) =>
      client
        .mutation(companyQueries.deleteCarrierPolicy(), { ...uploadPolicy })
        .then(({ deletedPolicy }) => resolve(deletedPolicy))
        .catch(reject)
    );

  static findOrganizationById = ({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<IFindOrganizationForPickUpAddress> =>
    new Promise((resolve, reject) =>
      client
        .query(companyQueries.findCompanyByOrganizationId(), {
          where: {
            id: organizationId,
          },
        })
        .then(({ getCompany }) => {
          const addresses = getCompany.addresses.find(
            ({ defaultAddress }) => defaultAddress === true
          );
          const pickUpLocations = getCompany.addresses.filter(
            ({ pickUpLocation }) => pickUpLocation === true
          );
          getCompany.contacts.map(item => {
            if (!item.email) item.email = '---';
            if (!item.phoneNumber) item.phoneNumber = '---';
          });
          return resolve({
            ...getCompany,
            addresses,
            pickUpLocations,
          });
        })
        .catch(err => reject(err))
    );

  static updateOrganization = ({
    updateOrganization,
    id,
  }: {
    updateOrganization: FormUpdateValues;
    id: string;
  }): Promise<IFindOrganizationForPickUpAddress> =>
    new Promise((resolve, reject) => {
      client
        .mutation(companyQueries.updateOrganization(), {
          input: {
            company_name: updateOrganization.companyName,
            document_number_cnpj: updateOrganization.documentNumberCnpj,
            trade_name: updateOrganization.tradeName,
            addresses: {
              update: {
                where: {
                  id: updateOrganization.addressId,
                },
                data: {
                  city_name: updateOrganization.cityName,
                  district: updateOrganization.district,
                  state_short_name: updateOrganization.stateShortName,
                  street: updateOrganization.street,
                  street_number: updateOrganization.streetNumber,
                  title: updateOrganization.title,
                  zip_code: updateOrganization.zipCode,
                  complement: updateOrganization.complement,
                  city_id: updateOrganization.cityId,
                  country_name: updateOrganization.countryName,
                  state_name: updateOrganization.stateName,
                  pick_up_location: updateOrganization.pickUpLocation,
                },
              },
            },
            contacts: {
              update: {
                where: {
                  id: updateOrganization.contactId,
                },
                data: {
                  email: updateOrganization.email,
                  phone_number: updateOrganization.phoneNumber,
                },
              },
            },
          },
          where: { id },
        })
        .then(({ updateOrganization }) => {
          const addresses = updateOrganization.addresses.find(
            ({ defaultAddress }) => defaultAddress === true
          );
          const pickUpLocations = updateOrganization.addresses.filter(
            ({ pickUpLocation }) => pickUpLocation === true
          );
          return resolve({
            ...updateOrganization,
            addresses,
            pickUpLocations,
          });
        })
        .catch(err => {
          reject(err);
        });
    });

  static updateBranchOrganization = ({
    updateBranchOrganization,
    id,
  }: {
    updateBranchOrganization: FormUpdateBranchValues;
    id: string;
  }): Promise<IBranchCompanyUpdate> =>
    new Promise((resolve, reject) => {
      client
        .mutation(companyQueries.updateBranchOrganization(), {
          where: { id },
          input: {
            trade_name: updateBranchOrganization.tradeName,
            document_number_cnpj: updateBranchOrganization.documentNumberCnpj,
            company_name: updateBranchOrganization.companyName,
            addresses: {
              update: {
                where: {
                  id: updateBranchOrganization.addressId,
                },
                data: {
                  zip_code: updateBranchOrganization.zipCode,
                  state_short_name: updateBranchOrganization.stateShortName,
                  city_name: updateBranchOrganization.cityName,
                  district: updateBranchOrganization.district,
                  street: updateBranchOrganization.street,
                  street_number: updateBranchOrganization.streetNumber,
                },
              },
            },
          },
        })
        .then(({ updateBranchOrganization }) => {
          return resolve(updateBranchOrganization);
        })
        .catch(err => {
          reject(err);
        });
    });

  static getBranchCompanies = ({
    id,
  }: {
    id: string;
  }): Promise<IDefaultBranchCompanies[]> =>
    new Promise((resolve, reject) =>
      client
        .query(companyQueries.myBranches(), { where: { id } })
        .then(({ myBranches }) => {
          const branches = myBranches.branchCompanies.map(branch => {
            const defaultLocation = branch.addresses.find(
              ({ defaultAddress }) => defaultAddress === true
            );

            if (defaultLocation) {
              defaultLocation.zipCode = Formatter.formatZipCode(
                defaultLocation?.zipCode
              );
            }

            return {
              ...branch,
              documentNumberCnpj: Formatter.formatCNPJ(
                branch.documentNumberCnpj
              ),
              defaultLocation,
            };
          });
          const activeBranches = branches.filter(
            ({ deletedAt }) => deletedAt === null
          );
          resolve(activeBranches);
        })
        .catch(err => {
          reject(err);
        })
    );

  static deleteBranchCompany = ({ id }: { id: string }): Promise<boolean> =>
    new Promise((resolve, reject) => {
      client
        .mutation(companyQueries.deleteBranchCompany(), {
          where: { id },
        })
        .then(({ status }) => {
          resolve(status);
        })
        .catch(err => {
          reject(err);
        });
    });

  static newBranchCompany = ({
    registerBranchOrganization,
  }: {
    registerBranchOrganization: INewBranchCompany;
  }): Promise<INewBranchCompany> =>
    new Promise((resolve, reject) => {
      client
        .mutation(companyQueries.newBranchCompany(), {
          input: {
            parent_id: registerBranchOrganization.parentId,
            organization: {
              trade_name: registerBranchOrganization.tradeName,
              document_number_cnpj:
                registerBranchOrganization.documentNumberCnpj,
              company_name: registerBranchOrganization.companyName,
            },
            address: {
              title: registerBranchOrganization.title,
              country_name: registerBranchOrganization.countryName,
              state_name: registerBranchOrganization.stateName,
              state_short_name: registerBranchOrganization.stateShortName,
              city_name: registerBranchOrganization.cityName,
              district: registerBranchOrganization.district,
              street: registerBranchOrganization.street,
              street_number: registerBranchOrganization.streetNumber,
              zip_code: registerBranchOrganization.zipCode,
              default_address: registerBranchOrganization.defaultAddress,
            },
          },
        })
        .then(({ registerBranchOrganization }) => {
          return resolve(registerBranchOrganization);
        })
        .catch(err => {
          reject(err);
        });
    });

  static getOrganizationApiKey = ({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<IGetOrganizationApiKey> =>
    new Promise((resolve, reject) =>
      client
        .query(companyQueries.getOrganizationApiKey(), {
          where: {
            id: organizationId,
          },
        })
        .then(({ getOrganizationApiKey }) => {
          resolve(getOrganizationApiKey);
        })
        .catch(err => reject(err))
    );
}

export default CompanyController;
