import { Col, Layout, message, PageHeader, Row } from 'antd';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/user';
import { useRequest } from '../../hooks/useRequest';
import ContractController from '../../structures/controllers/Contract';
import { IFetchContracts } from '../../structures/interfaces/Contract';
import { BuildSlug } from './Contracts.utils';
import ContractsFilter from './ContractsPage.filter';
import ContractsTable from './ContractsPage.table';
import ViewContract from './ViewContract';

const ContractsPage = (): ReactElement => {
  const { t } = useTranslation();
  const { userData, customerData } = useContext(UserContext);
  const orgId = userData?.organization.id || customerData?.organizationId;

  const [fetchContracts, isLoading] = useRequest(
    ContractController.fetchContracts
  );

  const [contracts, setContracts] = useState<IFetchContracts[]>([]);
  const [filterContracts, setFilterContracts] = useState<IFetchContracts[]>([]);

  const [viewContracts, setViewContracts] = useState(false);
  const [contractToView, setContractToView] = useState<IFetchContracts>();

  const pageHeader = (
    <PageHeader
      title={t('pages.contracts.title')}
      subTitle={t('pages.contracts.subtitle')}
      ghost={false}
    />
  );

  const handleFetchContracts = () => {
    if (userData) {
      fetchContracts({ orgId })
        .then(res => {
          const data = BuildSlug(res);
          setContracts(data);
          setFilterContracts(data);
        })
        .catch(() => message.error('Não foi possível buscar seus contratos'));
    } else {
      const farmIds = customerData?.farms.map(farm => farm.id);
      fetchContracts({ farmIds })
        .then(res => {
          const data = BuildSlug(res);
          setContracts(data);
          setFilterContracts(data);
        })
        .catch(() => message.error('Não foi possível buscar seus contratos'));
    }
  };

  useEffect(() => {
    handleFetchContracts();
  }, []);

  return (
    <Layout>
      {pageHeader}
      <Layout.Content style={{ margin: 24 }}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <ContractsFilter
              contracts={contracts}
              setFilteredContracts={setFilterContracts}
            />
          </Col>

          <Col span={24}>
            <ContractsTable
              loading={isLoading}
              dataSource={filterContracts}
              setViewContracts={setViewContracts}
              setContractToView={setContractToView}
            />
          </Col>
        </Row>
      </Layout.Content>
      <ViewContract
        contractData={contractToView}
        open={viewContracts}
        onClose={() => setViewContracts(false)}
      />
    </Layout>
  );
};

export default ContractsPage;
