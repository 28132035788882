import { ReactElement, ReactNode, useState } from 'react';
import { ICalendarCadences } from '../../structures/interfaces/Rules';
import { RuleContext } from './RuleContext';
import { ICreateCustomerRule } from '../../structures/interfaces/ScheduleRules';

export const RuleProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [calendarDates, setCalendarDates] = useState<ICalendarCadences[]>([]);
  const [customerRules, setCustomerRules] = useState<ICreateCustomerRule[]>([]);
  const [disableCadence, setDisableCadence] = useState(false);
  const [operation, setOperation] = useState<'create' | 'update'>('create');
  const [ruleType, setRuleType] = useState<'cif' | 'fob'>();

  const handleSetCalendarDates = (value: ICalendarCadences[]) => {
    const filteredItems = value.filter(item => item !== undefined);
    setCalendarDates(filteredItems);
  };
  const handleSetCustomerRules = (value: ICreateCustomerRule[]) => {
    const filteredItems = value.filter(item => item !== undefined);
    setCustomerRules(filteredItems);
  };
  const handleSetRuleType = (value: 'cif' | 'fob' | undefined) => {
    setRuleType(value);
  };
  const handleSetOperation = (value: 'create' | 'update') => {
    setOperation(value);
  };
  const handleSetDisableCadence = (value: boolean) => {
    setDisableCadence(value);
  };

  const contextValue = {
    calendarDates,
    handleSetCalendarDates,
    customerRules,
    handleSetCustomerRules,
    handleSetOperation,
    handleSetRuleType,
    handleSetDisableCadence,
    disableCadence,
    operation,
    ruleType,
  };

  return (
    <RuleContext.Provider value={contextValue}>{children}</RuleContext.Provider>
  );
};
