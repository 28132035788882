import { ArrowDownOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { DefaultOptionType } from 'antd/lib/select';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import UserContext from '../../../../../contexts/user';
import { useRequest } from '../../../../../hooks/useRequest';
import ScheduleRulesController from '../../../../../structures/controllers/ScheduleRules';
import TruckLoadsController from '../../../../../structures/controllers/TruckLoad';
import { IGetPickUpLocations } from '../../../../../structures/interfaces/ScheduleRules';
import { ITruckLoadCadences } from '../../../../../structures/interfaces/TruckLoad';
import { scrollOptions } from '../../../../../utils/formOptions';

const columns = { xs: 24, sm: 24, md: 12, lg: 12, xl: 4, xxl: 4 };

export interface IBasicDataFormValues {
  loadName: string;
  vehicle: string;
  seedType: string;
  pickUpLocation: string;
  unity: string;
  schedulingType: string;
}
interface IBasicDataProps {
  form: FormInstance<IBasicDataFormValues>;
  onFinish: (values: IBasicDataFormValues) => void;
  setCadences: Dispatch<SetStateAction<ITruckLoadCadences[]>>;
}
const BasicData = ({
  form,
  onFinish,
  setCadences,
}: IBasicDataProps): ReactElement => {
  const { userData, customerData } = useContext(UserContext);
  const orgId = userData?.organization.id || customerData?.organizationId;

  const [vehicles, setVehicles] = useState<DefaultOptionType[]>([]);
  const [locations, setLocations] = useState<DefaultOptionType[]>([]);
  const [seedTypes, setSeedTypes] = useState<DefaultOptionType[]>([]);

  const [fetchVehicles] = useRequest(TruckLoadsController.getVehicles);
  const [fetchLocales] = useRequest(ScheduleRulesController.getPickUpLocations);
  const [fetchSeedType] = useRequest(ScheduleRulesController.getCulture);
  const [fetchScheduleRule, isLoading] = useRequest(
    TruckLoadsController.listTruckLoadsRules
  );

  const unityOptions = [
    { value: 'BAG', label: 'BAGS' },
    // { value: 'SMALL_BAG', label: 'SACAS' },
    // será temporáriamente removido até termos uma request para isso!
  ];
  const schedulingTypeOptions = [
    { label: 'CIF', value: 'cif' },
    { label: 'FOB', value: 'fob' },
  ];
  const getVehicles = (orgId: string) => {
    fetchVehicles({ organizationId: orgId, takeCommonVehicles: true })
      .then(res => {
        const options = res.map(vehicle => ({
          value: vehicle.id,
          label: vehicle.vehicleModel,
        }));
        setVehicles(options);
      })
      .catch();
  };
  const getPickUpLocations = (orgId: string) => {
    const seedTypeId = form.getFieldValue('seedType');

    fetchLocales({ seedTypeId, organizationId: orgId })
      .then(res => {
        const filterItems = res.reduce((acc, item) => {
          if (!acc.find(otherItem => otherItem.id === item.id)) {
            acc.push(item);
          }
          return acc;
        }, [] as IGetPickUpLocations[]);

        if (filterItems.length === 1) {
          form.setFieldsValue({ pickUpLocation: filterItems[0].id });
        }

        const options = filterItems.map(location => ({
          value: location.id,
          label: location.title,
        }));

        setLocations(options);
      })
      .catch();
  };
  const getSeedTypes = () => {
    fetchSeedType({})
      .then(res => {
        const options = res.map(seedType => ({
          value: seedType.nameWithoutAccent,
          label: seedType.nameWithoutAccent,
        }));
        setSeedTypes(options);
      })
      .catch();
  };
  const handleFetchScheduleRule = (organizationId: string) => {
    fetchScheduleRule({
      organizationId,
      seedTypeId: form.getFieldValue('seedType'),
      unity: form.getFieldValue('unity'),
      pickUpLocation: form.getFieldValue('pickUpLocation'),
    }).then(res => {
      if (res.length === 0) {
        return message.error({
          content: 'Nenhuma regra com esses parâmetros foi encontrada!',
          style: { marginTop: 60 },
        });
      }
      const rule = res[0];
      const { schedulingType } = form.getFieldsValue();

      const userSchedule =
        schedulingType === 'cif' ? rule.cif_cadences : rule.fob_cadences;

      setCadences(userData ? userSchedule : rule.fob_cadences);
    });
  };

  useEffect(() => {
    if (orgId) {
      getVehicles(orgId);
      getSeedTypes();
    }
  }, []);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      scrollToFirstError={scrollOptions}
    >
      <Row gutter={[24, 0]}>
        <Col {...columns}>
          <Form.Item label="Nome da Carga" name={'loadName'}>
            <Input />
          </Form.Item>
        </Col>

        <Col {...columns}>
          <Form.Item
            label="Veículo"
            name={'vehicle'}
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              options={vehicles}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>

        {userData && (
          <Col {...columns}>
            <Form.Item
              label="Tipo de agendamento"
              name={'schedulingType'}
              rules={[{ required: true }]}
            >
              <Select allowClear options={schedulingTypeOptions} />
            </Form.Item>
          </Col>
        )}

        <Col {...columns}>
          <Form.Item
            label="Cultura"
            name={'seedType'}
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              options={seedTypes}
              showSearch
              optionFilterProp="children"
              onSelect={() => orgId && getPickUpLocations(orgId)}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>

        <Col {...columns}>
          <Form.Item
            label="Local de embarque"
            name={'pickUpLocation'}
            dependencies={['seedType']}
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              options={locations}
              showSearch
              disabled={!form.getFieldValue('seedType')}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>

        <Col {...columns}>
          <Form.Item
            label="Unidade de medida"
            name={'unity'}
            initialValue={'BAG'}
            rules={[{ required: true }]}
          >
            <Select options={unityOptions} allowClear />
          </Form.Item>
        </Col>

        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            icon={<ArrowDownOutlined />}
            loading={isLoading}
            onClick={() => {
              form.validateFields().then(() => {
                orgId && handleFetchScheduleRule(orgId);
              });
            }}
          >
            Carregar Cadências
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BasicData;
