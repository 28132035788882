import messages from '../i18n/locales/pt-br';
import { IAPIError, IErrorMessage } from '../utils/interfaces';

/**
 * @description handle error and return appropriated message and description error keys
 * @param err Request response error
 * @returns {object} { message, description } (error translation keys)
 */

const errorHandler = (err: IAPIError[]): IErrorMessage => {
  const errorMessage = {
    message: 'errors.default.message',
    description: 'errors.default.description',
  };

  try {
    const [{ extensions }] = err;

    const statusCode = extensions.originalError.statusCode;

    if (statusCode) {
      const messageKeyMessage = `errors.${statusCode}.message`;
      const messageKeyDescription = `errors.${statusCode}.description`;
      const statusObj = messages.translations.errors?.[statusCode];
      const { message, description } = statusObj;

      if (message || description) {
        return {
          message: messageKeyMessage,
          description: messageKeyDescription,
        };
      }
    }
  } catch {
    return errorMessage;
  }

  return errorMessage;
};

export default errorHandler;
