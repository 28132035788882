import { Col, Row } from 'antd';
import moment, { Moment } from 'moment';
import { ReactElement } from 'react';

interface ICalendarCellProps {
  date: Moment;
  cifCapacity: number;
  fobCapacity: number;
  // hours: [Moment, Moment];
  hours: string;
}

const CalendarCell = ({
  date,
  cifCapacity,
  fobCapacity,
  hours,
}: ICalendarCellProps): ReactElement => {
  const classNames = ['ant-picker-cell-inner', 'ant-picker-calendar-date'];
  if (date.isSame(moment(), 'day')) {
    classNames.push('ant-picker-calendar-date-today');
  }

  const cadenceContent = (
    <Row gutter={[0, 8]}>
      <Col span={24} style={{ background: 'lightgray' }}>
        Disponível
      </Col>
      <Col span={12} style={{ borderRight: '0.5px solid #d9d9d9' }}>
        <strong style={{ color: 'green' }}>CIF:</strong> {cifCapacity}
      </Col>
      <Col span={12}>
        <strong style={{ color: 'purple' }}>FOB:</strong> {fobCapacity}
      </Col>
      <Col span={24} style={{ borderTop: '0.5px solid #d9d9d9' }}>
        <strong style={{ fontSize: '13px' }}>{hours}</strong>
      </Col>
    </Row>
  );

  return (
    <div className={classNames.join(' ')} style={{ marginBottom: 8 }}>
      <div className={`ant-picker-calendar-date-value`}>
        {date.format('DD')}
      </div>
      <div
        className={`ant-picker-calendar-date-content`}
        style={{
          textAlign: 'center',
          border: '0.5px solid #d9d9d9',
        }}
      >
        {cadenceContent}
      </div>
    </div>
  );
};

export default CalendarCell;
